import Apollo from '@/graphql/vue-apollo'
import gql from 'graphql-tag'

const COMPLETE_RECRUITER_REGISTRATION = gql`
  mutation CompleteRecruiterRegistration($input: CompleteRecruiterRegistrationInput!) {
    completeRecruiterRegistration(input: $input) {
      user {
        id
      }
    }
  }
`
const COMPLETE_RECRUITER_EDUCATIONAL_REGISTRATION = gql`
  mutation CompleteRecruiterEducationalRegistration($input: CompleteRecruiterEducationalRegistrationInput!) {
    completeRecruiterEducationalRegistration(input: $input) {
      user {
        id
      }
    }
  }
`
const COMPLETE_ADVISOR_REGISTRATION = gql`
  mutation CompleteAdvisorRegistration($input: CompleteAdvisorRegistrationInput!) {
    completeAdvisorRegistration(input: $input) {
      user {
        id
      }
    }
  }
`
const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      user {
        id
        profile {
          id
          firstName
          lastName
          picture
        }
      }
    }
  }
`
const CHANGE_USER_EMAIL = gql`
  mutation ChangeUserEmail($input: ChangeEmailInput!) {
    changeEmail(input: $input)
  }
`
const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        id
      }
    }
  }
`
const CONFIRM_CHANGE_USER_EMAIL = gql`
  mutation ConfirmChangeUserEmail($input: ConfirmChangeEmailInput!) {
    confirmChangeEmail(input: $input) {
      user {
        id
        email
      }
    }
  }
`
const SEND_PASSWORD_RESET = gql`
  mutation SendPasswordReset($input: SendPasswordResetInput!) {
    sendPasswordReset(input: $input)
  }
`
const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      user {
        id
      }
    }
  }
`
const CREATE_CANDIDATE_ACCOUNT = gql`
  mutation CreateCandidateAccount($input: CreateCandidateAccountInput!) {
    createCandidateAccount(input: $input) {
      user {
        id
        email
        type
      }
    }
  }
`
const UPDATE_CANDIDATE_PROFILE = gql`
  mutation UpdateCandidateProfile($input: UpdateCandidateProfileInput!, $countryLanguage: CountryLanguage) {
    updateCandidateProfile(input: $input) {
      candidate {
        id
        profile {
          id
          ... on CandidateProfileGraphType {
            city
            country(language: $countryLanguage) {
              id
              countryCode
              name
            }
            domains
            experienceLevel
            firstName
            hasCsq
            hasPassport
            hasTraveled
            hasTraveledToCanada
            hasCanFam
            hasVisaRefused
            hasEntryRefused
            visaRefusedType
            languages {
              id
              language
              level
            }
            lastName
            linkedInProfile
            phoneNumber
            cellphoneNumber
            picture
            ageGroup
            educationLevel
            overallAverage
            lastYearAverage
            ratingSystem
            currentlySubscribedToStudyProgram
            hasReportsAndDiplomasCopy
            currentStudyProgramLevel
            frenchLanguageTestPassed
            trainingLevel
            hasFirstStudyYearLivingExpensesFinancialResources
            tuitionFeesAbleToPay
            livingExpensesPayer
            studyExpensesPayer
            financialResourcesProofs
            trainingRelated
            willComeToCanadaWithFamilyMember
          }
        }
      }
    }
  }
`
const GET_USER_BY_EMAIL = gql`
  query UserByEmail($email: String) {
    userByEmail(email: $email) {
      id
      type
    }
  }
`
const VERIFY_TOKEN = gql`
  query VerifyToken($email: String, $token: String, $type: TokenType!) {
    verifyToken(email: $email, token: $token, type: $type) {
      status
      user {
        id
        profile {
          firstName
        }
      }
    }
  }
`
const GET_ME = gql`
  query Me {
    me {
      id
    }
  }
`

export default {
  getMe: async function () {
    const user = await Apollo.instance.defaultClient.query({
      query: GET_ME
    })

    return user.data.me
  },
  createCandidateAccount: async function (email, firstName, lastName, password, returnPath) {
    await Apollo.instance.defaultClient.mutate({
      mutation: CREATE_CANDIDATE_ACCOUNT,
      variables: {
        input: {
          email,
          firstName,
          lastName,
          password,
          returnPath
        }
      }
    })
  },
  createCandidateProfile: async function () {
    const user = await Apollo.instance.defaultClient.query({
      query: GET_ME
    })

    const input = {
      userId: user.data.me.id
    }

    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_CANDIDATE_PROFILE,
      variables: {
        input
      }
    })
  },
  createRecruiterProfil: async function (emailAddress, inviteToken, password) {
    await Apollo.instance.defaultClient.mutate({
      mutation: COMPLETE_RECRUITER_REGISTRATION,
      variables: {
        input: {
          emailAddress,
          inviteToken,
          password
        }
      }
    })
  },
  createRecruiterEducationalProfil: async function (emailAddress, inviteToken, password) {
    await Apollo.instance.defaultClient.mutate({
      mutation: COMPLETE_RECRUITER_EDUCATIONAL_REGISTRATION,
      variables: {
        input: {
          emailAddress,
          inviteToken,
          password
        }
      }
    })
  },
  createAdvisorProfil: async function (emailAddress, inviteToken, password) {
    await Apollo.instance.defaultClient.mutate({
      mutation: COMPLETE_ADVISOR_REGISTRATION,
      variables: {
        input: {
          emailAddress,
          inviteToken,
          password
        }
      }
    })
  },
  verifyToken: async function (email, token, tokenType) {
    const result = await Apollo.instance.defaultClient.query({
      query: VERIFY_TOKEN,
      variables: {
        email,
        token,
        type: tokenType
      }
    })
    return result.data.verifyToken
  },
  updateUserProfile: async function (userId, { firstName, lastName, pictureFileId }) {
    const input = { userId }

    if (firstName !== null && firstName !== undefined && firstName !== '') {
      input.firstName = { value: firstName }
    }

    if (lastName !== null && lastName !== undefined && lastName !== '') {
      input.lastName = { value: lastName }
    }

    if (pictureFileId !== null && pictureFileId !== undefined && pictureFileId !== '') {
      input.pictureFileId = { value: pictureFileId }
    }

    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_USER_PROFILE,
      variables: {
        input
      }
    })
  },
  changeEmail: async function (userId, newEmail) {
    const input = { userId, newEmail }

    await Apollo.instance.defaultClient.mutate({
      mutation: CHANGE_USER_EMAIL,
      variables: {
        input
      }
    })
  },
  changePassword: async function (userId, currentPassword, newPassword) {
    const input = { currentPassword, newPassword }
    const result = await Apollo.instance.defaultClient.mutate({
      mutation: CHANGE_USER_PASSWORD,
      variables: {
        input
      }
    })

    return result.data.changePassword.user.id === userId
  },
  confirmChangeEmail: async function (newEmail, token) {
    const input = { newEmail, token }

    await Apollo.instance.defaultClient.mutate({
      mutation: CONFIRM_CHANGE_USER_EMAIL,
      variables: {
        input
      }
    })
  },
  sendPasswordReset: async function (emailAddress) {
    const input = { emailAddress }

    await Apollo.instance.defaultClient.mutate({
      mutation: SEND_PASSWORD_RESET,
      variables: {
        input
      }
    })
  },
  resetPassword: async function (emailAddress, newPassword, token) {
    const input = { emailAddress, newPassword, token }

    await Apollo.instance.defaultClient.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        input
      }
    })
  },
  getUserByEmail: async function (email) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_USER_BY_EMAIL,
      variables: {
        email
      }
    })

    if (result.data.userByEmail === null) {
      return null
    }
    return result.data.userByEmail
  }
}
