import { Consts } from '@/helpers/consts'

const MAX_MSG_QUEUE = 5

let logMessage = []
let sendMsgTimeout = null

export default {
  logInformation: function (msg) {
    logMessage.push({ Message: msg, Level: 2 })
    resetOrSend()
  },
  logWarning: function (msg) {
    logMessage.push({ Message: msg, Level: 3 })
    resetOrSend()
  },
  logError: function (msg) {
    logMessage.push({ Message: msg, Level: 4 })
    resetOrSend()
  },
  logCritical: function (msg) {
    logMessage.push({ Message: msg, Level: 5 })
    resetOrSend()
  }
}

async function sendMsg() {
  try {
    await fetch(`${Consts.ROOT_API}/log`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(logMessage)
    })
  } catch (error) {
    // silent error
    return
  }
  logMessage = []
}

function resetOrSend() {
  if (logMessage.length >= MAX_MSG_QUEUE) {
    sendMsg()
  } else {
    resetTimeout()
  }
}

function resetTimeout() {
  clearTimeout(sendMsgTimeout)
  startTimeout()
}

function startTimeout() {
  sendMsgTimeout = setTimeout(sendMsg, 1000)
}
