import gql from 'graphql-tag'

export default gql`
  enum NotificationType {
    info
    warning
    error
    success
  }

  enum MissionType {
    ON_LOCATION
    VIRTUAL
  }

  enum TokenType {
    RECRUITER_INVITATION
    ADVISOR_INVITATION
  }

  enum ProfessionalDomain {
    BUSINESS
    CONSTRUCTION
    CULTURE
    HEALTH
    MANAGEMENT
    MANUFACTURING
    NATURE
    SALES
    SCIENCES_TECHNOLOGY
    SOCIAL_SERVICES
  }

  enum JobPostingPipelineStep {
    CALLED
    HIRED
    INTERVIEW_PLANNED
    NEW
    NOT_SELECTED
    OFFER_SENT
    PROSPECT
    REJECTED
    TEST_REQUIRED
    TO_CALL
  }

  enum ApplicationSortField {
    APPLICATION_DATE
    CANDIDATE_NAME
    UPDATE_DATE
    CANDIDATE_COUNTRY
  }

  enum ContentLanguage {
    ENGLISH
    FRENCH
  }

  type LocalApp {
    showMenu: Boolean!
    showMainNav: Bolean!
    showAnyNav: Boolean!
    displayUpcomingInterviewNotification: Boolean!
    loadingCount: Int!
    termsVisible: Boolean!
    recruiterTermsVisible: Boolean!
    wizardSelection: WizardSelection!
  }

  type LocalUser {
    isConnected: Boolean!
    accessToken: String!
    refreshToken: String!
    selectedCompany: String!
    selectedMission: String!
  }

  type Notification {
    id: ID!
    type: NotificationType!
    text: String!
    duration: Int!
    dismissible: Boolean!
    contentIsHtml: Boolean!
  }

  type WizardSelection {
    terms: [String]!
    education: [String]!
    diploma: [String]!
    frenchLevel: [String]!
    domains: [String]!
    interviewLocation: [String]!
    institutionLevel: [String]!
  }

  type JobOfferFilter {
    terms: [String]!
    domains: [String]!
    education: [String]!
    englishLevel: [String]!
    experience: [String]!
    frenchLevel: [String]!
    interviewLocation: [String]!
  }

  type Mutation {
    updateLocalUser(user: User!): User
    updateDisplayMainNav(showNav: Boolean!): Boolean
    updateDisplayAnyNav(showNav: Boolean!): Boolean
    addNotification(notification: Notification!): Notification
    removeNotificationById(id: ID!): ID
    removeNotificationByType(type: NotificationType!): NotificationType
  }

  type CreateCompanyInput {
    name: String!
  }

  type UpdateCompanyInput {
    companyId: ID!
    name: StringMaybe
    recruiters: RecruiterDetailsIEnumerableMaybe
  }

  type CreateJobPostingInput {
    companyId: ID!
    title: String!
    missionId: ID
  }

  type CreateStudyProgramPostingInput {
    companyId: ID!
    title: String!
    missionId: ID
    domains: [String]
    diploma: StringMaybe
    descriptionHtml: StringMaybe
    isCoRecruitmentAvailable: Boolean
    url: StringMaybe
  }

  type UpdateStudyProgramPostingInput {
    studyProgramPostingId: ID!
    title: StringMaybe
    domains: StudyProgramPostingDomainInputMaybe
    diploma: StringMaybe
    descriptionHtml: StringMaybe
    isCoRecruitmentAvailable: BooleanMaybe
    url: StringMaybe
    durationInYears: IntMaybe
    durationInYearsNotApplicable: BooleanMaybe
    durationInMonths: IntMaybe
    durationInMonthsNotApplicable: BooleanMaybe
    durationInHours: IntMaybe
    durationInHoursNotApplicable: BooleanMaybe
    tuitionCostFrenchman: StringMaybe
    tuitionCostFrenchSpeakingBelgian: StringMaybe
    tuitionCostOtherNationalities: StringMaybe
    ageRequirement: IntMaybe
    educationLevel: StringMaybe
    frenchRequirement: StringMaybe
    frenchImportance: StringMaybe
    educationLevelImportance: StringMaybe
    tasksAndResponsibilitiesHtml: StringMaybe
    employersHtml: StringMaybe
    trainingHtml: StringMaybe
    scholarshipHtml: StringMaybe
    additionalDetailsHtml: StringMaybe
  }

  type StudyProgramPostingDomainInput {
    value: [String]
  }

  type StudyProgramPostingDomainInputMaybe {
    value: StudyProgramPostingDomainInput!
  }

  type DuplicateJobPostingInput {
    jobPostingId: ID!
    missionId: ID!
  }

  type CreateMissionInput {
    name: String!
    type: MissionType!
  }

  type ApproveJobPostingInput {
    jobPostingId: ID!
  }

  type PublishJobPostingInput {
    jobPostingId: ID!
  }

  type PublishStudyProgramPostingInput {
    studyProgramPostingId: ID!
  }

  type SubmitJobPostingInput {
    jobPostingId: ID!
  }

  type SubmitStudyProgramPostingInput {
    studyProgramPostingId: ID!
  }

  type UpdateJobPostingInput {
    academicImportance: StringMaybe
    academicPrecisions: StringMaybe
    academicRequirement: StringMaybe
    competencies: CompetenciesInputMaybe
    competenciesImportance: StringMaybe
    conditionsPrecisions: StringMaybe
    descriptionHtml: StringMaybe
    englishImportance: StringMaybe
    englishPrecisions: StringMaybe
    englishRequirement: StringMaybe
    experienceImportance: StringMaybe
    experiencePrecisions: StringMaybe
    experienceRequirement: StringMaybe
    frenchImportance: StringMaybe
    frenchPrecisions: StringMaybe
    frenchRequirement: StringMaybe
    hoursPerWeek: FloatMaybe
    jobPostingId: ID!
    location: StringMaybe
    nocCode: StringMaybe
    numberOfPositions: IntMaybe
    publishAutomatically: BooleanMaybe
    responsibilities: StringListMaybe
    salaryBracket: SalaryBracketInputMaybe
    title: StringMaybe
  }

  type FloatMaybe {
    value: Float!
  }

  type StringMaybe {
    value: String!
  }

  type IntMaybe {
    value: Int!
  }

  type BooleanMaybe {
    value: Boolean!
  }

  type IDMaybeNullable {
    value: ID!
  }

  type StringListMaybe {
    value: [String]!
  }

  type CompetenciesInputMaybe {
    value: CompetenciesInput!
  }

  type IDIEnumerableMaybe {
    value: [ID!]
  }

  type RecruiterDetailsIEnumerableMaybe {
    value: [RecruiterDetails]
  }

  type RecruiterDetails {
    email: String!
    firstName: String!
    lastName: String!
  }

  type CompetenciesInput {
    core: [String]!
    secondary: [String]!
  }

  type CreateCandidateAccountInput {
    email: String!
    firstName: String!
    lastName: String!
    password: String!
    returnPath: String
  }

  type ResendConfirmationEmailInput {
    email: String
  }

  type ConfirmEmailInput {
    email: String
    token: String
  }

  type UpdateCandidateProfileInput {
    city: StringMaybe
    competencies: StringListMaybe
    countryCode: StringMaybe
    domains: ProfessionalDomainListMaybe
    education: EducationInputListMaybe
    experience: ExperienceInputListMaybe
    firstName: StringMaybe
    hasCsq: HasCsqMaybe
    immigrationProject: StringMaybe
    languages: LanguageInputListMaybe
    lastName: StringMaybe
    linkedInProfile: StringMaybe
    phoneNumber: StringMaybe
    cellphoneNumber: StringMaybe
    pictureFileId: StringMaybe
    userId: ID!
    nationalities: StringListMaybe
    ageGroup: StringMaybe
    educationLevel: StringMaybe
    overallAverage: StringMaybe
    currentlySubscribedToStudyProgram: BooleanMaybe
    hasReportsAndDiplomasCopy: BooleanMaybe
    frenchLanguageTestPassed: StringMaybe
    trainingLevel: StringMaybe
    hasFirstStudyYearLivingExpensesFinancialResources: BooleanMaybe
    tuitionFeesAbleToPay: StringMaybe
    livingExpensesPayer: StringMaybe
    studyExpensesPayer: StringMaybe
    financialResourcesProofs: FinancialResourcesProofsMaybe
    trainingRelated: TrainingRelatedMaybe
    willComeToCanadaWithFamilyMember: BooleanMaybe
  }

  type FinancialResourcesProofsMaybe {
    value: [String]
  }

  type TrainingRelatedMaybe {
    value: [String]
  }

  type SalaryBracketInputMaybe {
    value: SalaryBracketInput!
  }

  type SalaryBracketInput {
    type: String!
    high: Float
    low: Float
  }

  type CompleteRecruiterRegistrationInput {
    emailAddress: String!
    inviteToken: String!
    password: String!
  }

  type DeactivateJobPostingInput {
    jobPostingId: ID!
  }

  type DeactivateStudyProgramPostingInput {
    studyProgramId: ID!
  }

  type ReactivateJobPostingInput {
    jobPostingId: ID!
  }

  type ReactivateStudyProgramPostingInput {
    studyProgramPostingId: ID!
  }

  type UpdateCompanyProfileInput {
    advantages: AdvantageListMaybe
    companyCulture: StringMaybe
    companyId: ID!
    differentiators: DifferentiatorListMaybe
    domains: ProfessionalDomainListMaybe
    headerFileId: StringMaybe
    logoFileId: StringMaybe
    pictureFileId: StringMaybe
    presentation: StringMaybe
    webSiteUrl: StringMaybe
  }

  type AdvantageListMaybe {
    value: [Advantage!]
  }

  type Advantage {
    title: String
    description: String
  }

  type DifferentiatorListMaybe {
    value: [Differentiator!]
  }

  type Differentiator {
    description: String
    pictureFileId: String
    title: String
  }

  type ProfessionalDomainListMaybe {
    value: [ProfessionalDomain!]
  }

  type UpdateUserProfileInput {
    firstName: StringMaybe
    lastName: StringMaybe
    pictureFileId: StringMaybe
    userId: ID!
  }

  type ApplyOnJobInput {
    candidateId: ID!
    jobPostingId: ID!
    locationId: ID
  }

  type ApplyOnStudyProgramInput {
    candidateId: ID!
    studyProgramPostingId: ID!
    secondaryStudyProgramId: ID
    locationId: ID
  }

  type CreateCompanyAgendaInput {
    companyId: ID!
    locationId: ID!
    name: String!
    acuityCalendarId: Number!
  }

  type CreateInstitutionAgendaInput {
    institutionId: ID!
    locationId: ID!
    name: String!
    acuityCalendarId: Number!
  }

  type ValidateAcuityCalendarInput {
    acuityCalendarId: Number
  }
`
