import { Consts } from '@/helpers/consts'

const mapEn = new Map()
const mapFr = new Map()

mapEn.set(Consts.URL_HOME, '')
mapFr.set(Consts.URL_HOME, '')

mapEn.set(Consts.URL_LOGIN, 'login')
mapFr.set(Consts.URL_LOGIN, 'connexion')

mapEn.set(Consts.URL_MAINTENANCE, 'maintenance')
mapFr.set(Consts.URL_MAINTENANCE, 'maintenance')

mapEn.set(Consts.URL_DOC, 'doc')
mapFr.set(Consts.URL_DOC, 'doc')

mapEn.set(Consts.URL_404, '404')
mapFr.set(Consts.URL_404, '404')

mapEn.set(Consts.URL_500, '500')
mapFr.set(Consts.URL_500, '500')

mapEn.set(Consts.URL_JOB_APPLICATION_INTERVIEW_LIST, 'job-interviews')
mapFr.set(Consts.URL_JOB_APPLICATION_INTERVIEW_LIST, 'entrevues-emploi')

mapEn.set(Consts.URL_JOB_INTERVIEW_WAITING_ROOM, 'job-interview')
mapFr.set(Consts.URL_JOB_INTERVIEW_WAITING_ROOM, 'entrevue-emploi')

mapEn.set(Consts.URL_JOB_INTERVIEW_CONNECTION, 'connection')
mapFr.set(Consts.URL_JOB_INTERVIEW_CONNECTION, 'connexion')

mapEn.set(Consts.URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST, 'study-program-interviews')
mapFr.set(Consts.URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST, 'entrevues-programme-etude')

mapEn.set(Consts.URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM, 'study-program-interview')
mapFr.set(Consts.URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM, 'entrevue-programme-etude')

mapEn.set(Consts.URL_STUDY_PROGRAM_INTERVIEW_CONNECTION, 'connection')
mapFr.set(Consts.URL_STUDY_PROGRAM_INTERVIEW_CONNECTION, 'connexion')

mapEn.set(Consts.URL_STUDY_PROGRAM_LIST, 'manage-programs')
mapFr.set(Consts.URL_STUDY_PROGRAM_LIST, 'gestion-programmes')

mapEn.set(Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE, 'programs')
mapFr.set(Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE, 'programmes')

mapEn.set(Consts.URL_FAVORITE_LIST, 'favorites')
mapFr.set(Consts.URL_FAVORITE_LIST, 'favoris')

mapEn.set(Consts.URL_INSTITUTION_LIST, 'institutions')
mapFr.set(Consts.URL_INSTITUTION_LIST, 'etablissements')

mapEn.set(Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION, 'agendas')
mapFr.set(Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION, 'agendas')

mapEn.set(Consts.URL_COMPANY_AGENDA_LIST, 'company')
mapFr.set(Consts.URL_COMPANY_AGENDA_LIST, 'compagnie')

mapEn.set(Consts.URL_INSTITUTION_AGENDA_LIST, 'institution')
mapFr.set(Consts.URL_INSTITUTION_AGENDA_LIST, 'etablissement')

mapEn.set(Consts.URL_STUDY_PROGRAM_CREATE, 'create')
mapFr.set(Consts.URL_STUDY_PROGRAM_CREATE, 'creer')

mapEn.set(Consts.URL_STUDY_PROGRAM_SUBMITTED, 'submitted')
mapFr.set(Consts.URL_STUDY_PROGRAM_SUBMITTED, 'soumis')

mapEn.set(Consts.URL_STUDY_PROGRAM_PUBLISHED, 'published')
mapFr.set(Consts.URL_STUDY_PROGRAM_PUBLISHED, 'publier')

mapEn.set(Consts.URL_STUDY_PROGRAM_EDIT, 'edit')
mapFr.set(Consts.URL_STUDY_PROGRAM_EDIT, 'edition')

mapEn.set(Consts.URL_STUDY_PROGRAM_PREVIEW, 'preview')
mapFr.set(Consts.URL_STUDY_PROGRAM_PREVIEW, 'apercu')

mapEn.set(Consts.URL_STUDY_PROGRAM_REVIEW, 'review')
mapFr.set(Consts.URL_STUDY_PROGRAM_REVIEW, 'revision')

mapEn.set(Consts.URL_STUDY_PROGRAM_PIPELINE, 'pipeline')
mapFr.set(Consts.URL_STUDY_PROGRAM_PIPELINE, 'pipeline')

mapEn.set(Consts.URL_STUDY_PROGRAM_SORT_NEW, 'sort')
mapFr.set(Consts.URL_STUDY_PROGRAM_SORT_NEW, 'trier')

mapEn.set(Consts.URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL, 'edit-recruiters')
mapFr.set(Consts.URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL, 'edition-recruteurs')

mapEn.set(Consts.URL_INSTITUTION_PROFILE, 'profile')
mapFr.set(Consts.URL_INSTITUTION_PROFILE, 'profil')

mapEn.set(Consts.URL_INSTITUTION_PROFILE_EDITION, 'edit')
mapFr.set(Consts.URL_INSTITUTION_PROFILE_EDITION, 'edition')

mapEn.set(Consts.URL_INSTITUTION_PROFILE_SUBMITTED, 'submitted')
mapFr.set(Consts.URL_INSTITUTION_PROFILE_SUBMITTED, 'soumis')

mapEn.set(Consts.URL_INSTITUTION_CREATE, 'create')
mapFr.set(Consts.URL_INSTITUTION_CREATE, 'creation')

mapEn.set(Consts.URL_INSTITUTION_CREATED, 'created')
mapFr.set(Consts.URL_INSTITUTION_CREATED, 'creer')

mapEn.set(Consts.URL_EMAIL_CONFIRMATION, 'confirm-email')
mapFr.set(Consts.URL_EMAIL_CONFIRMATION, 'confirmer-courriel')

mapEn.set(Consts.URL_USER_SETTINGS, 'settings')
mapFr.set(Consts.URL_USER_SETTINGS, 'reglages')

mapEn.set(Consts.URL_JOB_OFFER_ERROR, 'job-not-found')
mapFr.set(Consts.URL_JOB_OFFER_ERROR, 'offre-introuvable')

mapEn.set(Consts.URL_STUDY_PROGRAM_ERROR, 'study-program-not-found')
mapFr.set(Consts.URL_STUDY_PROGRAM_ERROR, 'programme-etude-introuvable')

mapEn.set(Consts.URL_JOB_OFFER_LIST_CANDIDATE, 'job-offers')
mapFr.set(Consts.URL_JOB_OFFER_LIST_CANDIDATE, 'offres-emploi')

mapEn.set(Consts.URL_JOB_OFFER_LIST, 'manage-job-offers')
mapFr.set(Consts.URL_JOB_OFFER_LIST, 'gestion-offres-emploi')

mapEn.set(Consts.URL_JOB_OFFER_CREATE, 'create')
mapFr.set(Consts.URL_JOB_OFFER_CREATE, 'creer')

mapEn.set(Consts.URL_JOB_OFFER_EDIT, 'edit')
mapFr.set(Consts.URL_JOB_OFFER_EDIT, 'edition')

mapEn.set(Consts.URL_JOB_OFFER_SUBMITTED, 'submitted')
mapFr.set(Consts.URL_JOB_OFFER_SUBMITTED, 'soumis')

mapEn.set(Consts.URL_JOB_OFFER_PREVIEW, 'preview')
mapFr.set(Consts.URL_JOB_OFFER_PREVIEW, 'apercu')

mapEn.set(Consts.URL_JOB_OFFER_REVIEW, 'review')
mapFr.set(Consts.URL_JOB_OFFER_REVIEW, 'revision')

mapEn.set(Consts.URL_JOB_OFFER_PIPELINE, 'pipeline')
mapFr.set(Consts.URL_JOB_OFFER_PIPELINE, 'pipeline')

mapEn.set(Consts.URL_JOB_OFFER_SORT_NEW, 'sort')
mapFr.set(Consts.URL_JOB_OFFER_SORT_NEW, 'trier')

mapEn.set(Consts.URL_JOB_OFFER_APPLY, 'apply')
mapFr.set(Consts.URL_JOB_OFFER_APPLY, 'postuler')

mapEn.set(Consts.URL_JOB_OFFER_APPLY_SUBMITTED, 'submitted-application')
mapFr.set(Consts.URL_JOB_OFFER_APPLY_SUBMITTED, 'application-soumis')

mapEn.set(Consts.URL_STUDY_PROGRAM_APPLY, 'apply')
mapFr.set(Consts.URL_STUDY_PROGRAM_APPLY, 'postuler')

mapEn.set(Consts.URL_STUDY_PROGRAM_APPLY_SUBMITTED, 'submitted-application')
mapFr.set(Consts.URL_STUDY_PROGRAM_APPLY_SUBMITTED, 'application-soumis')

mapEn.set(Consts.URL_JOB_OFFER_PUBLISHED, 'publish')
mapFr.set(Consts.URL_JOB_OFFER_PUBLISHED, 'publier')

mapEn.set(Consts.URL_FORGET_PASSWORD, 'forgot-password')
mapFr.set(Consts.URL_FORGET_PASSWORD, 'mot-de-passe-oublie')

mapEn.set(Consts.URL_FORGET_PASSWORD_CONFIRMATION, 'confirm-forgot-password')
mapFr.set(Consts.URL_FORGET_PASSWORD_CONFIRMATION, 'confirmer-mot-de-passe-oublie')

mapEn.set(Consts.URL_RESET_PASSWORD, 'reset-password')
mapFr.set(Consts.URL_RESET_PASSWORD, 'reinitialiser-mot-de-passe')

mapEn.set(Consts.URL_CREATE_CANDIDATE_USER, 'candidate')
mapFr.set(Consts.URL_CREATE_CANDIDATE_USER, 'candidat')

mapEn.set(Consts.URL_CREATE_CANDIDATE_PROFIL, 'create-candidate-profil')
mapFr.set(Consts.URL_CREATE_CANDIDATE_PROFIL, 'creation-profil-candidat')

mapEn.set(Consts.URL_CREATE_CANDIDATE_USER_CONFIRMATION, 'candidate-created')
mapFr.set(Consts.URL_CREATE_CANDIDATE_USER_CONFIRMATION, 'candidat-creer')

mapEn.set(Consts.URL_CREATE_RECUITER_PROFIL, 'recruiter')
mapFr.set(Consts.URL_CREATE_RECUITER_PROFIL, 'recruteur')

mapEn.set(Consts.URL_CREATE_ADVISOR_PROFIL, 'advisor')
mapFr.set(Consts.URL_CREATE_ADVISOR_PROFIL, 'conseiller')

mapEn.set(Consts.URL_CREATE_RECRUITER_ERROR, 'recruiter-error')
mapFr.set(Consts.URL_CREATE_RECRUITER_ERROR, 'erreur-recruteur')

mapEn.set(Consts.URL_CREATE_ADVISOR_ERROR, 'advisor-error')
mapFr.set(Consts.URL_CREATE_ADVISOR_ERROR, 'erreur-conseiller')

mapEn.set(Consts.URL_REGISTER, 'registration')
mapFr.set(Consts.URL_REGISTER, 'inscription')

mapEn.set(Consts.URL_BUSINESS, 'business')
mapFr.set(Consts.URL_BUSINESS, 'entreprise')

mapEn.set(Consts.URL_BUSINESS_LIST, 'business')
mapFr.set(Consts.URL_BUSINESS_LIST, 'entreprises')

mapEn.set(Consts.URL_BUSINESS_EDIT_RECRUITERS, 'edit-recruiters')
mapFr.set(Consts.URL_BUSINESS_EDIT_RECRUITERS, 'edition-recruteurs')

mapEn.set(Consts.URL_BUSINESS_CREATE, 'create')
mapFr.set(Consts.URL_BUSINESS_CREATE, 'creation')

mapEn.set(Consts.URL_BUSINESS_CREATED, 'created')
mapFr.set(Consts.URL_BUSINESS_CREATED, 'creer')

mapEn.set(Consts.URL_BUSINESS_PROFIL_EDITION, 'edit')
mapFr.set(Consts.URL_BUSINESS_PROFIL_EDITION, 'edition')

mapEn.set(Consts.URL_BUSINESS_PROFIL_SUBMITTED, 'submitted')
mapFr.set(Consts.URL_BUSINESS_PROFIL_SUBMITTED, 'soumis')

mapEn.set(Consts.URL_MISSION_LIST, 'missions')
mapFr.set(Consts.URL_MISSION_LIST, 'missions')

mapEn.set(Consts.URL_MISSION_CREATE, 'create')
mapFr.set(Consts.URL_MISSION_CREATE, 'creation')

mapEn.set(Consts.URL_MISSION_CREATED, 'created')
mapFr.set(Consts.URL_MISSION_CREATED, 'creer')

mapEn.set(Consts.URL_MISSION_EDITION, 'edit')
mapFr.set(Consts.URL_MISSION_EDITION, 'edition')

mapEn.set(Consts.URL_APPLICATIONS_LIST, 'applications')
mapFr.set(Consts.URL_APPLICATIONS_LIST, 'candidatures')

mapEn.set(Consts.URL_CANDIDATE_CV, 'my-profile')
mapFr.set(Consts.URL_CANDIDATE_CV, 'mon-profil')

mapEn.set(Consts.URL_CANDIDATE_PROFIL_PRO_PREVIEW, 'preview')
mapFr.set(Consts.URL_CANDIDATE_PROFIL_PRO_PREVIEW, 'apercu')

mapEn.set(Consts.URL_CANDIDATE_PROFIL_EDUCATIONAL_PREVIEW, 'preview-educational')
mapFr.set(Consts.URL_CANDIDATE_PROFIL_EDUCATIONAL_PREVIEW, 'apercu-etudiant')

mapEn.set(Consts.URL_CANDIDATE_APPLICATION, 'applications')
mapFr.set(Consts.URL_CANDIDATE_APPLICATION, 'candidatures')

mapEn.set(Consts.URL_APPLICATION_CONVOCATIONS_LIST, 'convocations')
mapFr.set(Consts.URL_APPLICATION_CONVOCATIONS_LIST, 'convocations')

mapEn.set(Consts.URL_STUDY_PROGRAM_APPLICATION_CONVOCATIONS_LIST, 'convocations')
mapFr.set(Consts.URL_STUDY_PROGRAM_APPLICATION_CONVOCATIONS_LIST, 'convocations')

mapEn.set(Consts.URL_CANDIDATE_LIST, 'candidates')
mapFr.set(Consts.URL_CANDIDATE_LIST, 'candidats')

mapEn.set(Consts.URL_CANDIDATE_EDIT, 'edit')
mapFr.set(Consts.URL_CANDIDATE_EDIT, 'edition')

mapEn.set(Consts.URL_CANDIDATE_EDIT_PRO, 'professionnel')
mapFr.set(Consts.URL_CANDIDATE_EDIT_PRO, 'professional')

mapEn.set(Consts.URL_CANDIDATE_EDIT_EDUCATIONAL, 'educational')
mapFr.set(Consts.URL_CANDIDATE_EDIT_EDUCATIONAL, 'etudiant')

mapEn.set(Consts.URL_EDIT_ADVISORS, 'advisors')
mapFr.set(Consts.URL_EDIT_ADVISORS, 'conseillers')

mapEn.set(Consts.URL_CONFIRM_EMAIL_CHANGE, 'confirm-email-change')
mapFr.set(Consts.URL_CONFIRM_EMAIL_CHANGE, 'confirmer-changement-courriel')

mapEn.set(Consts.TWILIO_URL, 'twilio-test')
mapFr.set(Consts.TWILIO_URL, 'twilio-test')

export const Url = {
  en: mapEn,
  fr: mapFr,
  getUrl: function (key, locale) {
    if (locale === Consts.LANG_EN) {
      return mapEn.get(key)
    } else {
      return mapFr.get(key)
    }
  }
}
