<template>
  <div v-show="visible" class="loader-generic-loader">
    <v-progress-linear :indeterminate="true" :height="2" :background-opacity="0" class="ma-0"></v-progress-linear>
  </div>
</template>

<script>
import gql from 'graphql-tag'

const GET_LOADING_COUNT = gql`
  query GetLoadingCount {
    app @client {
      loadingCount
    }
  }
`

export default {
  apollo: {
    app: {
      query: GET_LOADING_COUNT
    }
  },
  computed: {
    visible() {
      if (typeof this.app !== 'object') {
        return false
      }
      if (this.app.loadingCount > 0) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="stylus" scoped>
.loader-generic-loader {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
</style>
