import 'whatwg-fetch'

import Vue from 'vue'
import i18n from '@/helpers/i18n'

import ConstsHelper from '@/plugins/consts'
import Sluggify from '@/plugins/sluggify'
import String from '@/plugins/string'
import Logger from '@/plugins/logger'
import { Vuetify, VuetifyOptions } from '@/plugins/vuetify'
import i18nPlugin from '@/plugins/i18n'
import VueScrollTo from 'vue-scrollto'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VMoney from 'v-money'
import App from '@/app.vue'
import router from '@/helpers/router'
import Apollo from '@/graphql/vue-apollo'
import authentification from '@/services/authentification'
import LoadScript from 'vue-plugin-load-script'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import { SetupThemeMeta } from '@/helpers/theme'

Vue.use(Vuetify, VuetifyOptions)
Vue.use(Sluggify)
Vue.use(String)
Vue.use(ConstsHelper)
Vue.use(VMoney)
Vue.use(Logger)
Vue.use(i18nPlugin)
Vue.use(VueScrollTo)
Vue.use(VueYouTubeEmbed)
Vue.use(LoadScript)
Vue.use(vueAwesomeCountdown)

Vue.config.productionTip = false

SetupThemeMeta()

authentification.loadLocalState().then(() => {
  new Vue({
    router: router.instance,
    i18n: i18n.instance(),
    apolloProvider: Apollo.instance,
    render: (h) => h(App)
  }).$mount('#app')
})
