<template>
  <div v-if="notifications && notifications.length > 0">
    <div ref="PermanentSnackbarContainer">
      <template v-for="(item, index) in permanentSnackbars">
        <notification-snackbar
          v-if="index === 0"
          v-on:dismiss="onNotificationDismiss"
          :item="item"
          :key="snackbarKey(item)"
          class="permanent-snackbar"
        />
      </template>
    </div>
    <template v-for="(item, index) in timedSnackbars">
      <notification-snackbar
        v-if="index === 0"
        v-on:dismiss="onNotificationDismiss"
        :item="item"
        :key="snackbarKey(item)"
        class="timed-snackbar"
        :style="timedSnackbarMarginTop()"
      />
    </template>
    <v-container v-if="alerts.length > 0" fluid class="pb-0">
      <v-sheet dark color="white" elevation="2">
        <notification-alert v-for="alert in alerts" v-on:dismiss="onNotificationDismiss" :item="alert" :key="alert.id" />
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { Consts } from '@/helpers/consts'
import Notification from '@/services/notification'
import NotificationAlert from '@/components/notification/notification-alert'
import NotificationSnackbar from '@/components/notification/notification-snackbar'

import gql from 'graphql-tag'

const GET_NOTIFICATIONS = gql`
  query LocalNotifications {
    notifications @client {
      id
      type
      text
      duration
      dismissible
      contentIsHtml
    }
  }
`

export default {
  components: {
    NotificationAlert,
    NotificationSnackbar
  },
  apollo: {
    notifications: {
      query: GET_NOTIFICATIONS
    }
  },
  computed: {
    alerts() {
      return this.notifications.filter((item) => !item.duration || item.duration <= 0)
    },
    permanentSnackbars() {
      return this.notifications.filter((item) => item.duration && item.duration === Consts.NOTIFICATION_TIME_PERMANENT)
    },
    timedSnackbars() {
      return this.notifications.filter(
        (item) => item.duration && item.duration > 0 && item.duration !== Consts.NOTIFICATION_TIME_PERMANENT
      )
    },
    snackbarKey() {
      return (item) => `snackbar-${item.id}`
    }
  },
  methods: {
    onNotificationDismiss: function (id) {
      Notification.removeNotificationById(id)
    },
    timedSnackbarMarginTop() {
      return `margin-top: ${
        this.permanentSnackbars.length > 0 &&
        this.$refs.PermanentSnackbarContainer &&
        this.$refs.PermanentSnackbarContainer.firstElementChild
          ? this.$refs.PermanentSnackbarContainer.firstElementChild.clientHeight + 10
          : 0
      }px`
    }
  }
}
</script>

<style lang="stylus">
.permanent-snackbar {
  z-index: 10
}
.timed-snackbar {
  z-index: 5
}
</style>
