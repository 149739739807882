import env from '@/helpers/env'
import { Consts } from '@/helpers/consts'

export const Theme = env.VUE_APP_THEME === Consts.THEME_SDED ? Consts.THEME_SDED : Consts.THEME_QI // QI par défaut
export const ThemeSlug = Theme.toLowerCase()
export const ThemeIsQI = env.VUE_APP_THEME !== Consts.THEME_SDED // QI par défaut
export const ThemeIsSDED = env.VUE_APP_THEME === Consts.THEME_SDED
export const ColorTheme = getColorTheme()

export function SetupThemeMeta() {
  document.querySelector('meta[name="theme-color"]').setAttribute('content', ColorTheme.primary)
}

export function SetVueI18nDefaultArgs(VueI18n) {
  const originalTranslate = VueI18n.prototype._translate
  VueI18n.prototype._translate = function (messages, locale, fallback, key, host, interpolateMode, args) {
    if (!args) args = {}
    args['const:tradeMark'] = env.VUE_APP_TRADE_MARK
    args['const:applicationFullName'] = env.VUE_APP_APPLICATION_FULL_NAME
    args['const:organism'] = env.VUE_APP_ORGANISM
    args['const:organismShortName'] = env.VUE_APP_ORGANISM_SHORT_NAME
    args['const:regionFr'] = env.VUE_APP_REGION_FR
    args['const:regionEn'] = env.VUE_APP_REGION_EN
    args['const:helpEmail'] = env.VUE_APP_HELP_EMAIL
    args['const:missionHelpEmail'] = env.VUE_APP_MISSION_HELP_EMAIL
    args['const:themeSlug'] = ThemeSlug
    args['const.websiteShortUrl'] = env.VUE_APP_WEBSITE_SHORT_URL
    args['const:websiteFrUrl'] = env.VUE_APP_WEBSITE_FR_URL
    args['const:websiteEnUrl'] = env.VUE_APP_WEBSITE_EN_URL
    args['const:websiteContactFrUrl'] = env.VUE_APP_WEBSITE_CONTACT_FR_URL
    args['const:websiteContactEnUrl'] = env.VUE_APP_WEBSITE_CONTACT_EN_URL
    args['const:websiteCorecruitmentFrUrl'] = env.VUE_APP_WEBSITE_CORECRUITMENT_FR_URL
    args['const:websiteCorecruitmentEnUrl'] = env.VUE_APP_WEBSITE_CORECRUITMENT_EN_URL
    args['const:createAccountTutorialFrUrl'] = env.VUE_APP_CREATE_ACCOUNT_TUTORIAL_FR_URL
    args['const:createAccountTutorialEnUrl'] = env.VUE_APP_CREATE_ACCOUNT_TUTORIAL_EN_URL
    args['const:interviewFaqFrUrl'] = env.VUE_APP_INTERVIEW_FAQ_FR_URL
    args['const:interviewFaqEnUrl'] = env.VUE_APP_INTERVIEW_FAQ_EN_URL

    return originalTranslate.apply(this, [messages, locale, fallback, key, host, interpolateMode, args])
  }
}

function getColorTheme() {
  switch (Theme) {
    case Consts.THEME_SDED:
      return {
        primary: '#F08D42',
        secondary: '#4db2b5',
        error: '#FF3E50',
        success: '#373A4A',
        pipelinePrimary: '#F08D42',
        pipelineSecondary: '#4CB2B4',
        pipelineThird: '#373A4A '
      }
    case Consts.THEME_QI:
    default: // QI par défaut
      return {
        primary: '#00a1e3',
        secondary: '#8cc63e',
        error: '#FF3E50',
        pipelinePrimary: '#2D95DA',
        pipelineSecondary: '#0D843E',
        pipelineThird: '#74BB16'
      }
  }
}
