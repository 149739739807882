import Apollo from '@/graphql/vue-apollo'

import gql from 'graphql-tag'

const MODIFY_LOADING_COUNT = gql`
  mutation ModifyLoadingCount($countModifier: Int!) {
    modifyLoadingCount(countModifier: $countModifier) @client
  }
`

export default {
  modifyCount: async function (countModifier) {
    await Apollo.instance.defaultClient.mutate({
      mutation: MODIFY_LOADING_COUNT,
      variables: {
        countModifier
      }
    })
  }
}
