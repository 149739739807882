<template>
  <v-dialog v-model="displayDialog" persistent scrollable max-width="480">
    <v-card>
      <v-card-text style="max-height: calc(100vh - 100px)">
        <ui-content>
          <p v-html="$t('status-version-change-text')"></p>
        </ui-content>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outline @click="reloadPage">{{ $t('status-version-change-reload-btn') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Status from '@/services/status'

export default {
  data() {
    return {
      displayDialog: false,
      version: null,
      interval: null
    }
  },
  mounted() {
    this.version = Status.getVersion()

    const vm = this
    this.interval = setInterval(function () {
      vm.updateStatus()
    }, 1000)
  },
  methods: {
    updateStatus: function () {
      const newVersion = Status.getVersion()
      if (this.version === '') {
        this.version = newVersion
      }
      if (this.version !== newVersion) {
        clearInterval(this.interval)
        this.displayDialog = true
      }
    },
    reloadPage: function () {
      location.reload(true)
      this.displayDialog = false
    }
  }
}
</script>
