import Apollo from '@/graphql/vue-apollo'
import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const UPDATE_LOCAL_USER = gql`
  mutation UpdateLocalUser($user: User!) {
    updateLocalUser(user: $user) @client
  }
`
const DELETE_COMPANY = gql`
  mutation DeleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input)
  }
`
const DELETE_COMPANY_PROFILE_TRANSLATION = gql`
  mutation DeleteCompanyProfileTranslation($input: DeleteCompanyProfileTranslationInput!) {
    deleteCompanyProfileTranslation(input: $input) {
      company {
        id
        header
        logo
        picture
        translations {
          id
          advantages {
            id
            description
            title
          }
          companyCulture
          differentiators {
            id
            description
            picture
            title
          }
          language
          presentation
          webSiteUrl
        }
      }
    }
  }
`
const UPDATE_COMPANY_PROFILE_TRANSLATION = gql`
  mutation UpdateCompanyProfileTranslation($input: UpdateCompanyProfileTranslationInput!) {
    updateCompanyProfileTranslation(input: $input) {
      company {
        id
        translations {
          id
          advantages {
            id
            description
            title
          }
          companyCulture
          differentiators {
            id
            description
            picture
            title
          }
          language
          presentation
          webSiteUrl
        }
      }
    }
  }
`
const UPDATE_COMPANY_PROFILE = gql`
  mutation UpdateCompanyProfile($input: UpdateCompanyProfileInput!) {
    updateCompanyProfile(input: $input) {
      company {
        id
        header
        logo
        picture
        domains
      }
    }
  }
`
const REMOVE_RECRUITER_FROM_COMPANY = gql`
  mutation RemoveRecruiterFromCompany($input: RemoveRecruiterFromCompanyInput!) {
    removeRecruiterFromCompany(input: $input) {
      company {
        id
        recruiters {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const ADD_RECRUITER_TO_COMPANY = gql`
  mutation AddRecruiterToCompany($input: AddRecruiterToCompanyInput!) {
    addRecruiterToCompany(input: $input) {
      company {
        id
        recruiters {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        name
        participations {
          id
        }
        recruiters {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        id
        participations {
          id
        }
        name
        recruiters {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const GET_LOCAL_USER_CURRENT_COMPANY = gql`
  query LocalUserCurrentCompany {
    user @client {
      selectedCompany
    }
  }
`
const GET_ASSOCIATED_MISSIONS = gql`
  query AssociatedMissions($companyId: ID!) {
    company(companyId: $companyId) {
      id
      participations {
        id
        mission {
          id
        }
      }
    }
  }
`

export default {
  selectedCompany: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_CURRENT_COMPANY
    })
    return result.data.user.selectedCompany
  },
  createCompany: async function (input) {
    const result = await Apollo.instance.defaultClient.mutate({
      mutation: CREATE_COMPANY,
      variables: {
        input
      }
    })

    return result.data.createCompany.company.id
  },
  updateCompany: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        input
      }
    })
  },
  updateCompanyProfile: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_COMPANY_PROFILE,
      variables: {
        input
      }
    })
  },
  updateCompanyProfileTranslation: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_COMPANY_PROFILE_TRANSLATION,
      variables: {
        input
      }
    })
  },
  deleteCompanyProfileTranslation: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: DELETE_COMPANY_PROFILE_TRANSLATION,
      variables: {
        input
      }
    })
  },
  updateCompanySelected: async function (selectedCompany) {
    const user = { selectedCompany }

    const result = await Apollo.instance.defaultClient.query({
      query: GET_ASSOCIATED_MISSIONS,
      variables: {
        companyId: selectedCompany
      }
    })

    const currentMissionId = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
    if (!result.data.company.participations.find((p) => p.mission.id === currentMissionId)) {
      let selectedMission = ''
      if (result.data.company.participations.length > 0) {
        selectedMission = result.data.company.participations.first().mission.id
        localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
      } else {
        localStorage.removeItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
      }
      user.selectedMission = selectedMission
    }

    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_LOCAL_USER,
      variables: {
        user
      }
    })

    localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_COMPANY, selectedCompany)
  },
  addRecruiters: async function (companyId, recruiters) {
    for (let i = 0; i < recruiters.length; i++) {
      const recruiter = recruiters[i]
      await Apollo.instance.defaultClient.mutate({
        mutation: ADD_RECRUITER_TO_COMPANY,
        variables: {
          input: {
            companyId,
            email: recruiter.email,
            firstName: recruiter.firstName,
            lastName: recruiter.lastName
          }
        }
      })
    }
  },
  removeRecruiterFromCompany: async function (companyId, userId) {
    const input = { companyId, userId }

    await Apollo.instance.defaultClient.mutate({
      mutation: REMOVE_RECRUITER_FROM_COMPANY,
      variables: {
        input
      }
    })
  },
  deleteCompany: async function (companyId) {
    const input = { companyId }

    await Apollo.instance.defaultClient.mutate({
      mutation: DELETE_COMPANY,
      variables: {
        input
      }
    })
  }
}
