<template>
  <v-snackbar v-model="visibility" :color="item.type" :right="true" :timeout="item.duration" :top="true" auto-height>
    {{ !item.contentIsHtml ? item.text : '' }}
    <span v-html="item.contentIsHtml ? item.text : ''" />
    <v-btn v-if="item.dismissible" flat @click="visibility = false" class="theme--dark">{{ $t('close') }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visibility: true
    }
  },
  watch: {
    visibility(val) {
      if (!val) {
        this.$emit('dismiss', this.item.id)
      }
    }
  }
}
</script>

<style lang="stylus">
.v-snack__content
  a
    color: #fff
</style>
