import gql from 'graphql-tag'

const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      isConnected
      accessToken
      refreshToken
      selectedCompany
      selectedMission
      selectedInstitution
    }
  }
`

const GET_TERMS_VISIBLE = gql`
  query GetTermsVisible {
    app @client {
      termsVisible
      recruiterTermsVisible
    }
  }
`
const GET_NOTIFICATIONS = gql`
  query LocalNotifications {
    notifications @client {
      id
      type
      text
      duration
      dismissible
      contentIsHtml
    }
  }
`
const GET_SHOW_NAV = gql`
  query LocalShowMenu {
    app @client {
      showMainNav
      showAnyNav
      showBreadcrumb
      displayUpcomingInterviewNotification
    }
  }
`
const GET_SHOW_BREADCRUMB = gql`
  query LocalShowBreadcrumb {
    app @client {
      showBreadcrumb
      isAbsolutePos
    }
  }
`
const GET_LOADING_COUNT = gql`
  query GetLoadingCount {
    app @client {
      loadingCount
    }
  }
`
const GET_WIZARD_SELECTION = gql`
  query GetLoadingCount {
    app @client {
      wizardSelection {
        terms
        domains
        education
        diploma
        frenchLevel
        interviewLocation
        institutionLevel
      }
    }
  }
`
const GET_JOB_OFFER_FILTER = gql`
  query GetJobOfferFilter {
    app @client {
      jobOfferFilter {
        terms
        domains
        education
        englishLevel
        experience
        frenchLevel
        interviewLocation
      }
    }
  }
`

export default {
  Mutation: {
    updateDisplayMainNav(_, { showNav }, { cache }) {
      const data = cache.readQuery({ query: GET_SHOW_NAV })
      data.app.showMainNav = showNav

      cache.writeQuery({
        query: GET_SHOW_NAV,
        data
      })

      return showNav
    },
    updateDisplayAnyNav(_, { showNav }, { cache }) {
      const data = cache.readQuery({ query: GET_SHOW_NAV })
      data.app.showAnyNav = showNav

      cache.writeQuery({
        query: GET_SHOW_NAV,
        data
      })

      return showNav
    },
    updateDisplayUpcomingInterviewNotification(_, { displayUpcomingInterviewNotification }, { cache }) {
      const data = cache.readQuery({ query: GET_SHOW_NAV })
      data.app.displayUpcomingInterviewNotification =
        displayUpcomingInterviewNotification !== null ? displayUpcomingInterviewNotification : true

      cache.writeQuery({
        query: GET_SHOW_NAV,
        data
      })
    },
    updateDisplayBreadcrumb(_, { showBreadcrumb, isAbsolutePos }, { cache }) {
      const data = cache.readQuery({ query: GET_SHOW_BREADCRUMB })
      data.app.showBreadcrumb = showBreadcrumb
      data.app.isAbsolutePos = isAbsolutePos

      cache.writeQuery({
        query: GET_SHOW_BREADCRUMB,
        data
      })

      return showBreadcrumb
    },
    updateLocalUser(_, { user }, { cache }) {
      const data = cache.readQuery({ query: GET_LOCAL_USER })
      if (user.isConnected !== null && user.isConnected !== undefined) {
        data.user.isConnected = user.isConnected
      }

      if (user.accessToken !== null && user.accessToken !== undefined) {
        data.user.accessToken = user.accessToken
      }

      if (user.refreshToken !== null && user.refreshToken !== undefined) {
        data.user.refreshToken = user.refreshToken
      }

      if (user.selectedCompany !== null && user.selectedCompany !== undefined) {
        data.user.selectedCompany = user.selectedCompany
      }

      if (user.selectedInstitution !== null && user.selectedInstitution !== undefined) {
        data.user.selectedInstitution = user.selectedInstitution
      }

      if (user.selectedMisselectedMissionsion !== null && user.selectedMission !== undefined) {
        data.user.selectedMission = user.selectedMission
      }

      cache.writeQuery({
        query: GET_LOCAL_USER,
        data
      })

      return data.user
    },
    addNotification(_, { notification }, { cache }) {
      const data = cache.readQuery({ query: GET_NOTIFICATIONS })
      data.notifications.push(notification)

      cache.writeQuery({
        query: GET_NOTIFICATIONS,
        data
      })

      return notification
    },
    removeNotificationById(_, { id }, { cache }) {
      const data = cache.readQuery({ query: GET_NOTIFICATIONS })
      data.notifications = data.notifications.filter((item) => item.id !== id)

      cache.writeQuery({
        query: GET_NOTIFICATIONS,
        data
      })

      return id
    },
    removeNotificationByType(_, { type }, { cache }) {
      const data = cache.readQuery({ query: GET_NOTIFICATIONS })
      data.notifications = data.notifications.filter((item) => item.type !== type)

      cache.writeQuery({
        query: GET_NOTIFICATIONS,
        data
      })

      return type
    },
    modifyLoadingCount(_, { countModifier }, { cache }) {
      const data = cache.readQuery({ query: GET_LOADING_COUNT })
      data.app.loadingCount += countModifier

      cache.writeQuery({
        query: GET_LOADING_COUNT,
        data
      })
    },
    showTerms(_, { isVisible }, { cache }) {
      const data = cache.readQuery({ query: GET_TERMS_VISIBLE })
      data.app.termsVisible = isVisible

      cache.writeQuery({
        query: GET_TERMS_VISIBLE,
        data
      })
    },
    showRecruiterTerms(_, { isVisible }, { cache }) {
      const data = cache.readQuery({ query: GET_TERMS_VISIBLE })
      data.app.recruiterTermsVisible = isVisible

      cache.writeQuery({
        query: GET_TERMS_VISIBLE,
        data
      })
    },
    saveWizardSelection(_, { wizard }, { cache }) {
      const data = cache.readQuery({ query: GET_WIZARD_SELECTION })
      data.app.wizardSelection.terms = wizard.terms
      data.app.wizardSelection.education = wizard.education
      data.app.wizardSelection.diploma = wizard.diploma
      data.app.wizardSelection.frenchLevel = wizard.frenchLevel
      data.app.wizardSelection.domains = wizard.domains
      data.app.wizardSelection.interviewLocation = wizard.interviewLocation
      data.app.wizardSelection.institutionLevel = wizard.institutionLevel

      cache.writeQuery({
        query: GET_WIZARD_SELECTION,
        data
      })
    },
    saveJobOfferFilter(_, { filters }, { cache }) {
      const data = cache.readQuery({ query: GET_JOB_OFFER_FILTER })
      data.app.jobOfferFilter.terms = filters.terms
      data.app.jobOfferFilter.education = filters.education
      data.app.jobOfferFilter.englishLevel = filters.englishLevel
      data.app.jobOfferFilter.frenchLevel = filters.frenchLevel
      data.app.jobOfferFilter.domains = filters.domains
      data.app.jobOfferFilter.interviewLocation = filters.interviewLocation
      data.app.jobOfferFilter.experience = filters.experience

      cache.writeQuery({
        query: GET_JOB_OFFER_FILTER,
        data
      })
    }
  }
}
