<template>
  <v-dialog v-model="displayDialog" persistent scrollable max-width="480">
    <v-card>
      <v-card-text style="max-height: calc(100vh - 100px)">
        <ui-content>
          <h2 class="title">{{ $t('recruiter-terms-change-title') }}</h2>
          <p>{{ $t('recruiter-terms-change-text-1') }}</p>
          <p>{{ $t('recruiter-terms-change-text-2') }}</p>
        </ui-content>
        <v-checkbox v-model="consentRecruiterTerms" class="mt-3">
          <template v-slot:label>
            <span v-html="$t('recruiter-terms-consent-checkbox')"></span>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="logout">{{ $t('logout-label') }}</v-btn>
        <v-btn :disabled="disabledBtn || !consentRecruiterTerms" color="primary" @click="onAcceptTerms">
          {{ $t('recruiter-terms-accept-btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Terms from '@/services/terms'
import Authentification from '@/services/authentification'

import { Consts } from '@/helpers/consts'
import { ThemeSlug } from '@/helpers/theme'

export default {
  data() {
    return {
      ThemeSlug,
      consentRecruiterTerms: false,
      displayDialog: true,
      disabledBtn: false
    }
  },
  methods: {
    onAcceptTerms: async function () {
      if (this.consentRecruiterTerms) {
        this.disabledBtn = true
        await Terms.acceptRecruiterTerms(Consts.RECRUITER_TERMS_LATEST)
        this.displayDialog = false
      }
    },
    logout: function () {
      Authentification.logout()
    }
  }
}
</script>
