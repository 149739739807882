import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/helpers/i18n'
import App from '@/services/app'
import Status from '@/services/status'
import { Consts } from '@/helpers/consts'
import BreadcrumbHelper from '@/helpers/breadcrumb'
import Notification from '@/services/notification'
import Authentification from '@/services/authentification'
import ArrayHelper from '@/plugins/array'
import { Url } from '@/helpers/url'

Vue.use(ArrayHelper)

/* meta {
  displayDefaultNav: Boolean (default true)                    // If we need to display the default nav from nav-container (should be set to false only when the views display it's own menu)
  displayNav: Boolean (default true)                           // If we need any nav at all (edge-case for login)
  displayUpcomingInterviewNotification: Boolean (default true) // Hidden during videoconference interview but shown everywhere else
  loginRequired: Boolean (default false)                       // If the page required to be logged to the Back-end with any kind of restrictions
  userTypeRequired: [string] (default [])                      // If the page required specific type of user (ADVISOR / CANDIDATE / RECRUITER / ANONYME)
  breadcrumb: Function                                         // The function to get the breadcrumb title
  displayBreadcrumb: Boolean (default false)                   // If we need to display the breadcrumb in the curret page
} */
const defaultMeta = {
  displayDefaultNav: true,
  displayNav: true,
  displayUpcomingInterviewNotification: true,
  loginRequired: false,
  userTypeRequired: [],
  breadcrumb: () => '',
  displayBreadcrumb: false,
  isAbsolutePos: false
}

const routePublic = [
  {
    key: Consts.URL_DOC,
    component: () => import(/* webpackChunkName: "doc" */ '@/views/doc.vue'),
    meta: {
      displayDefaultNav: true
    }
  },
  {
    key: Consts.URL_404,
    component: () => import(/* webpackChunkName: "error-404" */ '@/views/error-404.vue')
  },
  {
    key: Consts.URL_500,
    component: () => import(/* webpackChunkName: "error-500" */ '@/views/error-500.vue')
  },
  {
    key: Consts.URL_FORGET_PASSWORD,
    component: () => import(/* webpackChunkName: "anonyme-forgot-password" */ '@/views/anonyme/forgot-password.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_FORGET_PASSWORD_CONFIRMATION,
    component: () =>
      import(/* webpackChunkName: "anonyme-forgot-password-confirmation" */ '@/views/anonyme/forgot-password-confirmation.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "anonyme-reset-password" */ '@/views/anonyme/reset-password.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_JOB_OFFER_ERROR,
    component: () => import(/* webpackChunkName: "public-job-offer-error" */ '@/views/public/job-offer-error.vue')
  },
  {
    key: Consts.URL_STUDY_PROGRAM_ERROR,
    component: () => import(/* webpackChunkName: "public-job-offer-error" */ '@/views/public/study-program-error.vue')
  }
]

const routeAnonyme = [
  {
    key: Consts.URL_EMAIL_CONFIRMATION,
    component: () => import(/* webpackChunkName: "anonyne-confirm-email" */ '@/views/anonyme/confirm-email.vue'),
    meta: {
      displayNav: false,
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    meta: {
      displayNav: false,
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_HOME,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_LOGIN)
    }
  },
  {
    key: Consts.URL_MAINTENANCE,
    component: () => import(/* webpackChunkName: "maintenance" */ '@/views/anonyme/maintenance.vue'),
    meta: {
      loginRequired: false,
      displayNav: false,
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_REGISTER,
    meta: {
      userTypeRequired: [Consts.USER_TYPE_ANONYME]
    },
    redirect: (to) => {
      return '/'
    },
    children: [
      {
        key: Consts.URL_CREATE_CANDIDATE_USER,
        component: () =>
          import(/* webpackChunkName: "anonyne-create-candidate-user" */ '@/views/anonyme/create-candidate-user.vue'),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      },
      {
        key: Consts.URL_CREATE_CANDIDATE_USER_CONFIRMATION,
        component: () =>
          import(
            /* webpackChunkName: "anonyne-create-candidate-confirmation" */ '@/views/anonyme/create-candidate-confirmation.vue'
          ),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      },
      {
        key: Consts.URL_CREATE_RECUITER_PROFIL,
        component: () =>
          import(/* webpackChunkName: "anonyme-create-recruiter-profil" */ '@/views/anonyme/create-recruiter-profil.vue'),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      },
      {
        key: Consts.URL_CREATE_RECRUITER_ERROR,
        component: () =>
          import(/* webpackChunkName: "anonyme-create-advisor-error" */ '@/views/anonyme/create-advisor-error.vue'),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      },
      {
        key: Consts.URL_CREATE_ADVISOR_PROFIL,
        component: () =>
          import(/* webpackChunkName: "anonyme-create-advisor-profil" */ '@/views/anonyme/create-advisor-profil.vue'),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      },
      {
        key: Consts.URL_CREATE_ADVISOR_ERROR,
        component: () =>
          import(/* webpackChunkName: "anonyme-create-recruiter-error" */ '@/views/anonyme/create-recruiter-error.vue'),
        meta: {
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_ANONYME]
        }
      }
    ]
  },
  {
    key: Consts.URL_JOB_OFFER_LIST_CANDIDATE,
    component: () => import(/* webpackChunkName: "candidate-job-offer-list" */ '@/views/candidate/job-offer-list.vue'),
    meta: {
      loginRequired: false,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_ANONYME]
    },
    children: [
      {
        key: Consts.URL_JOB_OFFER_ID,
        path: Consts.URL_PARAMS_JOB_OFFER_ID,
        component: () => import(/* webpackChunkName: "anonyme-job-offer-detail" */ '@/views/anonyme/job-offer-detail.vue'),
        meta: { loginRequired: false, userTypeRequired: [Consts.USER_TYPE_ANONYME], displayDefaultNav: false },
        children: [
          {
            key: Consts.URL_JOB_OFFER_APPLY,
            component: () => import(/* webpackChunkName: "anonyme-job-offer-apply" */ '@/views/candidate/job-offer-apply.vue'),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_BUSINESS_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_LOGIN)
    },
    children: [
      {
        key: Consts.URL_BUSINESS_PROFIL,
        path: Consts.URL_PARAMS_BUSINESS_ID,
        component: () => import(/* webpackChunkName: "candidate-business-detail" */ '@/views/candidate/business-detail.vue'),
        meta: { loginRequired: false, userTypeRequired: [Consts.USER_TYPE_ANONYME], displayDefaultNav: false }
      }
    ]
  },
  {
    key: Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE,
    component: () =>
      import(/* webpackChunkName: "candidate-educational-program-list" */ '@/views/candidate/educational/program-list.vue'),
    meta: {
      loginRequired: false,
      userTypeRequired: [Consts.USER_TYPE_ANONYME],
      displayDefaultNav: false
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(/* webpackChunkName: "anonyme-educational-program-detail" */ '@/views/anonyme/program-detail.vue'),
        meta: { loginRequired: false, userTypeRequired: [Consts.USER_TYPE_ANONYME], displayDefaultNav: false },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_APPLY,
            component: () =>
              import(
                /* webpackChunkName: "anonyme-educational-program-apply" */ '@/views/candidate/educational/program-apply.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_INSTITUTION_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_LOGIN)
    },
    children: [
      {
        key: Consts.URL_INSTITUTION_PROFILE,
        path: Consts.URL_PARAMS_INSTITUTION_ID,
        component: () =>
          import(/* webpackChunkName: "candidate-institution-detail" */ '@/views/candidate/institution-detail.vue'),
        meta: { loginRequired: false, userTypeRequired: [Consts.USER_TYPE_ANONYME], displayDefaultNav: false }
      }
    ]
  }
]

const routeCandidate = [
  {
    // Ignore candidate registration process if already logged in so that the registration link doesn't return a 404.
    key: Consts.URL_REGISTER,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_CREATE_CANDIDATE_USER,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_HOME)
        }
      }
    ]
  },
  {
    key: Consts.URL_EMAIL_CONFIRMATION,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    }
  },
  {
    key: Consts.URL_LOGIN,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    }
  },
  {
    key: Consts.URL_HOME,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST_CANDIDATE)
    }
  },
  {
    key: Consts.URL_USER_SETTINGS,
    component: () => import(/* webpackChunkName: "generic-user-settings" */ '@/views/generic/user-settings.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    }
  },
  {
    key: Consts.URL_CONFIRM_EMAIL_CHANGE,
    component: () => import(/* webpackChunkName: "generic-confirm-email-change" */ '@/views/generic/confirm-email-change.vue'),
    meta: {
      loginRequired: true,
      displayNav: false,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    }
  },
  {
    key: Consts.URL_APPLICATIONS_LIST,
    component: () => import(/* webpackChunkName: "candidate-applications-list" */ '@/views/candidate/applications-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    }
  },
  {
    key: Consts.URL_APPLICATION_CONVOCATIONS_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
    },
    children: [
      {
        key: Consts.URL_BUSINESS_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
        },
        children: [
          {
            key: Consts.URL_APPLICATION_CONVOCATION,
            path: Consts.URL_PARAMS_APPLICATION_ID,
            component: () =>
              import(/* webpackChunkName: "candidate-application-convocation" */ '@/views/candidate/application-convocation.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
            }
          }
        ]
      },
      {
        key: Consts.URL_INSTITUTION_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_APPLICATION_CONVOCATION,
            path: Consts.URL_PARAMS_APPLICATION_ID,
            component: () =>
              import(
                /* webpackChunkName: "candidate-study-program-application-convocation" */ '@/views/candidate/study-program-application-convocation.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_JOB_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
    },
    children: [
      {
        key: Consts.URL_JOB_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "job-interview-waiting-room" */ '@/views/interview/waiting-room/job-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE],
          displayNav: false,
          displayDefaultNav: false,
          displayUpcomingInterviewNotification: false
        },
        children: [
          {
            key: Consts.URL_JOB_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_CANDIDATE],
              displayDefaultNav: false,
              displayNav: false,
              displayUpcomingInterviewNotification: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "study-program-interview-waiting-room" */ '@/views/interview/waiting-room/study-program-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE],
          displayNav: false,
          displayDefaultNav: false,
          displayUpcomingInterviewNotification: false
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_CANDIDATE],
              displayDefaultNav: false,
              displayNav: false,
              displayUpcomingInterviewNotification: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_CREATE_CANDIDATE_PROFIL,
    component: () =>
      import(/* webpackChunkName: "candidate-create-candidate-profil" */ '@/views/candidate/create-candidate-profil.vue'),
    meta: {
      loginRequired: true,
      displayDefaultNav: false,
      displayNav: false,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    }
  },
  {
    key: Consts.URL_BUSINESS_LIST,
    component: () => import(/* webpackChunkName: "advisor-business-list" */ '@/views/advisor/business-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    },
    children: [
      {
        key: Consts.URL_BUSINESS_PROFIL,
        path: Consts.URL_PARAMS_BUSINESS_ID,
        component: () => import(/* webpackChunkName: "candidate-business-detail" */ '@/views/candidate/business-detail.vue'),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
      }
    ]
  },
  {
    key: Consts.URL_JOB_OFFER_LIST_CANDIDATE,
    component: () => import(/* webpackChunkName: "candidate-job-offer-list" */ '@/views/candidate/job-offer-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    },
    children: [
      {
        key: Consts.URL_JOB_OFFER_ID,
        path: Consts.URL_PARAMS_JOB_OFFER_ID,
        component: () => import(/* webpackChunkName: "candidate-job-offer-detail" */ '@/views/candidate/job-offer-detail.vue'),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false },
        children: [
          {
            key: Consts.URL_JOB_OFFER_APPLY,
            component: () => import(/* webpackChunkName: "candidate-job-offer-apply" */ '@/views/candidate/job-offer-apply.vue'),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
          },
          {
            key: Consts.URL_JOB_OFFER_APPLY_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "candidate-job-offer-apply-submitted" */ '@/views/candidate/job-offer-apply-submitted.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE] }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE,
    component: () =>
      import(/* webpackChunkName: "candidate-educational-program-list" */ '@/views/candidate/educational/program-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(
            /* webpackChunkName: "candidate-educational-program-detail" */ '@/views/candidate/educational/program-detail.vue'
          ),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_APPLY,
            component: () =>
              import(
                /* webpackChunkName: "candidate-educational-program-apply" */ '@/views/candidate/educational/program-apply.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_APPLY_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "candidate-educational-program-apply-submitted" */ '@/views/candidate/educational/program-apply-submitted.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE] }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_FAVORITE_LIST,
    component: () => import(/* webpackChunkName: "candidate-favorite-list" */ '@/views/candidate/favorite-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(
            /* webpackChunkName: "candidate-educational-program-detail" */ '@/views/candidate/educational/program-detail.vue'
          ),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_APPLY,
            component: () =>
              import(
                /* webpackChunkName: "candidate-educational-program-apply" */ '@/views/candidate/educational/program-apply.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_APPLY_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "candidate-educational-program-apply-submitted" */ '@/views/candidate/educational/program-apply-submitted.vue'
              ),
            meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE] }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_CANDIDATE_CV,
    component: () => import(/* webpackChunkName: "candidate-profiles" */ '@/views/candidate/profiles.vue'),
    meta: {
      loginRequired: true,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
    },
    children: [
      {
        key: Consts.URL_CANDIDATE_EDIT,
        component: () => import(/* webpackChunkName: "candidate-profile-edit" */ '@/views/candidate/profile-edition.vue'),
        meta: {
          loginRequired: true,
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
        }
      },
      {
        key: Consts.URL_CANDIDATE_PROFIL_PRO_PREVIEW,
        component: () => import(/* webpackChunkName: "candidate-profile-detail" */ '@/views/candidate/profile-detail.vue'),
        meta: {
          loginRequired: true,
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
        }
      },
      {
        key: Consts.URL_CANDIDATE_PROFIL_EDUCATIONAL_PREVIEW,
        component: () =>
          import(
            /* webpackChunkName: "candidate-profile-detail-educational" */ '@/views/candidate/profile-detail-educational.vue'
          ),
        meta: {
          loginRequired: true,
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
        }
      },
      {
        key: Consts.URL_CANDIDATE_EDIT_PRO,
        component: () =>
          import(/* webpackChunkName: "candidate-profile-edition-pro" */ '@/views/candidate/profile-edition-pro.vue'),
        meta: {
          loginRequired: true,
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
        }
      },
      {
        key: Consts.URL_CANDIDATE_EDIT_EDUCATIONAL,
        component: () =>
          import(
            /* webpackChunkName: "candidate-profile-edition-educational" */ '@/views/candidate/profile-edition-educational.vue'
          ),
        meta: {
          loginRequired: true,
          displayDefaultNav: false,
          userTypeRequired: [Consts.USER_TYPE_CANDIDATE]
        }
      }
    ]
  },
  {
    key: Consts.URL_INSTITUTION_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE)
    },
    children: [
      {
        key: Consts.URL_INSTITUTION_PROFILE,
        path: Consts.URL_PARAMS_INSTITUTION_ID,
        component: () =>
          import(/* webpackChunkName: "candidate-institution-detail" */ '@/views/candidate/institution-detail.vue'),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_CANDIDATE], displayDefaultNav: false }
      }
    ]
  }
]

const routeAdvisor = [
  {
    // Ignore candidate registration process if already logged in so that the registration link doesn't return a 404.
    key: Consts.URL_REGISTER,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_CREATE_ADVISOR_PROFIL,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_HOME)
        }
      }
    ]
  },
  {
    key: Consts.URL_LOGIN,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST_CANDIDATE)
    }
  },
  {
    key: Consts.TWILIO_URL,
    component: () => import(/* webpackChunkName: "doc" */ '@/views/interview/twilio-app.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_JOB_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_JOB_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "job-interview-waiting-room" */ '@/views/interview/waiting-room/job-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          displayNav: false,
          displayDefaultNav: false
        },
        children: [
          {
            key: Consts.URL_JOB_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              displayDefaultNav: false,
              displayNav: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "study-program-interview-waiting-room" */ '@/views/interview/waiting-room/study-program-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          displayNav: false,
          displayDefaultNav: false
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              displayDefaultNav: false,
              displayNav: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_JOB_OFFER_LIST_CANDIDATE,
    component: () => import(/* webpackChunkName: "advisor-job-offer-list" */ '@/views/candidate/job-offer-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-list')
    },
    children: [
      {
        key: Consts.URL_JOB_OFFER_ID,
        path: Consts.URL_PARAMS_JOB_OFFER_ID,
        component: () => import(/* webpackChunkName: "recruiter-job-offer-detail" */ '@/views/recruiter/job-offer-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.jobOfferId),
          displayBreadcrumb: true
        }
      }
    ]
  },
  {
    key: Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE,
    component: () =>
      import(/* webpackChunkName: "advisor-institution-program-list" */ '@/views/candidate/educational/program-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-study-program-list')
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-program-detail" */ '@/views/recruiter-educational/program/detail.vue'
          ),
        meta: {
          loginRequired: false,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.studyProgramId),
          displayBreadcrumb: true
        }
      }
    ]
  },
  {
    key: Consts.URL_EDIT_ADVISORS,
    component: () => import(/* webpackChunkName: "advisor-edit-advisors" */ '@/views/advisor/edit-advisors.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR]
    }
  },
  {
    key: Consts.URL_CANDIDATE_LIST,
    component: () => import(/* webpackChunkName: "advisor-candidate-list" */ '@/views/advisor/candidate-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-candidate-list')
    },
    children: [
      {
        key: Consts.URL_CANDIDATE_CV,
        path: Consts.URL_CANDIDATE_CV_ID,
        component: () =>
          import(/* webpackChunkName: "advisor-candidate-profile-detail" */ '@/views/advisor/candidate-profile-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: async (params) => await BreadcrumbHelper.candidateName(params.candidateId)
        },
        children: [
          {
            key: Consts.URL_APPLICATIONS_LIST,
            component: () =>
              import(/* webpackChunkName: "advisor-applications-list" */ '@/views/advisor/candidate-applications-list.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-applications-list'),
              displayBreadcrumb: true,
              isAbsolutePos: true
            }
          }
        ]
      },
      {
        key: Consts.URL_CANDIDATE_APPLICATION,
        meta: {
          breadcrumb: () => i18n.instance().t('breadcrumb-applications-list')
        },
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_BUSINESS_LIST)
        },
        children: [
          {
            key: Consts.URL_CANDIDATE_APPLICATION_CV,
            path: Consts.URL_PARAMS_APPLICATION_ID,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-candidate-profile-detail" */ '@/views/recruiter/candidate-profile-detail.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: async (params) => await BreadcrumbHelper.candidateNameByApplication(params.applicationId),
              displayBreadcrumb: true
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_APPLICATION_CONVOCATIONS_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
    },
    children: [
      {
        key: Consts.URL_BUSINESS_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
        },
        children: [
          {
            key: Consts.URL_APPLICATION_CONVOCATION,
            path: Consts.URL_PARAMS_APPLICATION_ID,
            component: () =>
              import(/* webpackChunkName: "candidate-application-convocation" */ '@/views/candidate/application-convocation.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR]
            }
          }
        ]
      },
      {
        key: Consts.URL_INSTITUTION_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_APPLICATIONS_LIST)
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_APPLICATION_CONVOCATION,
            path: Consts.URL_PARAMS_APPLICATION_ID,
            component: () =>
              import(
                /* webpackChunkName: "candidate-study-program-application-convocation" */ '@/views/candidate/study-program-application-convocation.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR]
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_MISSION_LIST,
    component: () => import(/* webpackChunkName: "advisor-mission-list" */ '@/views/advisor/mission-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-mission-list')
    },
    children: [
      {
        key: Consts.URL_MISSION_CREATE,
        component: () => import(/* webpackChunkName: "advisor-mission-creation" */ '@/views/advisor/mission-creation.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-create-mission'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_MISSION_CREATED,
        component: () => import(/* webpackChunkName: "advisor-mission-created" */ '@/views/advisor/mission-created.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-mission-created'),
          displayBreadcrumb: true,
          isAbsolutePos: true
        }
      },
      {
        key: Consts.URL_MISSION,
        path: Consts.URL_PARAMS_MISSION_ID,
        component: () => import(/* webpackChunkName: "advisor-mission-edition" */ '@/views/advisor/mission-edition.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-mission-edit'),
          displayBreadcrumb: true
        }
      }
    ]
  },
  {
    key: Consts.URL_INSTITUTION_LIST,
    component: () => import(/* webpackChunkName: "advisor-institution-list" */ '@/views/advisor/institution/list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-institution-list')
    },
    children: [
      {
        key: Consts.URL_INSTITUTION_CREATE,
        component: () =>
          import(/* webpackChunkName: "advisor-institution-creation" */ '@/views/advisor/institution/creation.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-institution-create'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_INSTITUTION_CREATED,
        component: () => import(/* webpackChunkName: "advisor-institution-created" */ '@/views/advisor/institution/created.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-institution-created'),
          displayBreadcrumb: true,
          isAbsolutePos: true
        }
      },
      {
        key: Consts.URL_INSTITUTION_PROFILE,
        path: Consts.URL_PARAMS_INSTITUTION_ID,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-institution-detail" */ '@/views/recruiter-educational/institution/detail.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: async (params) => await BreadcrumbHelper.institutionName(params.institutionId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL,
            component: () =>
              import(
                /* webpackChunkName: "advisor-institution-edit-recruiters-educational" */ '@/views/advisor/institution/edit-recruiters-educational.vue'
              ),
            meta: {
              loginRequired: true,
              breadcrumb: () => i18n.instance().t('institution-edit-recruiters-educational-title'),
              displayBreadcrumb: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR]
            }
          },
          {
            key: Consts.URL_INSTITUTION_PROFILE_EDITION,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-institution-profile-edition" */ '@/views/recruiter-educational/institution/profile-edition.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-edit-institution'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_INSTITUTION_PROFILE_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-institution-profile-submitted" */ '@/views/recruiter-educational/institution/profile-submitted.vue'
              ),
            meta: {
              loginRquired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-institution-submitted'),
              displayBreadcrumb: true,
              isAbsolutePos: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_LIST,
            component: () =>
              import(/* webpackChunkName: "advisor-institution-program-list" */ '@/views/advisor/institution/program/list.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-institution-program-list'),
              displayBreadcrumb: true
            },
            children: [
              {
                key: Consts.URL_STUDY_PROGRAM_SUBMITTED,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-submitted" */ '@/views/recruiter-educational/program/submitted.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-institution-program-submit'),
                  displayBreadcrumb: true,
                  isAbsolutePos: true
                }
              },
              {
                key: Consts.URL_STUDY_PROGRAM_ID,
                path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-detail" */ '@/views/recruiter-educational/program/detail.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: async (params) => await BreadcrumbHelper.draftProgramTitle(params.studyProgramPostingId),
                  displayBreadcrumb: true
                }
              }
            ]
          },
          {
            key: Consts.URL_STUDY_PROGRAM_CREATE,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-edition" */ '@/views/recruiter-educational/program/edition.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-creation'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_PUBLISHED,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-published" */ '@/views/recruiter-educational/program/published.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-publish'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_ID,
            path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-preview" */ '@/views/recruiter-educational/program/preview.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: async (params) => await BreadcrumbHelper.draftProgramTitle(params.studyProgramPostingId),
              displayBreadcrumb: true
            },
            children: [
              {
                key: Consts.URL_STUDY_PROGRAM_PIPELINE,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-pipeline" */ '@/views/recruiter-educational/program/pipeline.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-pipeline'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_STUDY_PROGRAM_SORT_NEW,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-sort" */ '@/views/recruiter-educational/program/sort.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-pipeline-sort'),
                  displayBreadcrumb: true,
                  isAbsolutePos: true
                }
              },
              {
                key: Consts.URL_STUDY_PROGRAM_EDIT,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-edition" */ '@/views/recruiter-educational/program/edition.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-edit'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_STUDY_PROGRAM_PREVIEW,
                component: () =>
                  import(
                    /* webpackChunkName: "recruiter-educational-program-preview" */ '@/views/recruiter-educational/program/preview.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-study-program-preview'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_STUDY_PROGRAM_REVIEW,
                component: () =>
                  import(
                    /* webpackChunkName: "advisor-institution-program-review" */ '@/views/advisor/institution/program/review.vue'
                  ),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-review'),
                  displayBreadcrumb: true
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_BUSINESS_LIST,
    component: () => import(/* webpackChunkName: "advisor-business-list" */ '@/views/advisor/business-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-business')
    },
    children: [
      {
        key: Consts.URL_BUSINESS_CREATE,
        component: () => import(/* webpackChunkName: "advisor-business-creation" */ '@/views/advisor/business-creation.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-business-create'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_BUSINESS_CREATED,
        component: () => import(/* webpackChunkName: "advisor-business-created" */ '@/views/advisor/business-created.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: () => i18n.instance().t('breadcrumb-business-created'),
          displayBreadcrumb: true,
          isAbsolutePos: true
        }
      },
      {
        key: Consts.URL_BUSINESS_PROFIL,
        path: Consts.URL_PARAMS_BUSINESS_ID,
        component: () => import(/* webpackChunkName: "recruiter-business-detail" */ '@/views/recruiter/business-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_ADVISOR],
          breadcrumb: async (params) => await BreadcrumbHelper.businessName(params.businessId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_BUSINESS_PROFIL_EDITION,
            component: () =>
              import(/* webpackChunkName: "recruiter-business-profil-edition" */ '@/views/recruiter/business-profil-edition.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-edition'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_BUSINESS_PROFIL_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-business-profil-submitted" */ '@/views/recruiter/business-profil-submitted.vue'
              ),
            meta: {
              loginRquired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-submit'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_BUSINESS_EDIT_RECRUITERS,
            component: () =>
              import(/* webpackChunkName: "advisor-business-edit-recruiters" */ '@/views/advisor/business-edit-recruiters.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-edit-recruiters'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_LIST,
            component: () => import(/* webpackChunkName: "advisor-job-offer-list" */ '@/views/advisor/job-offer-list.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-job-offers'),
              displayBreadcrumb: true
            },
            children: [
              {
                key: Consts.URL_JOB_OFFER_SUBMITTED,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-submitted" */ '@/views/recruiter/job-offer-submitted.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-submit'),
                  displayBreadcrumb: true,
                  isAbsolutePos: true
                }
              },
              {
                key: Consts.URL_JOB_OFFER_ID,
                path: Consts.URL_PARAMS_JOB_OFFER_ID,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-detail" */ '@/views/recruiter/job-offer-detail.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.jobOfferId),
                  displayBreadcrumb: true
                }
              }
            ]
          },
          {
            key: Consts.URL_JOB_OFFER_CREATE,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-edition" */ '@/views/recruiter/job-offer-edition.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-creation'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_PUBLISHED,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-published" */ '@/views/recruiter/job-offer-published.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-published'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_ID,
            path: Consts.URL_PARAMS_JOB_OFFER_ID,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-preview" */ '@/views/recruiter/job-offer-preview.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.jobOfferId),
              displayBreadcrumb: true
            },
            children: [
              {
                key: Consts.URL_JOB_OFFER_PIPELINE,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-pipeline" */ '@/views/recruiter/job-offer-pipeline.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-pipeline'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_JOB_OFFER_SORT_NEW,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-sort" */ '@/views/recruiter/job-offer-sort.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-pipeline-sort'),
                  displayBreadcrumb: true,
                  isAbsolutePos: true
                }
              },
              {
                key: Consts.URL_JOB_OFFER_EDIT,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-edition" */ '@/views/recruiter/job-offer-edition.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-edit'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_JOB_OFFER_PREVIEW,
                component: () =>
                  import(/* webpackChunkName: "recruiter-job-offer-preview" */ '@/views/recruiter/job-offer-preview.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-preview'),
                  displayBreadcrumb: true
                }
              },
              {
                key: Consts.URL_JOB_OFFER_REVIEW,
                component: () =>
                  import(/* webpackChunkName: "advisor-job-offer-review" */ '@/views/advisor/job-offer-review.vue'),
                meta: {
                  loginRequired: true,
                  userTypeRequired: [Consts.USER_TYPE_ADVISOR],
                  breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-review'),
                  displayBreadcrumb: true
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_HOME,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_BUSINESS_LIST)
    }
  },
  {
    key: Consts.URL_USER_SETTINGS,
    component: () => import(/* webpackChunkName: "generic-user-settings" */ '@/views/generic/user-settings.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR]
    }
  },
  {
    key: Consts.URL_CONFIRM_EMAIL_CHANGE,
    component: () => import(/* webpackChunkName: "generic-confirm-email-change" */ '@/views/generic/confirm-email-change.vue'),
    meta: {
      loginRequired: true,
      displayNav: false,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR]
    }
  },
  {
    key: Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION,
    component: () =>
      import(
        /* webpackChunkName: "advisor-agenda-list-by-company-institution" */ '@/views/advisor/agenda/list-by-company-institution.vue'
      ),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_ADVISOR],
      breadcrumb: () => i18n.instance().t('breadcrumb-agendas-list')
    },
    children: [
      {
        key: Consts.URL_COMPANY_AGENDA_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION)
        },
        meta: {
          breadcrumb: () => i18n.instance().t('breadcrumb-agendas-company-list')
        },
        children: [
          {
            key: Consts.URL_COMPANY_AGENDA_LIST_DETAIL,
            path: Consts.URL_PARAMS_BUSINESS_ID,
            component: () =>
              import(/* webpackChunkName: "company-agenda-list" */ '@/views/advisor/agenda/company-agenda-list.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: async (params) => await BreadcrumbHelper.businessName(params.businessId),
              displayBreadcrumb: true
            }
          }
        ]
      },
      {
        key: Consts.URL_INSTITUTION_AGENDA_LIST,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION)
        },
        meta: {
          breadcrumb: () => i18n.instance().t('breadcrumb-agendas-institution-list')
        },
        children: [
          {
            key: Consts.URL_INSTITUTION_AGENDA_LIST_DETAIL,
            path: Consts.URL_PARAMS_INSTITUTION_ID,
            component: () =>
              import(/* webpackChunkName: "institution-agenda-list" */ '@/views/advisor/agenda/institution-agenda-list.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_ADVISOR],
              breadcrumb: async (params) => await BreadcrumbHelper.institutionName(params.institutionId),
              displayBreadcrumb: true
            }
          }
        ]
      }
    ]
  }
]

const routeRecruiter = [
  {
    // Ignore candidate registration process if already logged in so that the registration link doesn't return a 404.
    key: Consts.URL_REGISTER,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_CREATE_RECUITER_PROFIL,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_HOME)
        }
      }
    ]
  },
  {
    key: Consts.URL_LOGIN,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    }
  },
  {
    key: Consts.TWILIO_URL,
    component: () => import(/* webpackChunkName: "doc" */ '@/views/interview/twilio-app.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_HOME,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST)
    }
  },
  {
    key: Consts.URL_JOB_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_JOB_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "job-interview-waiting-room" */ '@/views/interview/waiting-room/job-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          displayNav: false,
          displayDefaultNav: false
        },
        children: [
          {
            key: Consts.URL_JOB_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              displayDefaultNav: false,
              displayNav: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_USER_SETTINGS,
    component: () => import(/* webpackChunkName: "generic-user-settings" */ '@/views/generic/user-settings.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER]
    }
  },
  {
    key: Consts.URL_CONFIRM_EMAIL_CHANGE,
    component: () => import(/* webpackChunkName: "generic-confirm-email-change" */ '@/views/generic/confirm-email-change.vue'),
    meta: {
      loginRequired: true,
      displayNav: false,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER]
    }
  },
  {
    key: Consts.URL_JOB_OFFER_LIST,
    component: () => import(/* webpackChunkName: "recruiter-job-offer-list" */ '@/views/recruiter/job-offer-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER],
      breadcrumb: () => i18n.instance().t('breadcrumb-business-job-offers')
    },
    children: [
      {
        key: Consts.URL_JOB_OFFER_CREATE,
        component: () => import(/* webpackChunkName: "recruiter-job-offer-edition" */ '@/views/recruiter/job-offer-edition.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-creation'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_JOB_OFFER_SUBMITTED,
        component: () =>
          import(/* webpackChunkName: "recruiter-job-offer-submitted" */ '@/views/recruiter/job-offer-submitted.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-submit'),
          displayBreadcrumb: true,
          isAbsolutePos: true
        }
      },
      {
        key: Consts.URL_JOB_OFFER_PUBLISHED,
        component: () =>
          import(/* webpackChunkName: "recruiter-job-offer-published" */ '@/views/recruiter/job-offer-published.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-published'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_JOB_OFFER_ID,
        path: Consts.URL_PARAMS_JOB_OFFER_ID,
        component: () => import(/* webpackChunkName: "recruiter-job-offer-detail" */ '@/views/recruiter/job-offer-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.jobOfferId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_JOB_OFFER_EDIT,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-edition" */ '@/views/recruiter/job-offer-edition.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-edit'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_PREVIEW,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-preview" */ '@/views/recruiter/job-offer-preview.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-job-offer-preview'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_PIPELINE,
            component: () =>
              import(/* webpackChunkName: "recruiter-job-offer-pipeline" */ '@/views/recruiter/job-offer-pipeline.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-pipeline'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_JOB_OFFER_SORT_NEW,
            component: () => import(/* webpackChunkName: "recruiter-job-offer-sort" */ '@/views/recruiter/job-offer-sort.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-pipeline-sort'),
              displayBreadcrumb: true,
              isAbsolutePos: true
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_BUSINESS_LIST,
    meta: {
      breadcrumb: () => i18n.instance().t('breadcrumb-business')
    },
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_LOGIN)
    },
    children: [
      {
        key: Consts.URL_BUSINESS_PROFIL,
        path: Consts.URL_PARAMS_BUSINESS_ID,
        component: () => import(/* webpackChunkName: "recruiter-business-detail" */ '@/views/recruiter/business-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER],
          breadcrumb: async (params) => await BreadcrumbHelper.businessName(params.businessId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_BUSINESS_EDIT_RECRUITERS,
            component: () =>
              import(/* webpackChunkName: "advisor-business-edit-recruiters" */ '@/views/advisor/business-edit-recruiters.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-edit-recruiters'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_BUSINESS_PROFIL_EDITION,
            component: () =>
              import(/* webpackChunkName: "recruiter-business-profil-edition" */ '@/views/recruiter/business-profil-edition.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-mission-edit'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_BUSINESS_PROFIL_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-business-profil-submitted" */ '@/views/recruiter/business-profil-submitted.vue'
              ),
            meta: {
              loginRquired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER],
              breadcrumb: () => i18n.instance().t('breadcrumb-business-submit'),
              displayBreadcrumb: true
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION,
    component: () => import(/* webpackChunkName: "recruiter-agenda-list" */ '@/views/recruiter/agenda/list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER]
    }
  },
  {
    key: Consts.URL_JOB_OFFER_LIST_CANDIDATE,
    component: () => import(/* webpackChunkName: "candidate-job-offer-list" */ '@/views/candidate/job-offer-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER]
    },
    children: [
      {
        key: Consts.URL_JOB_OFFER_ID,
        path: Consts.URL_PARAMS_JOB_OFFER_ID,
        component: () => import(/* webpackChunkName: "anonyme-job-offer-detail" */ '@/views/anonyme/job-offer-detail.vue'),
        meta: { loginRequired: true, userTypeRequired: [Consts.USER_TYPE_RECRUITER] }
      }
    ]
  }
]

const routeRecruiterEducational = [
  {
    // Ignore candidate registration process if already logged in so that the registration link doesn't return a 404.
    key: Consts.URL_REGISTER,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_CREATE_RECUITER_PROFIL,
        redirect: (to) => {
          return i18n.getLocalizedUrl(Consts.URL_HOME)
        }
      }
    ]
  },
  {
    key: Consts.URL_LOGIN,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    }
  },
  {
    key: Consts.TWILIO_URL,
    component: () => import(/* webpackChunkName: "doc" */ '@/views/interview/twilio-app.vue'),
    meta: {
      displayDefaultNav: false
    }
  },
  {
    key: Consts.URL_HOME,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_STUDY_PROGRAM_LIST)
    }
  },
  {
    key: Consts.URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST,
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_HOME)
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM,
        path: Consts.URL_PARAMS_APPLICATION_ID,
        component: () =>
          import(
            /* webpackChunkName: "study-program-interview-waiting-room" */ '@/views/interview/waiting-room/study-program-interview-waiting-room.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          displayNav: false,
          displayDefaultNav: false
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_INTERVIEW_CONNECTION,
            component: () => import(/* webpackChunkName: "interview-connection" */ '@/views/interview/interview-connection.vue'),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              displayDefaultNav: false,
              displayNav: false
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_USER_SETTINGS,
    component: () => import(/* webpackChunkName: "generic-user-settings" */ '@/views/generic/user-settings.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL]
    }
  },
  {
    key: Consts.URL_CONFIRM_EMAIL_CHANGE,
    component: () => import(/* webpackChunkName: "generic-confirm-email-change" */ '@/views/generic/confirm-email-change.vue'),
    meta: {
      loginRequired: true,
      displayNav: false,
      displayDefaultNav: false,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL]
    }
  },
  {
    key: Consts.URL_STUDY_PROGRAM_LIST,
    component: () =>
      import(/* webpackChunkName: "recruiter-educational-program-list" */ '@/views/recruiter-educational/program/list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
      breadcrumb: () => i18n.instance().t('breadcrumb-institution-program-list')
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_CREATE,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-program-edition" */ '@/views/recruiter-educational/program/edition.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-creation'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_STUDY_PROGRAM_SUBMITTED,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-program-submitted" */ '@/views/recruiter-educational/program/submitted.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: () => i18n.instance().t('breadcrumb-institution-program-submit'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_STUDY_PROGRAM_PUBLISHED,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-program-published" */ '@/views/recruiter-educational/program/published.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-publish'),
          displayBreadcrumb: true
        }
      },
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-program-detail" */ '@/views/recruiter-educational/program/preview.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: async (params) => await BreadcrumbHelper.draftProgramTitle(params.studyProgramPostingId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_STUDY_PROGRAM_EDIT,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-edition" */ '@/views/recruiter-educational/program/edition.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: () => i18n.instance().t('breadcrumb-studyprogram-edit'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_PREVIEW,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-preview" */ '@/views/recruiter-educational/program/preview.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: () => i18n.instance().t('breadcrumb-study-program-preview'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_PIPELINE,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-pipeline" */ '@/views/recruiter-educational/program/pipeline.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: async (params) => await BreadcrumbHelper.draftProgramTitle(params.studyProgramPostingId),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_STUDY_PROGRAM_SORT_NEW,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-program-sort" */ '@/views/recruiter-educational/program/sort.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: () => i18n.instance().t('breadcrumb-pipeline-sort'),
              displayBreadcrumb: true,
              isAbsolutePos: true
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_INSTITUTION_LIST,
    meta: {
      breadcrumb: () => i18n.instance().t('breadcrumb-institution-list')
    },
    redirect: (to) => {
      return i18n.getLocalizedUrl(Consts.URL_LOGIN)
    },
    children: [
      {
        key: Consts.URL_INSTITUTION_PROFILE,
        path: Consts.URL_PARAMS_INSTITUTION_ID,
        component: () =>
          import(
            /* webpackChunkName: "recruiter-educational-institution-detail" */ '@/views/recruiter-educational/institution/detail.vue'
          ),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: async (params) => await BreadcrumbHelper.institutionName(params.institutionId),
          displayBreadcrumb: true
        },
        children: [
          {
            key: Consts.URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL,
            component: () =>
              import(
                /* webpackChunkName: "advisor-institution-edit-recruiters-educational" */ '@/views/advisor/institution/edit-recruiters-educational.vue'
              ),
            meta: {
              loginRequired: true,
              breadcrumb: () => i18n.instance().t('institution-edit-recruiters-educational-title'),
              displayBreadcrumb: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL]
            }
          },
          {
            key: Consts.URL_INSTITUTION_PROFILE_EDITION,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-institution-profile-edition" */ '@/views/recruiter-educational/institution/profile-edition.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: () => i18n.instance().t('breadcrumb-edit-institution'),
              displayBreadcrumb: true
            }
          },
          {
            key: Consts.URL_INSTITUTION_PROFILE_SUBMITTED,
            component: () =>
              import(
                /* webpackChunkName: "recruiter-educational-institution-profile-submitted" */ '@/views/recruiter-educational/institution/profile-submitted.vue'
              ),
            meta: {
              loginRequired: true,
              userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
              breadcrumb: () => i18n.instance().t('breadcrumb-institution-submitted'),
              displayBreadcrumb: true
            }
          }
        ]
      }
    ]
  },
  {
    key: Consts.URL_AGENDA_LIST_BY_COMPANY_INSTITUTION,
    component: () =>
      import(/* webpackChunkName: "recruiter-educational-agenda-list" */ '@/views/recruiter-educational/agenda/list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL]
    }
  },
  {
    key: Consts.URL_STUDY_PROGRAM_LIST_CANDIDATE,
    component: () =>
      import(/* webpackChunkName: "advisor-institution-program-list" */ '@/views/candidate/educational/program-list.vue'),
    meta: {
      loginRequired: true,
      userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
      breadcrumb: () => i18n.instance().t('breadcrumb-study-program-list')
    },
    children: [
      {
        key: Consts.URL_STUDY_PROGRAM_ID,
        path: Consts.URL_PARAMS_STUDY_PROGRAM_ID,
        component: () =>
          import(/* webpackChunkName: "recruiter-educational-program-detail" */ '@/views/anonyme/program-detail.vue'),
        meta: {
          loginRequired: true,
          userTypeRequired: [Consts.USER_TYPE_RECRUITER_EDUCATIONAL],
          breadcrumb: async (params) => await BreadcrumbHelper.draftJobPostingTitle(params.studyProgramId),
          displayBreadcrumb: true
        }
      }
    ]
  }
]

const instance = createRouter([...routePublic, ...routeAnonyme])
instance.beforeEach(async (to, from, next) => {
  await Status.handleIsInMaintenance(to.meta.key)

  if (from.matched.length === 0) {
    if (to.params.lang === 'fr' || to.params.lang === 'en') {
      i18n.setLang(to.params.lang)
    } else {
      i18n.setLang('fr')
    }
  }

  if (to.matched.length === 0) {
    if (await validateIsLogged(to, from, next)) {
      next(i18n.getLocalizedUrl(Consts.URL_404))
    }
  } else if (
    validateLang(to, from, next) &&
    (!to.meta.loginRequired ||
      (to.meta.loginRequired && (await validateIsLogged(to, from, next)) && (await validateCandidateProfil(to, from, next)))) &&
    (await validateUserType(to, from, next))
  ) {
    App.updateDisplayAnyNav(to.meta.displayNav)
    App.updateDisplayMainNav(to.meta.displayDefaultNav)
    App.updateDisplayBreadcrumb(to.meta.displayBreadcrumb, to.meta.isAbsolutePos)
    App.updateDisplayUpcomingInterviewNotification(to.meta.displayUpcomingInterviewNotification)
    next()
  }
})

const removeLoading = () => {
  Array.from(document.getElementsByClassName('app-loading')).forEach((elm) => {
    elm.parentNode.removeChild(elm)
  })
}
instance.onReady(removeLoading, removeLoading)

export default {
  instance,
  changeLang: () => {
    const businessId = instance.currentRoute.params.businessId
    const jobOfferId = instance.currentRoute.params.jobOfferId
    const institutionId = instance.currentRoute.params.institutionId
    const studyProgramPostingId = instance.currentRoute.params.studyProgramPostingId
    const applicationId = instance.currentRoute.params.applicationId
    const missionId = instance.currentRoute.params.missionId
    const candidateId = instance.currentRoute.params.candidateId

    i18n.changeLang()
    instance.replace(
      i18n
        .getLocalizedUrl(instance.currentRoute.meta.key)
        .replace(Consts.URL_PARAMS_JOB_OFFER_ID, jobOfferId)
        .replace(Consts.URL_PARAMS_BUSINESS_ID, businessId)
        .replace(Consts.URL_PARAMS_STUDY_PROGRAM_ID, studyProgramPostingId)
        .replace(Consts.URL_PARAMS_INSTITUTION_ID, institutionId)
        .replace(Consts.URL_PARAMS_APPLICATION_ID, applicationId)
        .replace(Consts.URL_PARAMS_MISSION_ID, missionId)
        .replace(Consts.URL_CANDIDATE_CV_ID, candidateId)
    )
  },
  setUrlByUserType: (type) => {
    switch (type) {
      case Consts.USER_TYPE_CANDIDATE: {
        const newRouter = createRouter([...routePublic, ...routeCandidate])
        instance.matcher = newRouter.matcher
        break
      }
      case Consts.USER_TYPE_RECRUITER: {
        const newRouter = createRouter([...routePublic, ...routeRecruiter])
        instance.matcher = newRouter.matcher
        break
      }
      case Consts.USER_TYPE_RECRUITER_EDUCATIONAL: {
        const newRouter = createRouter([...routePublic, ...routeRecruiterEducational])
        instance.matcher = newRouter.matcher
        break
      }
      case Consts.USER_TYPE_ADVISOR: {
        const newRouter = createRouter([...routePublic, ...routeAdvisor])
        instance.matcher = newRouter.matcher
        break
      }
      case Consts.USER_TYPE_ANONYME: {
        const newRouter = createRouter([...routePublic, ...routeAnonyme])
        instance.matcher = newRouter.matcher
        break
      }
    }
  }
}

function createRouter(routesDef) {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/:lang',
        component: () => import(/* webpackChunkName: "routing" */ '@/views/routing.vue'),
        children: generateRouteMultilangue(routesDef)
      },
      {
        path: '/',
        redirect: (to) => {
          return i18n.instance().locale
        }
      }
    ]
  })
}

function validateLang(to, from, next) {
  let lang = to.params.lang
  if (!lang) lang = to.fullPath.substring(1).split('/').first()

  if (!i18n.isLangValid(lang)) {
    next(i18n.instance().locale)
    return false
  }

  if (i18n.instance().locale !== lang) {
    next()
    const businessId = to.params.businessId
    const jobOfferId = to.params.jobOfferId
    const institutionId = to.params.institutionId
    const studyProgramPostingId = to.params.studyProgramPostingId
    const applicationId = to.params.applicationId
    const missionId = to.params.missionId
    const candidateId = to.params.candidateId

    instance.replace(
      i18n
        .getLocalizedUrl(to.meta.key)
        .replace(Consts.URL_PARAMS_JOB_OFFER_ID, jobOfferId)
        .replace(Consts.URL_PARAMS_BUSINESS_ID, businessId)
        .replace(Consts.URL_PARAMS_STUDY_PROGRAM_ID, studyProgramPostingId)
        .replace(Consts.URL_PARAMS_INSTITUTION_ID, institutionId)
        .replace(Consts.URL_PARAMS_APPLICATION_ID, applicationId)
        .replace(Consts.URL_PARAMS_MISSION_ID, missionId)
        .replace(Consts.URL_CANDIDATE_CV_ID, candidateId)
    )
    return false
  }

  return true
}

async function validateIsLogged(to, from, next) {
  if (!(await Authentification.getUserIsLogged())) {
    Notification.showError(i18n.instance().t('need-login-notification'), 6000)
    next(`${i18n.getLocalizedUrl(Consts.URL_LOGIN)}?returnPath=${to.fullPath}`)
    return false
  }
  return true
}

async function validateUserType(to, from, next) {
  if (to.meta.userTypeRequired.length > 0) {
    const userType = await Authentification.getUserType()

    if (!to.meta.userTypeRequired.includes(userType)) {
      Notification.showWarning('restricted-access-notification', 6000)
      if (from.meta.key === Consts.URL_LOGIN) {
        next(i18n.getLocalizedUrl(Consts.URL_HOME))
      } else {
        next(false)
      }
      return false
    }
  }
  return true
}

async function validateCandidateProfil(to, from, next) {
  const user = await Authentification.getMe()
  if (to.meta.key !== Consts.URL_CREATE_CANDIDATE_PROFIL && user.type === Consts.USER_TYPE_CANDIDATE && user.profile === null) {
    next(`${i18n.getLocalizedUrl(Consts.URL_CREATE_CANDIDATE_PROFIL)}?returnPath=${to.fullPath}`)
    return false
  }
  return true
}

function generateRouteMultilangue(routesDef) {
  i18n.setLocalizedUrlByKey(generateKeyToUrl(routesDef))
  return routesDef
    .map((x) => generateRoute(x, Consts.LANG_FR))
    .concat(routesDef.map((x) => generateRoute(x, Consts.LANG_EN)))
    .flattenDeep()
}

function generateRoute(route, locale, basePath = '', breadcrumb = []) {
  const meta = { ...defaultMeta, ...route.meta, key: route.key }
  const path = (basePath !== '' ? basePath + '/' : '') + (route.path ? route.path : Url.getUrl(route.key, locale))
  const routeBreadcrumb = breadcrumb.concat([{ get: meta.breadcrumb, url: '/' + locale + '/' + path }])
  meta.generatedBreadcrumb = routeBreadcrumb

  let routeItem = null
  if (route.redirect) {
    routeItem = {
      path,
      meta,
      redirect: route.redirect
    }
  } else {
    routeItem = {
      path,
      component: route.component,
      meta
    }
  }

  let children = []
  if (route.children) {
    children = route.children.map((x) => generateRoute(x, locale, path, routeBreadcrumb)).flattenDeep()
  }

  return [...[routeItem], ...children]
}

function generateKeyToUrl(routes) {
  const dict = {}
  for (const route of routes) {
    dict.en = { ...dict.en, ...generateRouteByKey(`/${Consts.LANG_EN}/`, route, Consts.LANG_EN) }
    dict.fr = { ...dict.fr, ...generateRouteByKey(`/${Consts.LANG_FR}/`, route, Consts.LANG_FR) }
  }
  return dict
}

function generateRouteByKey(basePath, route, locale) {
  let dict = {}
  const path = route.path ? route.path : Url.getUrl(route.key, locale)
  dict[route.key] = basePath + path
  if (route.children) {
    for (const child of route.children) {
      dict = { ...dict, ...generateRouteByKey(basePath + path + '/', child, locale) }
    }
  }
  return dict
}

Vue.use(Router)
