<template>
  <nav-app-mission :missions="missions" :mini="mini" @open="open()" />
</template>

<script>
import NavAppMission from '@/components/nav/nav-app-mission'

import i18n from '@/helpers/i18n'
import { Consts } from '@/helpers/consts'
import gql from 'graphql-tag'

const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      selectedCompany
    }
  }
`
const GET_ASSOCIATED_MISSIONS = gql`
  query AssociatedMissions($companyId: ID!) {
    company(companyId: $companyId) {
      id
      participations {
        id
        mission {
          id
          endDate
          name
          status
          startDate
        }
      }
    }
  }
`

export default {
  components: {
    NavAppMission
  },
  data() {
    return {
      missions: []
    }
  },
  props: {
    mini: {
      type: Boolean,
      required: true
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER
    },
    company: {
      query: GET_ASSOCIATED_MISSIONS,
      variables() {
        return { companyId: this.user.selectedCompany }
      },
      result({ data, loading }) {
        if (data === undefined) {
          if (!loading) {
            // throw error
          }
          return
        }

        this.missions = data.company.participations.map((x) => {
          const label =
            x.mission.status === Consts.MISSION_STATUS_ARCHIVED ? i18n.instance().t('mission-mention-archived') + ' ' : ''
          return {
            id: x.mission.id,
            label: label + x.mission.name,
            status: x.mission.status,
            date: {
              startDate: x.mission.startDate,
              endDate: x.mission.endDate
            }
          }
        })

        this.missions.sort((mission) => {
          return mission.status === Consts.MISSION_STATUS_ACTIVE ? -1 : 1
        })
      },
      skip() {
        return this.user === undefined
      }
    }
  },
  methods: {
    open: function () {
      this.$emit('open')
    }
  }
}
</script>
