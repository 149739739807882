import { Consts } from '@/helpers/consts'
import i18n from '@/helpers/i18n'
import Authentification from '@/services/authentification'
import Logger from '@/services/logger'
import Router from '@/helpers/router'

async function getStatus() {
  const response = await fetch(`${Consts.ROOT_API}/status`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const jsonResponse = await response.json()
  isInMaintenance = jsonResponse.isInMaintenance
  version = jsonResponse.version
  await handleIsInMaintenance()
}

async function handleIsInMaintenance(currentRoute = Router.instance.currentRoute.meta.key) {
  try {
    if (isInMaintenance) {
      if (await Authentification.getUserIsLogged()) {
        Authentification.logout()
      }
      if (currentRoute !== Consts.URL_MAINTENANCE) {
        Router.instance.replace(i18n.getLocalizedUrl(Consts.URL_MAINTENANCE))
      }
    } else {
      if (currentRoute === Consts.URL_MAINTENANCE) {
        Router.instance.replace(i18n.getLocalizedUrl(Consts.URL_HOME))
      }
    }
  } catch (error) {
    // The app will rarely be in maintenance so if an unknown error happens here we will let the user use the app.
    // The backend has its own check on IsInMaintenance anyway.
    Logger.logError('Error while checking if app is in maintenance.')
  }
}

let isInMaintenance = false
let version = ''
getStatus()
setInterval(async function () {
  await getStatus()
}, 60000)

export default {
  getVersion: () => {
    return version
  },
  handleIsInMaintenance: async function (currentRoute) {
    await handleIsInMaintenance(currentRoute)
  }
}
