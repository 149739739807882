const overrides = window.qi_env || {}

export default {
  VUE_APP_ENVIRONMENT: overrides.VUE_APP_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT,
  VUE_APP_I18N_LOCALE: overrides.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_LOCALE,
  VUE_APP_I18N_FALLBACK_LOCALE: overrides.VUE_APP_I18N_FALLBACK_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  VUE_APP_ROOT_API: overrides.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API,
  VUE_APP_GRAPHQL_HTTP: overrides.VUE_APP_GRAPHQL_HTTP || process.env.VUE_APP_GRAPHQL_HTTP,
  VUE_APP_AZURE_INSIGHT_API: overrides.VUE_APP_AZURE_INSIGHT_API || process.env.VUE_APP_AZURE_INSIGHT_API,
  VUE_APP_AZURE_INSIGHT_APIKEY: overrides.VUE_APP_AZURE_INSIGHT_APIKEY || process.env.VUE_APP_AZURE_INSIGHT_APIKEY,
  VUE_APP_AZURE_LOAD_THRESHOLD_MILLISECONDS:
    overrides.VUE_APP_AZURE_LOAD_THRESHOLD_MILLISECONDS || process.env.VUE_APP_AZURE_LOAD_THRESHOLD_MILLISECONDS,
  VUE_APP_THEME: overrides.VUE_APP_THEME || process.env.VUE_APP_THEME,
  VUE_APP_APPLICATION_NAME: overrides.VUE_APP_APPLICATION_NAME || process.env.VUE_APP_APPLICATION_NAME,
  VUE_APP_APPLICATION_FULL_NAME: overrides.VUE_APP_APPLICATION_FULL_NAME || process.env.VUE_APP_APPLICATION_FULL_NAME,
  VUE_APP_PLATFORM_NAME: overrides.VUE_APP_PLATFORM_NAME || process.env.VUE_APP_PLATFORM_NAME,
  VUE_APP_TRADE_MARK: overrides.VUE_APP_TRADE_MARK || process.env.VUE_APP_TRADE_MARK,
  VUE_APP_ORGANISM: overrides.VUE_APP_ORGANISM || process.env.VUE_APP_ORGANISM,
  VUE_APP_ORGANISM_SHORT_NAME: overrides.VUE_APP_ORGANISM_SHORT_NAME || process.env.VUE_APP_ORGANISM_SHORT_NAME,
  VUE_APP_REGION_FR: overrides.VUE_APP_REGION_FR || process.env.VUE_APP_REGION_FR,
  VUE_APP_REGION_EN: overrides.VUE_APP_REGION_EN || process.env.VUE_APP_REGION_EN,
  VUE_APP_HELP_EMAIL: overrides.VUE_APP_HELP_EMAIL || process.env.VUE_APP_HELP_EMAIL,
  VUE_APP_MISSION_HELP_EMAIL: overrides.VUE_APP_MISSION_HELP_EMAIL || process.env.VUE_APP_MISSION_HELP_EMAIL,
  VUE_APP_WEBSITE_SHORT_URL: overrides.VUE_APP_WEBSITE_SHORT_URL || process.env.VUE_APP_WEBSITE_SHORT_URL,
  VUE_APP_WEBSITE_FR_URL: overrides.VUE_APP_WEBSITE_FR_URL || process.env.VUE_APP_WEBSITE_FR_URL,
  VUE_APP_WEBSITE_EN_URL: overrides.VUE_APP_WEBSITE_EN_URL || process.env.VUE_APP_WEBSITE_EN_URL,
  VUE_APP_WEBSITE_CONTACT_FR_URL: overrides.VUE_APP_WEBSITE_CONTACT_FR_URL || process.env.VUE_APP_WEBSITE_CONTACT_FR_URL,
  VUE_APP_WEBSITE_CONTACT_EN_URL: overrides.VUE_APP_WEBSITE_CONTACT_EN_URL || process.env.VUE_APP_WEBSITE_CONTACT_EN_URL,
  VUE_APP_WEBSITE_CORECRUITMENT_FR_URL:
    overrides.VUE_APP_WEBSITE_CORECRUITMENT_FR_URL || process.env.VUE_APP_WEBSITE_CORECRUITMENT_FR_URL,
  VUE_APP_WEBSITE_CORECRUITMENT_EN_URL:
    overrides.VUE_APP_WEBSITE_CORECRUITMENT_EN_URL || process.env.VUE_APP_WEBSITE_CORECRUITMENT_EN_URL,
  VUE_APP_CREATE_ACCOUNT_TUTORIAL_FR_URL:
    overrides.VUE_APP_CREATE_ACCOUNT_TUTORIAL_FR_URL || process.env.VUE_APP_CREATE_ACCOUNT_TUTORIAL_FR_URL,
  VUE_APP_CREATE_ACCOUNT_TUTORIAL_EN_URL:
    overrides.VUE_APP_CREATE_ACCOUNT_TUTORIAL_EN_URL || process.env.VUE_APP_CREATE_ACCOUNT_TUTORIAL_EN_URL,
  VUE_APP_INTERVIEW_FAQ_FR_URL: overrides.VUE_APP_INTERVIEW_FAQ_FR_URL || process.env.VUE_APP_INTERVIEW_FAQ_FR_URL,
  VUE_APP_INTERVIEW_FAQ_EN_URL: overrides.VUE_APP_INTERVIEW_FAQ_EN_URL || process.env.VUE_APP_INTERVIEW_FAQ_EN_URL,
  VUE_APP_GTAG_ID: overrides.VUE_APP_GTAG_ID || process.env.VUE_APP_GTAG_ID
}
