<template>
  <v-list class="py-1">
    <div v-for="(list, listIndex) in lists" :key="`nav-app-list-${listIndex}`">
      <v-divider v-if="listIndex > 0" class="my-1" />
      <div class="py-1">
        <v-list-tile
          v-for="(item, itemIndex) in items.filter((i) => i.list === list)"
          :key="`nav-app-list-${listIndex}-${itemIndex}`"
          :to="item.to"
          ripple
          exact
        >
          <v-list-tile-action>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title :class="item.className">{{ item.label }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action v-if="showNotificationIcon(item)">
            <v-icon color="primary">error</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </div>
    </div>
  </v-list>
</template>

<script>
import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const GET_ME = gql`
  query Me {
    me {
      id
      hasUpcomingOrOngoingInterview
    }
  }
`
const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      selectedCompany
      selectedInstitution
    }
  }
`

export default {
  data() {
    return {
      selectedCompany: null
    }
  },
  props: {
    userRole: {
      type: String,
      required: false,
      default: Consts.USER_TYPE_ANONYME
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER,
      result({ data, loading }) {
        this.selectedCompany = data.user.selectedCompany
      }
    },
    me: {
      query: GET_ME,
      skip() {
        return this.userRole === Consts.USER_TYPE_ANONYME
      }
    }
  },
  methods: {
    showNotificationIcon: function (item) {
      return this.me !== undefined && item.id === 'APPLICATIONS_LIST' && this.me.hasUpcomingOrOngoingInterview
    }
  },
  computed: {
    lists() {
      const lists = []
      this.items.forEach((item) => {
        const list = item.list
        if (!lists.includes(list)) {
          lists.push(list)
        }
      })
      return lists
    },
    items() {
      if (!this.user) return []

      const items = []

      const add = (icon, label, to, list = 'default', className = '', id = '') => {
        items.push({
          icon,
          label: this.$t(label),
          to,
          list,
          className,
          id
        })
      }

      switch (this.userRole) {
        case Consts.USER_TYPE_ADVISOR:
          add('business', 'nav-business-btn', this.$getLocalizedUrl(this.$consts('URL_BUSINESS_LIST')))
          add('school', 'nav-institution-btn', this.$getLocalizedUrl(this.$consts('URL_INSTITUTION_LIST')))
          add('event', 'nav-agendas-btn', this.$getLocalizedUrl(this.$consts('URL_AGENDA_LIST_BY_COMPANY_INSTITUTION')))
          add('person_outline', 'nav-candidate-btn', this.$getLocalizedUrl(this.$consts('URL_CANDIDATE_LIST')))
          add('flight_takeoff', 'nav-mission-btn', this.$getLocalizedUrl(this.$consts('URL_MISSION_LIST')))
          add('supervisor_account', 'nav-advisor-list', this.$getLocalizedUrl(this.$consts('URL_EDIT_ADVISORS')))
          break
        case Consts.USER_TYPE_CANDIDATE:
          add('card_travel', 'nav-jobs-btn', this.$getLocalizedUrl(this.$consts('URL_JOB_OFFER_LIST_CANDIDATE')))
          add('school', 'nav-educational-btn', this.$getLocalizedUrl(this.$consts('URL_STUDY_PROGRAM_LIST_CANDIDATE')))
          add(
            'near_me',
            'nav-applications-btn',
            this.$getLocalizedUrl(this.$consts('URL_APPLICATIONS_LIST')),
            'default',
            'menu-item__title menu-item__title--two-lines',
            'APPLICATIONS_LIST'
          )
          add('favorite_outline', 'nav-favorites-btn', this.$getLocalizedUrl(this.$consts('URL_FAVORITE_LIST')))
          break
        case Consts.USER_TYPE_RECRUITER: {
          const currentCompanyId = this.user !== undefined ? this.user.selectedCompany : ''
          add('work_outline', 'nav-job-offer-btn', this.$getLocalizedUrl(this.$consts('URL_JOB_OFFER_LIST')))
          add('event', 'nav-agendas-btn', this.$getLocalizedUrl(this.$consts('URL_AGENDA_LIST_BY_COMPANY_INSTITUTION')))
          add(
            'business',
            'nav-business-profil-btn',
            this.$getLocalizedUrl(this.$consts('URL_BUSINESS_PROFIL')).replace(Consts.URL_PARAMS_BUSINESS_ID, currentCompanyId),
            'profile'
          )
          add(
            'supervisor_account',
            'nav-recruiters-list',
            this.$getLocalizedUrl(this.$consts('URL_BUSINESS_EDIT_RECRUITERS')).replace(
              Consts.URL_PARAMS_BUSINESS_ID,
              currentCompanyId
            ),
            'profile'
          )
          break
        }
        case Consts.USER_TYPE_RECRUITER_EDUCATIONAL: {
          const currentInstitutionId = this.user !== undefined ? this.user.selectedInstitution : ''
          add('school', 'nav-educational-programs-btn', this.$getLocalizedUrl(this.$consts('URL_STUDY_PROGRAM_LIST')))
          add('event', 'nav-agendas-btn', this.$getLocalizedUrl(this.$consts('URL_AGENDA_LIST_BY_COMPANY_INSTITUTION')))
          add(
            'business',
            'nav-institution-profil-btn',
            this.$getLocalizedUrl(this.$consts('URL_INSTITUTION_PROFILE')).replace(
              Consts.URL_PARAMS_INSTITUTION_ID,
              currentInstitutionId
            ),
            'profile'
          )
          add(
            'supervisor_account',
            'nav-recruiters-list',
            this.$getLocalizedUrl(this.$consts('URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL')).replace(
              Consts.URL_PARAMS_INSTITUTION_ID,
              currentInstitutionId
            ),
            'profile'
          )
          break
        }
        case Consts.USER_TYPE_ANONYME:
      }
      return items
    }
  }
}
</script>

<style lang="stylus">
.menu-item {
  &__title {
    &--two-lines {
      white-space: normal;
      height: 38px;
      line-height: 1.2;
    }
  }
}
</style>
