import i18n from '@/helpers/i18n'
import { Consts } from '@/helpers/consts'

export default {
  install(Vue) {
    // eslint-disable-next-line no-unused-expressions
    ;(Vue.prototype.$currentContentLanguage = () => {
      return i18n.locale() === Consts.LANG_FR ? Consts.CONTENT_LANGUAGE_FR : Consts.CONTENT_LANGUAGE_EN
      // eslint-disable-next-line no-sequences
    }),
      (Vue.prototype.$currentCountryLanguage = () => {
        return i18n.locale() === Consts.LANG_FR ? Consts.CONTENT_LANGUAGE_FR : Consts.CONTENT_LANGUAGE_EN
      })
  }
}
