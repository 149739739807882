<template>
  <v-list dense class="nav-app-user">
    <!-- Mini -->
    <v-list-tile v-show="mini" @click="triggerOpen">
      <v-list-tile-action>
        <v-avatar size="24">
          <v-img
            :aspect-ratio="1"
            :src="
              hasPicture
                ? `${picture}?rmode=crop&format=jpg&width=64&height=64&bgcolor=fafafa`
                : require(`@/assets/avatar-secondary-${ThemeSlug}.png`)
            "
          ></v-img>
        </v-avatar>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>{{ userName }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <!-- NOT Mini -->
    <v-list-group v-show="!mini" v-model="open">
      <v-list-tile slot="activator">
        <v-list-tile-action>
          <v-avatar size="24">
            <img :aspect-ratio="1" :src="hasPicture ? picture : require(`@/assets/avatar-secondary-${ThemeSlug}.png`)" />
          </v-avatar>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ userName }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- Mon curriculum -->
      <v-list-tile v-if="isCandidate" @click="goToUserProfile">
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ $t('my-curriculum-btn') }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- Réglage -->
      <v-list-tile @click="goToUserSettings">
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ $t('account-setting-btn') }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- Cookies -->
      <v-list-tile @click="openTermsfeed">
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ $t('nav-app-open-termsfeed') }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- Déconnexion -->
      <v-list-tile v-if="!anonymous" @click="logout">
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ $t('logout-label') }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list-group>
  </v-list>
</template>

<script>
import i18n from '@/helpers/i18n'
import { Consts } from '@/helpers/consts'
import { ThemeSlug } from '@/helpers/theme'
import Authentification from '@/services/authentification'

export default {
  data: function () {
    return {
      isOpen: false,
      ThemeSlug
    }
  },
  props: {
    userType: {
      type: String,
      required: true
    },
    mini: {
      type: Boolean,
      required: true
    },
    me: {
      type: Object,
      required: false
    }
  },
  computed: {
    hasPicture: function () {
      return this.me && this.me.profile && this.me.profile.picture !== null
    },
    picture: function () {
      return this.me.profile.picture
    },
    anonymous: function () {
      return this.userType === Consts.USER_TYPE_ANONYME
    },
    isCandidate: function () {
      return this.userType === Consts.USER_TYPE_CANDIDATE
    },
    userName() {
      if (!this.anonymous && this.me) {
        if (this.me.profile) {
          return `${this.me.profile.firstName} ${this.me.profile.lastName}`
        } else {
          return this.me.email
        }
      } else {
        return Consts.USER_TYPE_ANONYME
      }
    },
    open: {
      get() {
        return this.mini ? false : this.isOpen
      },
      set(bool) {
        this.isOpen = this.mini ? false : bool
      }
    }
  },
  methods: {
    logout: function () {
      Authentification.logout()
    },
    triggerOpen: function () {
      this.isOpen = true
      this.$emit('open')
    },
    goToUserProfile: function () {
      this.$router.push(i18n.getLocalizedUrl(Consts.URL_CANDIDATE_CV))
    },
    goToUserSettings: function () {
      this.$router.push(i18n.getLocalizedUrl(Consts.URL_USER_SETTINGS))
    },
    openTermsfeed: function () {
      const termsfeed = document.getElementById('open_preferences_center')
      if (termsfeed !== undefined && termsfeed !== null) {
        termsfeed.click()
      }
    }
  }
}
</script>

<style lang="stylus">
.nav-app-user { // Scope
  .v-list__group {
    &:before, &:after {
      display: none;
    }
  }

  .v-list__group__header {
    .v-list__group__header__append-icon {
      padding-left: 0;
    }
  }
}
</style>
