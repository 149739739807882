<template>
  <v-alert class="notification-alert" :type="item.type" v-model="visibility" :dismissible="item.dismissible">
    {{ !item.contentIsHtml ? item.text : '' }}
    <span v-html="item.contentIsHtml ? item.text : ''" />
  </v-alert>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visibility: true
    }
  },
  watch: {
    visibility(val) {
      if (!val) {
        this.$emit('dismiss', this.item.id)
      }
    }
  }
}
</script>

<style lang="stylus">
.v-alert.notification-alert
  margin: 0
  a
    color: #fff
  &:first-child
    border-radius: $sheet-border-radius $sheet-border-radius 0 0
  &:last-child
    border-radius: 0 0 $sheet-border-radius $sheet-border-radius
  &:only-child
    border-radius: $sheet-border-radius
</style>
