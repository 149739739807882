import Apollo from '@/graphql/vue-apollo'
import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const UPDATE_LOCAL_USER = gql`
  mutation UpdateLocalUser($user: User!) {
    updateLocalUser(user: $user) @client
  }
`
const DELETE_INSTITUTION_PROFILE_TRANSLATION = gql`
  mutation DeleteInstitutionProfileTranslation($input: DeleteInstitutionProfileTranslationInput!) {
    deleteInstitutionProfileTranslation(input: $input) {
      institution {
        id
        header
        logo
        institutionLevel
        translations {
          id
          advantages {
            id
            description
            title
          }
          language
          presentation
          presentationTitle
          webSiteUrl
          videoUrl
        }
      }
    }
  }
`
const UPDATE_INSTITUTION_PROFILE_TRANSLATION = gql`
  mutation UpdateInstitutionProfileTranslation($input: UpdateInstitutionProfileTranslationInput!) {
    updateInstitutionProfileTranslation(input: $input) {
      institution {
        id
        translations {
          id
          advantages {
            id
            description
            title
          }
          language
          presentation
          presentationTitle
          webSiteUrl
          videoUrl
        }
      }
    }
  }
`
const DELETE_INSTITUTION = gql`
  mutation DeleteInstitution($input: DeleteInstitutionInput!) {
    deleteInstitution(input: $input)
  }
`
const UPDATE_INSTITUTION_PROFILE = gql`
  mutation UpdateInstitutionProfile($input: UpdateInstitutionProfileInput!) {
    updateInstitutionProfile(input: $input) {
      institution {
        id
        logo
      }
    }
  }
`
const REMOVE_RECRUITER_EDUCATIONAL_FROM_INSTITUTION = gql`
  mutation RemoveRecruiterEducationalFromInstitution($input: RemoveRecruiterEducationalFromInstitutionInput!) {
    removeRecruiterEducationalFromInstitution(input: $input) {
      institution {
        id
        recruitersEducational {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const ADD_RECRUITER_EDUCATIONAL_TO_INSTITUTION = gql`
  mutation AddRecruiterEducationalToInstitution($input: AddRecruiterEducationalToInstitutionInput!) {
    addRecruiterEducationalToInstitution(input: $input) {
      institution {
        id
        recruitersEducational {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const UPDATE_INSTITUTION = gql`
  mutation UpdateInstitution($input: UpdateInstitutionInput!) {
    updateInstitution(input: $input) {
      institution {
        id
        name
        city
        institutionLevel
        recruitersEducational {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const CREATE_INSTITUTION = gql`
  mutation CreateInstitution($input: CreateInstitutionInput!) {
    createInstitution(input: $input) {
      institution {
        id
        name
        city
        institutionLevel
        recruitersEducational {
          id
          email
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const GET_LOCAL_USER_CURRENT_INSTITUTION = gql`
  query LocalUserCurrentInstitution {
    user @client {
      selectedInstitution
    }
  }
`
const GET_ASSOCIATED_MISSIONS_BY_INSTITUTION_ID = gql`
  query AssociatedMissionsByInstitutionId($institutionId: ID!) {
    institution(institutionId: $institutionId) {
      id
      participations {
        id
        mission {
          id
        }
      }
    }
  }
`

export default {
  selectedInstitution: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_CURRENT_INSTITUTION
    })
    return result.data.user.selectedInstitution
  },
  createInstitution: async function (input) {
    const result = await Apollo.instance.defaultClient.mutate({
      mutation: CREATE_INSTITUTION,
      variables: {
        input
      }
    })

    return result.data.createInstitution.institution.id
  },
  updateInstitution: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_INSTITUTION,
      variables: {
        input
      }
    })
  },
  updateInstitutionProfile: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_INSTITUTION_PROFILE,
      variables: {
        input
      }
    })
  },
  updateInstitutionProfileTranslation: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_INSTITUTION_PROFILE_TRANSLATION,
      variables: {
        input
      }
    })
  },
  deleteInstitutionProfileTranslation: async function (input) {
    await Apollo.instance.defaultClient.mutate({
      mutation: DELETE_INSTITUTION_PROFILE_TRANSLATION,
      variables: {
        input
      }
    })
  },
  updateInstitutionSelected: async function (selectedInstitution) {
    const user = { selectedInstitution }

    const result = await Apollo.instance.defaultClient.query({
      query: GET_ASSOCIATED_MISSIONS_BY_INSTITUTION_ID,
      variables: {
        institutionId: selectedInstitution
      }
    })

    const currentMissionId = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
    if (!result.data.institution.participations.find((p) => p.mission.id === currentMissionId)) {
      let selectedMission = ''
      if (result.data.institution.participations.length > 0) {
        selectedMission = result.data.institution.participations.first().mission.id
        localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
      } else {
        localStorage.removeItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
      }
      user.selectedMission = selectedMission
    }

    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_LOCAL_USER,
      variables: {
        user
      }
    })

    localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_INSTITUTION, selectedInstitution)
  },
  addRecruitersEducational: async function (institutionId, recruitersEducational) {
    for (let i = 0; i < recruitersEducational.length; i++) {
      const recruiterEducational = recruitersEducational[i]
      await Apollo.instance.defaultClient.mutate({
        mutation: ADD_RECRUITER_EDUCATIONAL_TO_INSTITUTION,
        variables: {
          input: {
            institutionId,
            email: recruiterEducational.email,
            firstName: recruiterEducational.firstName,
            lastName: recruiterEducational.lastName
          }
        }
      })
    }
  },
  removeRecruiterEducationalFromInstitution: async function (institutionId, userId) {
    const input = { institutionId, userId }

    await Apollo.instance.defaultClient.mutate({
      mutation: REMOVE_RECRUITER_EDUCATIONAL_FROM_INSTITUTION,
      variables: {
        input
      }
    })
  },
  deleteInstitution: async function (institutionId) {
    const input = { institutionId }

    await Apollo.instance.defaultClient.mutate({
      mutation: DELETE_INSTITUTION,
      variables: {
        input
      }
    })
  }
}
