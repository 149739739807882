import Apollo from '@/graphql/vue-apollo'
import router from '@/helpers/router'
import i18n from '@/helpers/i18n'
import Notification from '@/services/notification'
import User from '@/services/user'
import VueJwtDecode from 'vue-jwt-decode'

import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const GET_LOCAL_USER_IS_CONNECTED = gql`
  query LocalUserIsConnected {
    user @client {
      isConnected
    }
  }
`
const GET_ME = gql`
  query Me {
    me {
      id
      type
      profile {
        id
      }
    }
  }
`
const GET_USER_RECRUITEREDUCATIONALOF = gql`
  query UserRecruiterEducationalOf {
    me {
      id
      recruiterEducationalOf {
        id
      }
    }
  }
`
const GET_USER_RECRUITEROF = gql`
  query UserRecruiterOf {
    me {
      id
      recruiterOf {
        id
      }
    }
  }
`
const GET_USER_TYPE = gql`
  query UserType {
    me {
      id
      type
    }
  }
`
const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      selectedCompany
      selectedMission
      selectedInstitution
    }
  }
`
const GET_LOCAL_USER_TOKEN = gql`
  query LocalUserToken {
    user @client {
      accessToken
      refreshToken
    }
  }
`
const GET_USER_TOS = gql`
  query GetUserTos {
    me {
      id
      qiCanContactMe {
        id
        hasAcceptedLatest
      }
      privacyPolicy {
        id
        hasAcceptedLatest
      }
      tos {
        id
        hasAcceptedLatest
      }
    }
  }
`
const GET_RECRUITER_TERMS = gql`
  query GetUserTos {
    me {
      id
      recruiterTerms {
        id
        hasAcceptedLatest
      }
    }
  }
`
const GET_ASSOCIATED_MISSIONS_BY_INSTITUTION_ID = gql`
  query AssociatedMissionsByInstitutionId($institutionId: ID!) {
    institution(institutionId: $institutionId) {
      id
      participations {
        id
        locations {
          id
          location
        }
        mission {
          id
        }
      }
    }
  }
`
const GET_ASSOCIATED_MISSIONS = gql`
  query AssociatedMissions($companyId: ID!) {
    company(companyId: $companyId) {
      id
      participations {
        id
        mission {
          id
        }
      }
    }
  }
`
const GET_MY_CANDIDATE_PROFILE = gql`
  query MyProfile {
    me {
      id
      profile {
        id
        ... on CandidateProfileGraphType {
          city
        }
      }
    }
  }
`
const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input)
  }
`
const RESEND_CONFIRMATION_EMAIL = gql`
  mutation ResendConfirmationEmail($input: ResendConfirmationEmailInput!) {
    resendConfirmationEmail(input: $input)
  }
`
const UPDATE_LOCAL_USER = gql`
  mutation UpdateLocalUser($user: User!) {
    updateLocalUser(user: $user) @client
  }
`
const SHOW_TERMS = gql`
  mutation ShowTerms($isVisible: Boolean!) {
    showTerms(isVisible: $isVisible) @client
  }
`
const SHOW_RECRUITER_TERMS = gql`
  mutation ShowRecruiterTerms($isVisible: Boolean!) {
    showRecruiterTerms(isVisible: $isVisible) @client
  }
`

export default {
  login: async function (username, password) {
    let deviceId = localStorage.getItem(Consts.LOCAL_STORAGE_DEVICEID)

    if (deviceId === null) {
      deviceId = 'quebecrecruteVue-' + new Date().getTime()
      localStorage.setItem(Consts.LOCAL_STORAGE_DEVICEID, deviceId)
    }

    const requestData = {
      username,
      password,
      deviceId
    }
    let response = null
    try {
      response = await fetch(`${Consts.ROOT_API}/token/login`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
    } catch (error) {
      Notification.showError(i18n.instance().t('error-unexpected'), 6000)
      return
    }

    const result = await response.json()
    if (response.status === 200) {
      await setUser(result.token, result.refreshToken)
      const user = await getMe()

      if (user.type === Consts.USER_TYPE_RECRUITER) {
        const result = await loadRecruiterLocalState()
        if (!result) return
      } else if (user.type === Consts.USER_TYPE_RECRUITER_EDUCATIONAL) {
        const result = await loadRecruiterEducationalLocalState()
        if (!result) return
      } else if (user.type === Consts.USER_TYPE_ADVISOR) {
        await loadAdvisorLocalState()
      } else {
        await loadCandidateLocalState()
      }

      const path = router.instance.currentRoute.query.returnPath || getHomeUrlByUserType(user.type)
      router.instance.push(path)
    } else if (response.status === 400 && result === 'Email not confirmed') {
      router.instance.push(
        i18n.getLocalizedUrl(Consts.URL_CREATE_CANDIDATE_USER_CONFIRMATION) + `?email=${encodeURIComponent(username)}`
      )
    } else {
      Notification.showError(i18n.instance().t('login-error-notification'), 6000)
    }
  },
  tokenIsAboutToExpire: function () {
    const token = localStorage.getItem(Consts.LOCAL_STORAGE_AUTHTOKEN)
    const decodedToken = VueJwtDecode.decode(token)
    return Date.now() + 120000 >= decodedToken.exp * 1000 // Vérifie si le jeton expire dans les 2 prochaines minutes
  },
  renewToken: async function () {
    if (localStorage.getItem(Consts.LOCAL_STORAGE_RENEWTOKEN_STATUS)) {
      await waitRefreshToken()
      return
    }

    localStorage.setItem(Consts.LOCAL_STORAGE_RENEWTOKEN_STATUS, 'active')

    const token = localStorage.getItem(Consts.LOCAL_STORAGE_AUTHTOKEN)
    const refreshToken = localStorage.getItem(Consts.LOCAL_STORAGE_REFRESHTOKEN)
    const deviceId = localStorage.getItem(Consts.LOCAL_STORAGE_DEVICEID)

    const requestData = {
      token,
      refreshToken,
      deviceId
    }

    const response = await fetch(`${Consts.ROOT_API}/token/refresh`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })

    if (response.status === 200) {
      const result = await response.json()
      await setUser(result.token, result.refreshToken)
    } else {
      await logout(null, window.location.pathname)
    }

    localStorage.removeItem(Consts.LOCAL_STORAGE_RENEWTOKEN_STATUS)
  },
  logout,
  loadLocalState: async function () {
    const token = localStorage.getItem(Consts.LOCAL_STORAGE_AUTHTOKEN)
    const refreshToken = localStorage.getItem(Consts.LOCAL_STORAGE_REFRESHTOKEN)
    localStorage.removeItem(Consts.LOCAL_STORAGE_RENEWTOKEN_STATUS)

    if (token !== null && refreshToken !== null) {
      await setUser(token, refreshToken)
      const user = await getMe()
      if (user.type === Consts.USER_TYPE_RECRUITER) {
        await loadRecruiterLocalState()
      } else if (user.type === Consts.USER_TYPE_RECRUITER_EDUCATIONAL) {
        await loadRecruiterEducationalLocalState()
      } else if (user.type === Consts.USER_TYPE_ADVISOR) {
        await loadAdvisorLocalState()
      } else {
        await loadCandidateLocalState()
      }
    }
  },
  getUserIsLogged: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_IS_CONNECTED
    })

    return result.data.user.isConnected
  },
  getUserType: async function () {
    const localResult = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_IS_CONNECTED
    })

    if (localResult.data.user.isConnected) {
      const result = await Apollo.instance.defaultClient.query({
        query: GET_USER_TYPE
      })
      return result.data.me.type
    }

    return Consts.USER_TYPE_ANONYME
  },
  getUserToken: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_TOKEN
    })

    return result.data.user.accessToken
  },
  getUserRefreshToken: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_LOCAL_USER_TOKEN
    })

    return result.data.user.refreshToken
  },
  getMe,
  uTypeToGraphqlType,
  resendConfirmationEmail: async function (email) {
    await Apollo.instance.defaultClient.mutate({
      mutation: RESEND_CONFIRMATION_EMAIL,
      variables: {
        input: {
          email
        }
      }
    })
  },
  confirmEmail: async function (token, email) {
    await Apollo.instance.defaultClient.mutate({
      mutation: CONFIRM_EMAIL,
      variables: {
        input: {
          email,
          token
        }
      }
    })
  }
}

async function logout(error, returnPath) {
  let path = ''

  if (error || returnPath) {
    const params = []

    if (error) {
      params.push('error=' + error)
    }

    if (returnPath) {
      params.push('returnPath=' + returnPath)
    }

    path = '?' + params.join('&')
  }

  localStorage.removeItem(Consts.LOCAL_STORAGE_AUTHTOKEN)
  localStorage.removeItem(Consts.LOCAL_STORAGE_REFRESHTOKEN)
  localStorage.removeItem(Consts.LOCAL_STORAGE_CURRENT_COMPANY)
  localStorage.removeItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
  localStorage.removeItem(Consts.LOCAL_STORAGE_CURRENT_INSTITUTION)
  localStorage.removeItem(Consts.LOCAL_STORAGE_GEOLOC)
  localStorage.removeItem(Consts.LOCAL_STORAGE_GEOLOC_DATE)

  router.setUrlByUserType(Consts.USER_TYPE_ANONYME)

  window.location.href = i18n.getLocalizedUrl(Consts.URL_LOGIN) + path
}

async function getMe() {
  const result = await Apollo.instance.defaultClient.query({
    query: GET_ME
  })

  return result.data.me
}

function getHomeUrlByUserType(type) {
  let homeUrl = i18n.getLocalizedUrl(Consts.URL_HOME)
  switch (type) {
    case Consts.USER_TYPE_RECRUITER: {
      homeUrl = i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST)
      break
    }
    case Consts.USER_TYPE_ADVISOR: {
      homeUrl = i18n.getLocalizedUrl(Consts.URL_BUSINESS_LIST)
      break
    }
    case Consts.USER_TYPE_CANDIDATE: {
      homeUrl = i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST_CANDIDATE)
      break
    }
  }

  return homeUrl
}

async function setUser(token, refreshToken) {
  localStorage.setItem(Consts.LOCAL_STORAGE_AUTHTOKEN, token)
  localStorage.setItem(Consts.LOCAL_STORAGE_REFRESHTOKEN, refreshToken)

  const userToken = JSON.parse(atob(token.split('.')[1]))
  const user = {
    isConnected: true,
    type: uTypeToGraphqlType(userToken.utype),
    accessToken: token,
    refreshToken
  }

  router.setUrlByUserType(user.type)

  await Apollo.instance.defaultClient.mutate({
    mutation: UPDATE_LOCAL_USER,
    variables: {
      user
    }
  })
}

async function waitRefreshToken() {
  return new Promise((resolve, reject) => {
    const timerId = setInterval(function () {
      if (!localStorage.getItem(Consts.LOCAL_STORAGE_RENEWTOKEN_STATUS)) {
        clearInterval(timerId)
        resolve()
      }
    }, 50)
  })
}

function uTypeToGraphqlType(utype) {
  let type = Consts.USER_TYPE_ANONYME
  switch (utype) {
    case 'Candidate':
      type = Consts.USER_TYPE_CANDIDATE
      break
    case 'Recruiter':
      type = Consts.USER_TYPE_RECRUITER
      break
    case 'RecruiterEducational':
      type = Consts.USER_TYPE_RECRUITER_EDUCATIONAL
      break
    case 'Advisor':
      type = Consts.USER_TYPE_ADVISOR
      break
  }

  return type
}

async function loadRecruiterLocalState() {
  let selectedCompany = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_COMPANY)
  let selectedMission = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
  let result = null

  if (selectedCompany === null) {
    result = await Apollo.instance.defaultClient.query({
      query: GET_USER_RECRUITEROF
    })

    if (result.data.me.recruiterOf.length > 0) {
      selectedCompany = result.data.me.recruiterOf.first().id
      localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_COMPANY, selectedCompany)
    } else {
      await logout(Consts.URL_LOGIN_RECRUITER_NO_COMPANY_ERROR)
      return false
    }
  }

  if (selectedMission === null) {
    result = await Apollo.instance.defaultClient.query({
      query: GET_ASSOCIATED_MISSIONS,
      variables: {
        companyId: selectedCompany
      }
    })

    if (result.data.company.participations.length > 0) {
      selectedMission = result.data.company.participations.first().mission.id
      localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
    }
  }

  const userResult = await Apollo.instance.defaultClient.query({
    query: GET_LOCAL_USER
  })

  userResult.data.user.selectedCompany = selectedCompany
  userResult.data.user.selectedMission = selectedMission

  await Apollo.instance.defaultClient.mutate({
    mutation: UPDATE_LOCAL_USER,
    variables: {
      user: userResult.data.user
    }
  })

  const resultRecruiterTerms = await Apollo.instance.defaultClient.query({
    query: GET_RECRUITER_TERMS
  })

  if (!resultRecruiterTerms.data.me.recruiterTerms.hasAcceptedLatest) {
    await Apollo.instance.defaultClient.mutate({
      mutation: SHOW_RECRUITER_TERMS,
      variables: { isVisible: true }
    })
  }

  return true
}

async function loadRecruiterEducationalLocalState() {
  let selectedInstitution = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_INSTITUTION)
  let selectedMission = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)
  let result = null

  if (selectedInstitution === null) {
    result = await Apollo.instance.defaultClient.query({
      query: GET_USER_RECRUITEREDUCATIONALOF
    })

    if (result.data.me.recruiterEducationalOf.length > 0) {
      selectedInstitution = result.data.me.recruiterEducationalOf.first().id
      localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_INSTITUTION, selectedInstitution)
    } else {
      await logout(Consts.URL_LOGIN_RECRUITER_EDUCATIONAL_NO_INSTITUTION_ERROR)
      return false
    }
  }

  if (selectedMission === null) {
    result = await Apollo.instance.defaultClient.query({
      query: GET_ASSOCIATED_MISSIONS_BY_INSTITUTION_ID,
      variables: {
        institutionId: selectedInstitution
      }
    })

    if (result.data.institution.participations.length > 0) {
      selectedMission = result.data.institution.participations.first().mission.id
      localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
    }
  }

  const userResult = await Apollo.instance.defaultClient.query({
    query: GET_LOCAL_USER
  })

  userResult.data.user.selectedInstitution = selectedInstitution
  userResult.data.user.selectedMission = selectedMission

  await Apollo.instance.defaultClient.mutate({
    mutation: UPDATE_LOCAL_USER,
    variables: {
      user: userResult.data.user
    }
  })

  const resultRecruiterTerms = await Apollo.instance.defaultClient.query({
    query: GET_RECRUITER_TERMS
  })

  if (!resultRecruiterTerms.data.me.recruiterTerms.hasAcceptedLatest) {
    await Apollo.instance.defaultClient.mutate({
      mutation: SHOW_RECRUITER_TERMS,
      variables: { isVisible: true }
    })
  }

  return true
}

async function loadAdvisorLocalState() {
  let selectedMission = localStorage.getItem(Consts.LOCAL_STORAGE_CURRENT_MISSION)

  if (selectedMission === null) {
    selectedMission = Consts.ALL_MISSION_ID
    localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
  }

  const userResult = await Apollo.instance.defaultClient.query({
    query: GET_LOCAL_USER
  })

  userResult.data.user.selectedMission = selectedMission

  await Apollo.instance.defaultClient.mutate({
    mutation: UPDATE_LOCAL_USER,
    variables: {
      user: userResult.data.user
    }
  })

  return true
}

async function loadCandidateLocalState() {
  let result = await Apollo.instance.defaultClient.query({
    query: GET_MY_CANDIDATE_PROFILE
  })

  if (result.data === null) {
    await User.createCandidateProfile()
  }

  result = await Apollo.instance.defaultClient.query({
    query: GET_USER_TOS
  })

  if (
    !result.data.me.qiCanContactMe.hasAcceptedLatest ||
    !result.data.me.privacyPolicy.hasAcceptedLatest ||
    !result.data.me.tos.hasAcceptedLatest
  ) {
    await Apollo.instance.defaultClient.mutate({
      mutation: SHOW_TERMS,
      variables: { isVisible: true }
    })
  }

  return true
}
