import authentification from '@/services/authentification'

export default {
  graphQLFetcher
}

async function graphQLFetcher(uri, params) {
  const userIsLogged = await authentification.getUserIsLogged()
  let tokenWasRenewed = false
  if (userIsLogged && authentification.tokenIsAboutToExpire()) {
    await authentification.renewToken()
    tokenWasRenewed = true
  }

  const response = await doFetch(uri, params)

  if (response.status !== 200) {
    if (response.headers.has('Token-Expired') && !tokenWasRenewed) {
      await authentification.renewToken()
      const r2 = await doFetch(uri, params)

      if (r2?.status === 200) {
        return r2
      }
    }
    await authentification.logout()
    throw new Error('Unauthorized')
  }

  return response
}

async function doFetch(uri, params) {
  const token = await authentification.getUserToken()

  if (token !== '') {
    params.headers.authorization = `Bearer ${token}`
  }

  return await fetch(uri, params)
}
