export default {
  app: {
    __typename: 'LocalApp',
    showMainNav: false,
    showAnyNav: false,
    showBreadcrumb: false,
    displayUpcomingInterviewNotification: true,
    isAbsolutePos: false,
    loadingCount: 0,
    termsVisible: false,
    recruiterTermsVisible: false,
    wizardSelection: {
      __typename: 'WizardSelection',
      terms: [],
      education: [],
      diploma: [],
      frenchLevel: [],
      domains: [],
      interviewLocation: [],
      institutionLevel: []
    },
    jobOfferFilter: {
      __typename: 'JobOfferFilter',
      terms: [],
      domains: [],
      education: [],
      englishLevel: [],
      experience: [],
      frenchLevel: [],
      interviewLocation: []
    }
  },
  user: {
    __typename: 'LocalUser',
    isConnected: false,
    accessToken: '',
    refreshToken: '',
    selectedCompany: '',
    selectedMission: '',
    selectedInstitution: ''
  },
  notifications: []
}
