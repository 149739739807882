<template>
  <div></div>
</template>
<script>
import env from '@/helpers/env'

export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      applicationName: env.VUE_APP_APPLICATION_NAME,
      termsfeedReady: false,
      checkingInterval: null
    }
  },
  mounted() {
    const termsfeedScript = document.createElement('script')
    termsfeedScript.setAttribute('src', '//www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js')
    termsfeedScript.setAttribute('charset', 'UTF-8')
    termsfeedScript.setAttribute('type', 'text/javascript')
    document.head.appendChild(termsfeedScript)

    const localThis = this
    this.checkingInterval = setInterval(function () {
      // eslint-disable-next-line no-undef
      if (cookieconsent) {
        localThis.termsfeedReady = true
      }
    }, 500)
  },
  watch: {
    termsfeedReady: function () {
      clearInterval(this.checkingInterval)
      // eslint-disable-next-line no-undef
      cookieconsent.run({
        notice_banner_type: 'simple',
        consent_type: 'express',
        palette: 'light',
        language: this.locale,
        page_load_consent_levels: ['strictly-necessary', 'functionality'],
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: false,
        page_refresh_confirmation_buttons: false,
        website_name: this.applicationName
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/config.scss';
/* Style du plugin TermsFeed - Free Cookie Consent */
/* Bannière */
#termsfeed-com---nb {
  .cc-nb-main-container {
    @media screen {
      background-color: color('primary-b98');
      border-top: none;
      font-family: $font-family-ibm;
      padding: 16px 24px;
    }

    @media screen and (min-width: map-get($breakpoint, 'sm')) {
      padding: 24px 32px;
    }

    @media screen and (min-width: map-get($breakpoint, 'lg')) {
      padding: 32px 48px;
    }
  }

  .cc-nb-title {
    @media screen {
      font-size: $font-size-h5;
      margin-bottom: 8px;
    }
  }

  .cc-nb-text {
    @media screen {
      font-size: $font-size-p3;
    }
  }

  .cc-nb-buttons-container {
    @media screen {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 8px;
    }

    .cc-nb-changep,
    .cc-nb-okagree,
    .cc-nb-reject {
      @media screen {
        margin: 0 !important;
      }
    }
  }
}

/* Dialogue */
#termsfeed-com---preferences-center {
  & {
    @media screen {
      font-family: $font-family-ibm;
    }
  }

  .cc-pc-head,
  .cc-cp-foot {
    @media screen {
      background-color: color('primary-b98');
    }
  }

  .cc-pc-head-title-text {
    @media screen {
      font-size: $font-size-p4;
    }
  }

  .cc-pc-head-lang select {
    @media screen {
      &:focus {
        box-shadow: 0 0 0 3px color('primary');
      }
    }
  }

  .cc-custom-checkbox input.cc-custom-checkbox {
    @media screen {
      &:focus {
        box-shadow: none;
        outline: none;
        background-color: transparent;
      }

      &:active {
        background-color: transparent !important;
      }
      &:focus-visible {
        & ~ label:before {
          background-color: color('primary');
        }
      }
    }
  }

  .cc-pc-head-close {
    @media screen {
      &:focus {
        outline: none;
        border: none;
        box-shadow: 0 0 0 3px color('primary');
      }
    }
  }

  .cc-cp-body-tabs-item-link,
  a {
    @media screen {
      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 3px color('primary');
      }
    }
  }

  .cc-cp-foot-save {
    @media screen {
      opacity: 1;
    }
  }
}

/* Boutons */
/* Style général */
#termsfeed-com---nb .cc-nb-okagree,
#termsfeed-com---nb .cc-nb-reject,
#termsfeed-com---nb .cc-nb-changep,
#termsfeed-com---preferences-center .cc-cp-foot-save {
  @media screen {
    font-size: 14px;
    text-transform: uppercase;
    font-family: $font-family-ibm;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
    padding: 0 16px;
    height: 36px;
    border-radius: 4px;
  }
}

/* Primaire */
#termsfeed-com---nb .cc-nb-okagree,
#termsfeed-com---nb .cc-nb-reject,
#termsfeed-com---preferences-center .cc-cp-foot-save {
  @media screen {
    color: white;
    background-color: color('primary');
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.025);

    &:hover,
    &:focus-visible {
      background-color: rgba(color('primary-rgba'), 0.8);
    }

    &:active {
      background-color: rgba(color('primary-rgba'), 0.5);
    }
  }
}

/* Link */
#termsfeed-com---nb .cc-nb-changep {
  @media screen {
    color: color('primary');
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus-visible {
      background-color: rgba(color('primary-rgba'), 0.15);
      outline: none;
    }

    &:active {
      background-color: rgba(color('primary-rgba'), 0.3);
    }
  }
}
</style>
