<template>
  <div>
    <dialog-terms-of-use v-if="app !== undefined && app.termsVisible" />
    <dialog-recruiter-terms v-if="app !== undefined && app.recruiterTermsVisible" />
  </div>
</template>

<script>
import DialogTermsOfUse from '@/components/dialog/terms-of-use'
import DialogRecruiterTerms from '@/components/dialog/recruiter-terms'

import gql from 'graphql-tag'

const GET_TERMS_VISIBLE = gql`
  query GetTermsVisible {
    app @client {
      termsVisible
      recruiterTermsVisible
    }
  }
`

export default {
  components: {
    DialogTermsOfUse,
    DialogRecruiterTerms
  },
  apollo: {
    app: {
      query: GET_TERMS_VISIBLE
    }
  }
}
</script>
