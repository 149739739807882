<template>
  <v-app id="app" class="app">
    <check-status-dialog />
    <loading-bar />
    <termsfeed :locale="locale"></termsfeed>
    <nav-app app v-if="app && app.showMainNav" />
    <v-content>
      <div class="app__content">
        <div class="app__top">
          <notification-container />
          <terms-of-use-container />
          <nav-breadcrumb />
        </div>
        <div class="app__view">
          <router-view :key="$route.path" />
        </div>
      </div>
    </v-content>
    <v-footer
      :fixed="!isMobile"
      v-if="this.user !== undefined && !isLogged"
      class="pa-3"
      :height="isMobile ? 'auto' : '32px'"
      :style="{ paddingBottom: isMobile ? '60px !important' : '' }"
    >
      <v-layout v-if="isMobile">
        <v-flex>
          <div>
            <div class="footer__btn-text" @click="changeLang">
              <v-icon size="1.25em" class="pr-1">language</v-icon>{{ $t('opposite-lang') }}
            </div>
            <div class="footer__btn-text pt-3" @click="goToQETWebsite">{{ $t('nav-app-go-to-QET-website') }}</div>
            <div class="footer__btn-text" @click="contactQI">{{ $t('nav-app-contact') }}</div>
            <div class="footer__btn-text" @click="openTermsfeed">{{ $t('nav-app-open-termsfeed') }}</div>
          </div>
          <div>
            <div @click="openUrl($t('privacy-policy-url'))">
              {{ $t('nav-app-privacy-policy') }}
            </div>
          </div>
          <div>
            <div @click="openUrl($t('terms-of-use-url'))">
              {{ $t('nav-app-terms-of-service') }}
            </div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-else justify-center row wrap>
        <div class="footer__btn-text" @click="changeLang">
          <v-icon size="1.25em" class="pr-1">language</v-icon>{{ $t('opposite-lang') }}
        </div>
        <div class="footer__btn-text padding-left" @click="goToQETWebsite">{{ $t('nav-app-go-to-QET-website') }}</div>
        <div class="footer__btn-text padding-left" @click="contactQI">{{ $t('nav-app-contact') }}</div>
        <div class="footer__btn-text padding-left" @click="openTermsfeed">{{ $t('nav-app-open-termsfeed') }}</div>
        <v-spacer />
        <div class="footer__btn-text" @click="openUrl($t('privacy-policy-url'))">
          {{ $t('nav-app-privacy-policy') }}
        </div>
        <div class="footer__btn-text padding-left" @click="openUrl($t('terms-of-use-url'))">
          {{ $t('nav-app-terms-of-service') }}
        </div>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import i18n from '@/helpers/i18n'
import NotificationContainer from '@/components/notification/notification-container'
import NavApp from '@/components/nav/nav-app'
import NavBreadcrumb from '@/components/nav/nav-breadcrumb'
import LoadingBar from '@/components/loader/generic-loading'
import TermsOfUseContainer from '@/components/terms/terms-of-use-container'
import CheckStatusDialog from '@/components/dialog/check-status'
import Notification from '@/services/notification'
import Termsfeed from '@/components/termsfeed/termsfeed'

import router from '@/helpers/router'
import { ThemeSlug } from '@/helpers/theme'

import gql from 'graphql-tag'

const GET_ME = gql`
  query Me($language: ContentLanguage = FRENCH) {
    me {
      id
      hasUpcomingInterview
      upcomingInterview(language: $language) {
        companyInstitutionName
        waitingRoomUrl
      }
    }
  }
`
const GET_LOCAL_USER_IS_CONNECTED = gql`
  query LocalUserIsConnected {
    user @client {
      isConnected
    }
  }
`
const GET_SHOW_NAV = gql`
  query LocalShowMenu {
    app @client {
      showMainNav
      displayUpcomingInterviewNotification
    }
  }
`

export default {
  apollo: {
    app: {
      query: GET_SHOW_NAV
    },
    user: {
      query: GET_LOCAL_USER_IS_CONNECTED
    },
    me: {
      query: GET_ME,
      variables() {
        return {
          language: this.$currentContentLanguage()
        }
      },
      skip() {
        return this.userIsAnonyme
      },
      result({ data, loading }) {
        if (!loading && data.me.hasUpcomingInterview && this.app.displayUpcomingInterviewNotification) {
          Notification.showInfo(
            this.$t('upcoming-appointment-notification', {
              companyInstitutionName: data.me.upcomingInterview.companyInstitutionName,
              onClick: data.me.upcomingInterview.waitingRoomUrl
            }),
            1000000,
            true,
            true
          )
        }
      }
    }
  },
  data() {
    return {
      ThemeSlug
    }
  },
  components: {
    LoadingBar,
    NotificationContainer,
    NavApp,
    NavBreadcrumb,
    TermsOfUseContainer,
    CheckStatusDialog,
    Termsfeed
  },
  computed: {
    userIsAnonyme: function () {
      return !this.user || !this.user.isConnected
    },
    isLogged: function () {
      if (this.user !== undefined) {
        return this.user.isConnected
      }

      return false
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    locale: function () {
      return i18n.locale()
    }
  },
  methods: {
    changeLang: function () {
      router.changeLang()
    },
    goToQETWebsite: function () {
      window.open(this.$t('QET-website-btn-url'), '_blank')
    },
    contactQI: function () {
      window.open(this.$t('contact-btn-url'), '_blank')
    },
    openUrl: function (url) {
      window.open(url, '_blank')
    },
    openTermsfeed: function () {
      const termsfeed = document.getElementById('open_preferences_center')
      if (termsfeed !== undefined && termsfeed !== null) {
        termsfeed.click()
      }
    }
  }
}
</script>

<style lang="stylus">
:root {
  .theme-qi {
    --primary: #009fdf;
    --primary-rgba: 0, 159, 223;
    --primary-b96: #daeef4;
    --primary-b96-rgba: 218, 238, 244;
    --primary-b98: #ecf6fa;
    --primary-b98-rgba: 236, 246, 250;

    --secondary: #002d5b;
    --secondary-rgba: 0, 45, 91;
    --secondary-b30: #041f49;
    --secondary-b30-rgba: 4, 31, 73;
    --secondary-b60: #233c93;
    --secondary-b-60-rgba: 35, 60, 147;
  }

  .theme-sded {
    --primary: #F08D42;
    --primary-rgba: 240, 141, 66;
    --primary-b96: #F9D2B3;
    --primary-b96-rgba: 253, 221, 196;
    --primary-b98: #FEF4EC;
    --primary-b98-rgba: 248, 230, 216;

    --secondary: #2D6A6C;
    --secondary-rgba: 36, 62, 92;
    --secondary-b30: #4DB2B5;
    --secondary-b30-rgba: 167, 178, 190;
    --secondary-b60: #B7E0E1;
    --secondary-b-60-rgba: 102, 120, 141;
  }
}

.app {
  .v-content__wrap {
    background: $body-bg-light;
  }

  &__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__top {
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
  }

  &__view {
    display: block;
    flex: 1 0 auto;
    max-width: 100%;
    position: relative;
  }

  .footer__contact-btn-icon{
    font-size:small !important;
  }

  .footer__btn-text{
    flex:none;
    cursor:pointer;
    &:hover {
      text-decoration: underline
    }
  }

  .padding-left {
    padding-left: 20px;
  }
}
</style>
