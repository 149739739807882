import Apollo from '@/graphql/vue-apollo'

import { Consts } from '@/helpers/consts'
import i18n from '@/helpers/i18n'

import gql from 'graphql-tag'

const GET_PUBLISH_PROGRAM_TITLE_BY_ID = gql`
  query PublishStudyProgramTitleById($id: ID!, $language: ContentLanguage!) {
    studyProgramPosting(id: $id) {
      id
      publishedVersion {
        id
        translationOrDefault(language: $language) {
          id
          title
        }
      }
    }
  }
`
const GET_DRAFT_PROGRAM_TITLE_BY_ID = gql`
  query DraftStudyProgramTitleById($id: ID!, $language: ContentLanguage!) {
    studyProgramPosting(id: $id) {
      id
      draftVersion {
        id
        translationOrDefault(language: $language) {
          id
          title
        }
      }
    }
  }
`
const GET_MISSION_NAME_BY_ID = gql`
  query MissionById($id: ID!) {
    mission(id: $id) {
      id
      name
    }
  }
`
const GET_DRAFT_JOB_TITLE_BY_ID = gql`
  query GetDraftJobTitleById($id: ID!, $language: ContentLanguage!) {
    jobPosting(id: $id) {
      id
      draftVersion {
        id
        translationOrDefault(language: $language) {
          id
          title
        }
      }
    }
  }
`
const GET_PUBLISH_JOB_TITLE_BY_ID = gql`
  query GetPublishJobTitleById($id: ID!, $language: ContentLanguage!) {
    jobPosting(id: $id) {
      id
      publishedVersion {
        id
        translationOrDefault(language: $language) {
          id
          title
        }
      }
    }
  }
`
const GET_CANDIDATE_NAME_BY_APPLICATION_ID = gql`
  query GetCandidateNameByApplicationId($id: ID!) {
    application(id: $id) {
      id
      candidate {
        id
        firstName
        lastName
      }
    }
  }
`
const GET_CANDIDATE_NAME_BY_ID = gql`
  query GetCandidateNameById($id: ID!) {
    user(id: $id) {
      id
      profile {
        id
        ... on CandidateProfileGraphType {
          firstName
          lastName
        }
      }
    }
  }
`
const GET_COMPANY_NAME_BY_ID = gql`
  query CompanyById($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
    }
  }
`
const GET_INSTITUTION_NAME_BY_ID = gql`
  query InstitutionById($institutionId: ID!) {
    institution(institutionId: $institutionId) {
      id
      name
    }
  }
`

export default {
  businessName: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_COMPANY_NAME_BY_ID,
      variables: {
        companyId: splitId(id)
      }
    })

    return result.data.company.name
  },
  publishJobPostingTitle: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_PUBLISH_JOB_TITLE_BY_ID,
      variables: {
        id: splitId(id),
        language: currentContentLanguage()
      }
    })

    return result.data.jobPosting.publishedVersion.translationOrDefault.title
  },
  draftJobPostingTitle: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_DRAFT_JOB_TITLE_BY_ID,
      variables: {
        id: splitId(id),
        language: currentContentLanguage()
      }
    })

    return result.data.jobPosting.draftVersion.translationOrDefault.title
  },
  candidateName: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_CANDIDATE_NAME_BY_ID,
      variables: {
        id: splitId(id)
      }
    })

    return `${result.data.user.profile.firstName} ${result.data.user.profile.lastName}`
  },
  candidateNameByApplication: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_CANDIDATE_NAME_BY_APPLICATION_ID,
      variables: {
        id: splitId(id)
      }
    })

    return `${result.data.application.candidate.firstName} ${result.data.application.candidate.lastName}`
  },
  missionName: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_MISSION_NAME_BY_ID,
      variables: {
        id: splitId(id)
      }
    })

    return result.data.mission.name
  },
  institutionName: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_INSTITUTION_NAME_BY_ID,
      variables: {
        institutionId: splitId(id)
      }
    })

    return result.data.institution.name
  },
  publishProgramTitle: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_PUBLISH_PROGRAM_TITLE_BY_ID,
      variables: {
        id: splitId(id),
        language: currentContentLanguage()
      }
    })

    return result.data.studyProgramPosting.publishedVersion.translationOrDefault.title
  },
  draftProgramTitle: async function (id) {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_DRAFT_PROGRAM_TITLE_BY_ID,
      variables: {
        id: splitId(id),
        language: currentContentLanguage()
      }
    })

    return result.data.studyProgramPosting.draftVersion.translationOrDefault.title
  }
}

function splitId(id) {
  return id.split('-').last()
}

function currentContentLanguage() {
  return i18n.locale() === Consts.LANG_FR ? Consts.CONTENT_LANGUAGE_FR : Consts.CONTENT_LANGUAGE_EN
}
