<template>
  <v-list dense class="nav-app-business">
    <!-- Mini -->
    <v-list-tile v-show="mini" @click="triggerOpen">
      <v-list-tile-action>
        <div class="text-truncate">{{ currentBusiness.name }}</div>
      </v-list-tile-action>
    </v-list-tile>
    <!-- NOT Mini -->
    <v-list-group v-show="!mini" v-model="open">
      <v-list-tile slot="activator">
        <v-list-tile-action>
          <v-icon>business</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ currentBusiness.name }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile
        @click="selectBusiness(business.id)"
        v-for="business in this.me.recruiterOf.filter((b) => !isCurrentBusiness(b.id))"
        :key="business.id"
      >
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ business.name }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list-group>
  </v-list>
</template>

<script>
import Company from '@/services/company'
import { Consts } from '@/helpers/consts'
import i18n from '@/helpers/i18n'

import gql from 'graphql-tag'

const GET_LOCAL_USER_CURRENT_COMPANY = gql`
  query LocalUserCurrentCompany {
    user @client {
      selectedCompany
    }
  }
`

export default {
  data: function () {
    return {
      isOpen: false
    }
  },
  props: {
    mini: {
      type: Boolean,
      required: true
    },
    me: {
      type: Object,
      required: false
    }
  },
  computed: {
    open: {
      get() {
        return this.mini ? false : this.isOpen
      },
      set(bool) {
        this.isOpen = this.mini ? false : bool
      }
    },
    isCurrentBusiness: function () {
      return (businessId) => {
        if (this.user && this.user.selectedCompany) {
          return this.user.selectedCompany === businessId
        }
        return false
      }
    },
    currentBusiness: function () {
      const current = this.me.recruiterOf.filter((business) => this.isCurrentBusiness(business.id))
      return current[0] || { name: '' }
    }
  },
  methods: {
    selectBusiness: async function (companyId) {
      await Company.updateCompanySelected(companyId)
      this.isGroupOpen = false
      this.$router.replace(i18n.getLocalizedUrl(Consts.URL_JOB_OFFER_LIST))
    },
    triggerOpen: function () {
      this.isOpen = true
      this.$emit('open')
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER_CURRENT_COMPANY
    }
  }
}
</script>

<style lang="stylus">
.nav-app-business { // Scope
  .v-list__group {
    &:before, &:after {
      display: none;
    }
  }

  .v-list__group__header {
    .v-list__group__header__append-icon {
      padding-left: 0;
    }
  }
}
</style>
