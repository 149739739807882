import Apollo from '@/graphql/vue-apollo'

import gql from 'graphql-tag'

const GET_WIZARD_SELECTION = gql`
  query GetLoadingCount {
    app @client {
      wizardSelection {
        terms
        domains
        education
        diploma
        frenchLevel
        interviewLocation
        institutionLevel
      }
    }
  }
`
const GET_JOB_OFFER_FILTER = gql`
  query GetJobOfferFilter {
    app @client {
      jobOfferFilter {
        terms
        domains
        education
        englishLevel
        experience
        frenchLevel
        interviewLocation
      }
    }
  }
`
const UPDATE_DISPLAY_BREADCRUMB = gql`
  mutation UpdateDisplayBreadcrumb($showBreadcrumb: Boolean!, $isAbsolutePos: Boolean!) {
    updateDisplayBreadcrumb(showBreadcrumb: $showBreadcrumb, isAbsolutePos: $isAbsolutePos) @client
  }
`
const SAVE_JOB_OFFER_FILTER = gql`
  mutation SaveJobOfferFilter($filters: JobOfferFilter!) {
    saveJobOfferFilter(filters: $filters) @client
  }
`
const SAVE_WIZARD_SELECTION = gql`
  mutation SaveWizardSelection($wizard: WizardSelection!) {
    saveWizardSelection(wizard: $wizard) @client
  }
`
const UPDATE_DISPLAY_ANY_NAV = gql`
  mutation UpdateLocalDisplayAnyNav($showNav: Boolean!) {
    updateDisplayAnyNav(showNav: $showNav) @client
  }
`
const UPDATE_DISPLAY_UPCOMING_INTERVIEW_NOTIFICATION = gql`
  mutation UpdateLocalDisplayUpcomingInterviewNotification($displayUpcomingInterviewNotification: Boolean!) {
    updateDisplayUpcomingInterviewNotification(displayUpcomingInterviewNotification: $displayUpcomingInterviewNotification)
      @client
  }
`
const UPDATE_DISPLAY_MAIN_NAV = gql`
  mutation UpdateLocalDisplayMainNav($showNav: Boolean!) {
    updateDisplayMainNav(showNav: $showNav) @client
  }
`

export default {
  updateDisplayMainNav: async function (showNav) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_DISPLAY_MAIN_NAV,
      variables: {
        showNav
      }
    })
  },
  updateDisplayAnyNav: async function (showNav) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_DISPLAY_ANY_NAV,
      variables: {
        showNav
      }
    })
  },
  updateDisplayUpcomingInterviewNotification: async function (displayUpcomingInterviewNotification) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_DISPLAY_UPCOMING_INTERVIEW_NOTIFICATION,
      variables: {
        displayUpcomingInterviewNotification
      }
    })
  },
  updateDisplayBreadcrumb: async function (showBreadcrumb, isAbsolutePos) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_DISPLAY_BREADCRUMB,
      variables: {
        showBreadcrumb,
        isAbsolutePos
      }
    })
  },
  saveWizardSelection: async function (wizard) {
    await Apollo.instance.defaultClient.mutate({
      mutation: SAVE_WIZARD_SELECTION,
      variables: {
        wizard
      }
    })
  },
  getWizardSelection: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_WIZARD_SELECTION
    })
    return result.data.app.wizardSelection
  },
  saveJobOfferFilter: async function (filters) {
    await Apollo.instance.defaultClient.mutate({
      mutation: SAVE_JOB_OFFER_FILTER,
      variables: {
        filters
      }
    })
  },
  getJobOfferFilter: async function () {
    const result = await Apollo.instance.defaultClient.query({
      query: GET_JOB_OFFER_FILTER
    })
    return result.data.app.jobOfferFilter
  }
}
