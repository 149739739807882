<template>
  <div class="nav-app-mobile">
    <v-btn class="nav-app-mobile__menu" icon @click="$emit('open')">
      <v-icon color="primary">menu</v-icon>
    </v-btn>
    <ui-app-logo class="nav-app-mobile__logo" />
  </div>
</template>

<script>
import Applicationable from 'vuetify/lib/mixins/applicationable'

export default {
  mixins: [Applicationable('top', [])],
  data() {
    const barHeight = 74
    const btnHeight = 40
    return {
      height: barHeight - (barHeight - btnHeight) / 2
    }
  },
  methods: {
    updateApplication() {
      return this.height
    }
  }
}
</script>

<style lang="stylus" scoped>
$nav-app-mobile-height := 64px

.nav-app-mobile
  &__menu
    position: fixed
    top: ($nav-app-mobile-height / 2)
    left: 8px
    margin: 0
    transform: translateY(-50%)
    z-index: 4
  &__logo
    position: fixed
    top: ($nav-app-mobile-height / 2)
    left: 64px
    transform: translateY(-50%)
    z-index: 0
</style>
