<template>
  <div class="scaffolding rm-child-margin">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<docs>
Afficher du contenu HTML avec la bonne présentation par
défaut (_scaffolding_).

<ui-content>
  <h1>
    Lorem ipsum dolor sit amet
  </h1>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Praesent ipsum tortor, placerat nec ante at, porta vehicula sapien.
  </p>
</ui-content>
<br />

``` html
<ui-content>
  <!-- HTML Element -->
</ui-content>
```
</docs>
