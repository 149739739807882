import Apollo from '@/graphql/vue-apollo'

import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const UPDATE_LOCAL_USER = gql`
  mutation UpdateLocalUser($user: User!) {
    updateLocalUser(user: $user) @client
  }
`
const CREATE_MISSION = gql`
  mutation CreateMission($input: CreateMissionInput!) {
    createMission(input: $input) {
      mission {
        id
        name
        type
        countries {
          id
          countryCode
          name
        }
      }
    }
  }
`
const UPDATE_MISSION = gql`
  mutation UpdateMission($input: UpdateMissionInput!) {
    updateMission(input: $input) {
      mission {
        id
        endDate
        locations {
          id
        }
        name
        startDate
        publicationStartDate
        publicationEndDate
        type
        countries {
          id
          countryCode
          name
        }
      }
    }
  }
`

export default {
  createMission: async function (name, type, countries) {
    const response = await Apollo.instance.defaultClient.mutate({
      mutation: CREATE_MISSION,
      variables: {
        input: {
          name,
          type,
          countries
        }
      }
    })

    return response.data.createMission.mission
  },
  updateMission: async function (missionId, updatedValues) {
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_MISSION,
      variables: {
        input: {
          missionId,
          ...updatedValues
        }
      }
    })
  },
  updateMissionSelected: async function (selectedMission) {
    const user = { selectedMission }
    await Apollo.instance.defaultClient.mutate({
      mutation: UPDATE_LOCAL_USER,
      variables: {
        user
      }
    })

    localStorage.setItem(Consts.LOCAL_STORAGE_CURRENT_MISSION, selectedMission)
  }
}
