<template>
  <v-list v-if="missions.length > 0" two-line class="nav-app-mission">
    <!-- Mini -->
    <v-list-tile v-show="mini" @click.stop="open">
      <v-list-tile-action>
        <div v-if="selectedMission !== null" class="text-truncate">{{ selectedMission.label }}</div>
      </v-list-tile-action>
    </v-list-tile>
    <!-- NOT Mini -->
    <v-list-group v-if="availableMissions.length > 0" v-show="!mini" v-model="groupOpen">
      <!-- Mission sélectionné -->
      <v-list-tile slot="activator">
        <v-list-tile-content v-if="selectedMission !== null">
          <v-list-tile-title class="primary--text">{{ selectedMission.label }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ dateLabel(selectedMission.date) }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- Mission -->
      <template v-for="mission in availableMissions">
        <v-list-tile @click.stop="selectMission(mission.id)" :key="mission.id">
          <v-list-tile-content>
            <v-list-tile-title>{{ mission.label }}</v-list-tile-title>
            <v-list-tile-sub-title>{{ dateLabel(mission.date) }}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list-group>
    <!-- NOT Mini && only one mission (the selected one) -->
    <v-list-tile v-else-if="selectedMission !== null">
      <v-list-tile-content v-if="selectedMission !== null">
        <v-list-tile-title class="primary--text">{{ selectedMission.label }}</v-list-tile-title>
        <v-list-tile-sub-title>{{ dateLabel(selectedMission.date) }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import i18n from '@/helpers/i18n'
import Mission from '@/services/mission'
import gql from 'graphql-tag'

const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      selectedMission
    }
  }
`

export default {
  model: {
    prop: 'missions',
    event: 'change'
  },
  data: function () {
    return {
      isGroupOpen: false
    }
  },
  props: {
    mini: {
      type: Boolean,
      required: true
    },
    missions: {
      type: Array,
      required: true
    }
  },
  computed: {
    dateLabel: function () {
      return (date) => {
        const formattedDates = i18n.getFormattedStartEndDates(date.startDate, date.endDate)
        return i18n.instance().t('mission-list-date', {
          startDate: formattedDates.startDate,
          endDate: formattedDates.endDate
        })
      }
    },
    groupOpen: {
      get() {
        return this.mini ? false : this.isGroupOpen
      },
      set(bool) {
        this.isGroupOpen = this.mini ? false : bool
      }
    },
    key() {
      return (index) => `mission-${index}`
    },
    selectedMission() {
      const missions = this.missions.filter((mission) => mission.id === this.user.selectedMission)
      if (missions && missions.length > 0) {
        return missions[0]
      }
      return null
    },
    availableMissions() {
      const missions = this.missions.filter((mission) => mission.id !== this.user.selectedMission)
      if (missions && missions.length > 0) {
        return missions
      }
      return []
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER
    }
  },
  methods: {
    open: function () {
      this.isGroupOpen = true
      this.$emit('open')
    },
    selectMission: async function (missionId) {
      await Mission.updateMissionSelected(missionId)
      this.isGroupOpen = false
    }
  }
}
</script>

<style lang="stylus">
.nav-app-mission { // Scope
  .v-list__group {
    &:before, &:after {
      display: none;
    }
  }

  .v-list__group__header {
    .v-list__group__header__append-icon {
      padding-left: 0;
    }
  }
}
</style>
