<template>
  <v-dialog v-model="displayDialog" persistent scrollable max-width="480">
    <v-card>
      <v-card-text style="max-height: calc(100vh - 100px)">
        <ui-content>
          <h2 class="title">{{ $t('terms-of-use-change-title') }}</h2>
          <p>{{ $t('terms-of-use-change-text') }}</p>
        </ui-content>
        <v-checkbox v-model="consentEmail" :label="$t('terms-of-use-consent-email-checkbox')"></v-checkbox>
        <v-checkbox v-model="consentPrivacyPolicy" class="termsCheckbox">
          <template v-slot:label>
            <span v-html="$t('terms-of-use-privacy-policy-checkbox', { url: $t('privacy-policy-url') })" />
          </template>
        </v-checkbox>
        <v-checkbox v-model="consentTermsOfService" class="termsCheckbox">
          <template v-slot:label>
            <span v-html="$t('terms-of-use-checkbox', { url: $t('terms-of-use-url') })" />
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="logout">{{ $t('logout-label') }}</v-btn>
        <v-btn
          :disabled="disabledBtn || !consentEmail || !consentPrivacyPolicy || !consentTermsOfService"
          color="primary"
          @click="onAcceptTerms"
          >{{ $t('terms-of-use-accept-btn') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Terms from '@/services/terms'
import Authentification from '@/services/authentification'

import { Consts } from '@/helpers/consts'
import { ThemeSlug } from '@/helpers/theme'

export default {
  data() {
    return {
      ThemeSlug,
      consentEmail: false,
      consentPrivacyPolicy: false,
      consentTermsOfService: false,
      displayDialog: true,
      disabledBtn: false
    }
  },
  methods: {
    onAcceptTerms: async function () {
      if (this.consentEmail && this.consentPrivacyPolicy && this.consentTermsOfService) {
        this.disabledBtn = true
        await Terms.acceptTos(Consts.QI_CAN_CONTACT_ME_LATEST, Consts.PRIVACY_POLICY_LATEST, Consts.TOS_LATEST)
        this.displayDialog = false
      }
    },
    logout: function () {
      Authentification.logout()
    }
  }
}
</script>

<style lang="scss">
.termsCheckbox {
  margin-top: 0px;
}
</style>
