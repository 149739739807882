import Apollo from '@/graphql/vue-apollo'

import gql from 'graphql-tag'

const SCHEDULE_CANDIDATES_REPORT = gql`
  mutation ScheduleCandidatesReport($input: ScheduleCandidatesReportInput!) {
    scheduleCandidatesReport(input: $input) {
      scheduledReport {
        id
        scheduled
      }
    }
  }
`

export default {
  ScheduleCandidatesReport: async function () {
    const input = { reportName: 'CandidatesReport' }

    const result = await Apollo.instance.defaultClient.mutate({
      mutation: SCHEDULE_CANDIDATES_REPORT,
      variables: {
        input
      }
    })

    return result.data.scheduleCandidatesReport.scheduledReport.scheduled
  }
}
