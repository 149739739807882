export default {
  install(Vue, options) {
    Vue.prototype.$htmlEnter = (str) => {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    }
    Vue.prototype.$toLocalDate = (str) => {
      if (str.split('-').length === 3) {
        return str
      } else {
        return `${str}-01`
      }
    }
  }
}
