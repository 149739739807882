import Apollo from '@/graphql/vue-apollo'
import { Consts } from '@/helpers/consts'
import id from '@/helpers/unique-id'

import gql from 'graphql-tag'

const ADD_NOTIFICATION = gql`
  mutation CreateLocalNotification($notification: Notification!) {
    addNotification(notification: $notification) @client
  }
`
const REMOVE_NOTIFICATION_BY_ID = gql`
  mutation RemoveLocalNotificationById($id: ID!) {
    removeNotificationById(id: $id) @client
  }
`
const REMOVE_NOTIFICATION_BY_TYPE = gql`
  mutation RemoveLocalNotificationByType($type: NotificationType!) {
    removeNotificationByType(type: $type) @client
  }
`

export default {
  showSuccess: async (text, duration = 1000, dismissible = true, contentIsHtml = false) =>
    await addNotification(Consts.NOTIFICATION_TYPE_SUCCESS, text, dismissible, duration, contentIsHtml),
  showInfo: async (text, duration = 1000, dismissible = true, contentIsHtml = false) =>
    await addNotification(Consts.NOTIFICATION_TYPE_INFO, text, dismissible, duration, contentIsHtml),
  showWarning: async (text, duration = 1000, dismissible = true, contentIsHtml = false) =>
    await addNotification(Consts.NOTIFICATION_TYPE_WARNING, text, dismissible, duration, contentIsHtml),
  showError: async (text, duration = -1, dismissible = true, contentIsHtml = false) =>
    await addNotification(Consts.NOTIFICATION_TYPE_ERROR, text, dismissible, duration, contentIsHtml),
  removeNotificationById: async (id) => {
    return await Apollo.instance.defaultClient.mutate({
      mutation: REMOVE_NOTIFICATION_BY_ID,
      variables: {
        id
      }
    })
  },
  removeNotificationByType: async (type) => {
    return await Apollo.instance.defaultClient.mutate({
      mutation: REMOVE_NOTIFICATION_BY_TYPE,
      variables: {
        type
      }
    })
  }
}

async function addNotification(type, text, dismissible, duration, contentIsHtml) {
  const notification = {
    __typename: 'Notification',
    id: id.generate(),
    type,
    text,
    duration,
    dismissible,
    contentIsHtml
  }

  await Apollo.instance.defaultClient.mutate({
    mutation: ADD_NOTIFICATION,
    variables: { notification }
  })

  return notification.id
}
