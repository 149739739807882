import Vue from 'vue'
import VueI18n from 'vue-i18n'
import UrlI18n from '@/plugins/url-i18n'
import { Consts } from '@/helpers/consts'
import { SetVueI18nDefaultArgs } from '@/helpers/theme'
import env from '@/helpers/env'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
dayjs.locale('fr')
dayjs.extend(advancedFormat)

SetVueI18nDefaultArgs(VueI18n)
Vue.use(VueI18n)

const instanceI18n = new VueI18n({
  locale: env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadLocaleMessages()
})

Vue.use(UrlI18n, { i18n: instanceI18n })

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

let localizedUrlByKey = {}

export default {
  instance: function () {
    return instanceI18n
  },
  locale: () => {
    return instanceI18n.locale
  },
  getFormattedStartEndDates: (startDateString, endDateString, forceLanguage) => {
    const language = forceLanguage || instanceI18n.locale
    const startDate = dayjs(startDateString)
    const endDate = dayjs(endDateString)
    let startDateFormat
    let endDateFormat

    if (startDate.year() === endDate.year()) {
      if (language === Consts.LANG_FR) {
        if (startDate.month() === endDate.month()) {
          startDateFormat = Consts.DAY_FORMAT
          endDateFormat = Consts.DAY_MONTH_YEAR_FORMAT
        } else {
          startDateFormat = Consts.DAY_MONTH_FORMAT
          endDateFormat = Consts.DAY_MONTH_YEAR_FORMAT
        }
      } else {
        startDateFormat = Consts.MONTH_DAY_FORMAT
        endDateFormat = Consts.MONTH_DAY_YEAR_FORMAT
      }
    } else {
      if (language === Consts.LANG_FR) {
        startDateFormat = Consts.DAY_MONTH_YEAR_FORMAT
        endDateFormat = Consts.DAY_MONTH_YEAR_FORMAT
      } else {
        startDateFormat = Consts.MONTH_DAY_YEAR_FORMAT
        endDateFormat = Consts.MONTH_DAY_YEAR_FORMAT
      }
    }

    return {
      startDate: getLocalizedDate(startDate, startDateFormat, language),
      endDate: getLocalizedDate(endDate, endDateFormat, language)
    }
  },
  setLang: (lang) => {
    dayjs.locale(lang)
    instanceI18n.locale = lang
  },
  changeLang: () => {
    const lang = instanceI18n.locale === 'en' ? 'fr' : 'en'
    dayjs.locale(lang)
    instanceI18n.locale = lang
  },
  getLocalizedUrl: (key) => {
    if (localizedUrlByKey[instanceI18n.locale][key] !== undefined) {
      return localizedUrlByKey[instanceI18n.locale][key]
    }

    return localizedUrlByKey[instanceI18n.locale]['404-url']
  },
  getLocalizedDate,
  getLocalizedMonthYear: (date) => {
    return getLocalizedDate(date, Consts.MONTH_YEAR_FORMAT)
  },
  getLocalizedDateDiff: (startDate, endDate, format) => {
    return Math.abs(dayjs(endDate).diff(dayjs(startDate), format))
  },
  setLocalizedUrlByKey: (keyToUrl) => {
    localizedUrlByKey = keyToUrl
  },
  isLangValid: (lang) => [Consts.LANG_FR, Consts.LANG_EN].includes(lang),
  getFullTextualYear: (date) => {
    let format = Consts.DAY_MONTH_YEAR_FORMAT
    if (instanceI18n.locale === Consts.LANG_EN) {
      format = Consts.MONTH_DAY_YEAR_FORMAT
    }
    return getLocalizedDate(date, format)
  }
}

function getLocalizedDate(date, format, forceLanguage) {
  const language = forceLanguage || instanceI18n.locale
  const parsedDate = typeof date === 'string' ? dayjs(date) : date
  let dateFormatted = dayjs(parsedDate).locale(language).format(format)

  if (language === Consts.LANG_FR) {
    dateFormatted = dateFormatted.toLowerCase()
  }

  return dateFormatted
}
