import Vuetify from 'vuetify/lib'
import '../stylus/app.styl'
import i18n from '@/helpers/i18n'
import { ColorTheme } from '@/helpers/theme'

const scrollbarWidth = (function () {
  const testWidth = 100
  const child = document.createElement('div')
  child.style.width = '100%'
  child.style.height = '100px'
  const parent = document.createElement('div')
  parent.style.position = 'fixed'
  parent.style.bottom = '0'
  parent.style.right = '0'
  parent.style.width = `${testWidth}px`
  parent.style.height = '10px'
  parent.style.overflow = 'auto'
  parent.appendChild(child)
  const body = document.getElementsByTagName('body')[0]
  body.appendChild(parent)
  const scrollbarWidth = testWidth - child.clientWidth
  body.removeChild(parent)
  return scrollbarWidth
})()

const VuetifyOptions = {
  theme: ColorTheme,
  breakpoint: {
    scrollbarWidth
  },
  lang: {
    t: (key, ...params) => i18n.instance().t(key, params)
  }
}

export { Vuetify, VuetifyOptions }
