const LANG_FR = 'fr'
const LANG_EN = 'en'

const THEME_QI = 'QI'
const THEME_SDED = 'SDED'

const CONTENT_LANGUAGE_FR = 'FRENCH'
const CONTENT_LANGUAGE_EN = 'ENGLISH'

const APPLY_TYPE_JOB = 'JOB'
const APPLY_TYPE_STUDY_PROGRAM = 'STUDY_PROGRAM'

const USER_TYPE_CANDIDATE = 'CANDIDATE'
const USER_TYPE_RECRUITER = 'RECRUITER'
const USER_TYPE_RECRUITER_EDUCATIONAL = 'RECRUITER_EDUCATIONAL'
const USER_TYPE_ADVISOR = 'ADVISOR'
const USER_TYPE_ANONYME = 'ANONYME'

const NOTIFICATION_TYPE_INFO = 'info'
const NOTIFICATION_TYPE_WARNING = 'warning '
const NOTIFICATION_TYPE_ERROR = 'error'
const NOTIFICATION_TYPE_SUCCESS = 'success'
const NOTIFICATION_TIME_PERMANENT = 1000000

const POST_TYPE_PERMANENT = 'PERMANENT'
const POST_TYPE_PART_TIME = 'PART_TIME'

const LANGUAGE_LEVEL_NOT_REQUIRED = 'NOT_REQUIRED'
const LANGUAGE_LEVEL_ELEMENTARY = 'ELEMENTARY'
const LANGUAGE_LEVEL_INTERMEDIATE = 'INTERMEDIATE'
const LANGUAGE_LEVEL_ADVANCED = 'ADVANCED'

const COMPUTER_LEVEL_NONE = 'NONE'
const COMPUTER_LEVEL_BEGINNER = 'BEGINNER'
const COMPUTER_LEVEL_INTERMEDIATE = 'INTERMEDIATE'
const COMPUTER_LEVEL_ADVANCED = 'ADVANCED'
const COMPUTER_LEVEL_EXPERT = 'EXPERT'

const ACADEMIC_LEVEL_NOT_REQUIRED = 'NOT_REQUIRED'
const ACADEMIC_LEVEL_SECONDARY = 'SECONDARY'
const ACADEMIC_LEVEL_COLLEGE = 'COLLEGE'
const ACADEMIC_LEVEL_UNIVERSITY = 'UNIVERSITY'
const ACADEMIC_LEVEL_UNIVERSITY_PLUS = 'UNIVERSITY_PLUS'

const EXPERIENCE_LEVEL_JUNIOR = 'JUNIOR'
const EXPERIENCE_LEVEL_INTERMEDIATE = 'INTERMEDIATE'
const EXPERIENCE_LEVEL_SENIOR = 'SENIOR'

const SALARY_RANGE_YEARLY = 'YEARLY'
const SALARY_RANGE_HOURLY = 'HOURLY'

const CRITERIA_VALUES_NOT_REQUIRED = 'NOT_REQUIRED'
const CRITERIA_VALUES_HELPFUL = 'USEFUL'
const CRITERIA_VALUES_IMPORTANT = 'IMPORTANT'
const CRITERIA_VALUES_FUNDAMENTAL = 'ESSENTIAL'

const TUITION_COST_TYPE_BY_YEAR = 'BY_YEAR'
const TUITION_COST_TYPE_FULL_PROGRAM = 'FULL_PROGRAM'

const STEP_JOB_OFFER_IDENTIFICATION = 'STEP_JOB_OFFER_IDENTIFICATION'
const STEP_JOB_OFFER_DESCRIPTION = 'STEP_JOB_OFFER_DESCRIPTION'
const STEP_JOB_OFFER_RESPONSIBILITY_COMPETENCE = 'STEP_JOB_OFFER_RESPONSIBILITY_COMPETENCE'
const STEP_JOB_OFFER_LANGUAGE = 'STEP_JOB_OFFER_LANGUAGE'
const STEP_JOB_OFFER_EDUCATIONS_EXPERIENCE = 'STEP_JOB_OFFER_EDUCATIONS_EXPERIENCE'
const STEP_JOB_OFFER_CONDITIONS = 'STEP_JOB_OFFER_CONDITIONS'
const STEP_JOB_OFFER_CRITERIA_VALUE = 'STEP_JOB_OFFER_CRITERIA_VALUE'
const STEP_JOB_OFFER_REVIEWING = 'STEP_JOB_OFFER_REVIEWING'

const STEP_CREATE_CANDIDATE_IDENTIFICATION = 'STEP_CREATE_CANDIDATE_IDENTIFICATION'
const STEP_CREATE_CANDIDATE_PROFIL = 'STEP_CREATE_CANDIDATE_PROFIL'

const STEP_EDIT_INSTITUTION_NAME = 'STEP_EDIT_INSTITUTION_NAME'
const STEP_EDIT_INSTITUTION_RECRUITER_EDUCATIONALS = 'STEP_EDIT_INSTITUTION_RECRUITER_EDUCATIONALS'
const STEP_EDIT_INSTITUTION_LOGO = 'STEP_EDIT_INSTITUTION_LOGO'
const STEP_EDIT_INSTITUTION_MISSIONS = 'STEP_EDIT_INSTITUTION_MISSIONS'

const INSTITUTION_LEVEL_PROFESSIONAL = 'INSTITUTION_LEVEL_PROFESSIONAL'
const INSTITUTION_LEVEL_COLLEGE = 'INSTITUTION_LEVEL_COLLEGE'
const INSTITUTION_LEVEL_UNIVERSITY = 'INSTITUTION_LEVEL_UNIVERSITY'

const INSTITUTION_LEVEL_PROFESSIONAL_VALUE = 'PROFESSIONAL'
const INSTITUTION_LEVEL_COLLEGE_VALUE = 'COLLEGE'
const INSTITUTION_LEVEL_UNIVERSITY_VALUE = 'UNIVERSITY'

const STEP_EDIT_BUSINESS_NAME = 'STEP_EDIT_BUSINESS_NAME'
const STEP_EDIT_BUSINESS_LOGO = 'STEP_EDIT_BUSINESS_LOGO'
const STEP_EDIT_BUSINESS_MISSIONS = 'STEP_EDIT_BUSINESS_MISSIONS'
const STEP_EDIT_BUSINESS_RECRUITERS = 'STEP_EDIT_BUSINESS_RECRUITERS'

const URL_LOGIN = 'login-url'
const URL_LOGIN_RECRUITER_NO_COMPANY_ERROR = 'no-company-error'
const URL_LOGIN_RECRUITER_EDUCATIONAL_NO_INSTITUTION_ERROR = 'no-institution-error'
const URL_DOC = 'doc-url'
const URL_404 = '404-url'
const URL_500 = '500-url'
const URL_HOME = 'home-url'
const URL_MAINTENANCE = 'maintenance-url'
const URL_EMAIL_CONFIRMATION = 'confirm-email-url'
const URL_USER_SETTINGS = 'user-setting-url'
const URL_CONFIRM_EMAIL_CHANGE = 'confirm-email-change-url'

const URL_JOB_APPLICATION_INTERVIEW_LIST = 'job-pplication-interview-list'
const URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST = 'study-program-application-interview-list'

const URL_JOB_INTERVIEW_WAITING_ROOM = 'job-interview-waiting-room'
const URL_JOB_INTERVIEW_CONNECTION = 'job-interview-connection'
const URL_STUDY_PROGRAM_INTERVIEW_CONNECTION = 'study-program-interview-connection'
const URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM = 'study-program-interview-waiting-room'
const WAITING_ROOM_STATUS_NOT_STARTED = 'NOT_STARTED'
const WAITING_ROOM_STATUS_STARTED = 'STARTED'
const WAITING_ROOM_STATUS_FINISHED = 'FINISHED'

const FAVORITE_JOB_POSTINGS = 'favorite-job-postings'
const FAVORITE_STUDY_PROGRAM_POSTINGS = 'favorite-study-program-postings'

const URL_JOB_OFFER_ERROR = 'job-offer-error-url'
const URL_JOB_OFFER_LIST = 'job-offer-list-url'
const URL_JOB_OFFER_LIST_CANDIDATE = 'job-offer-list-candidate-url'
const URL_JOB_OFFER_ID = 'job-offer-id-url'
const URL_JOB_OFFER_CREATE = 'job-offer-create-url'
const URL_JOB_OFFER_EDIT = 'job-offer-edit-url'
const URL_JOB_OFFER_SUBMITTED = 'job-offer-submitted-url'
const URL_JOB_OFFER_PREVIEW = 'job-offer-preview-url'
const URL_JOB_OFFER_REVIEW = 'job-offer-review-url'
const URL_JOB_OFFER_PIPELINE = 'job-offer-pipeline-url'
const URL_JOB_OFFER_SORT_NEW = 'job-offer-sort-new-url'
const URL_JOB_OFFER_APPLY = 'job-offer-apply-url'
const URL_JOB_OFFER_APPLY_SUBMITTED = 'job-offer-apply-submitted-url'
const URL_JOB_OFFER_PUBLISHED = 'job-offer-publish-url'
const URL_FORGET_PASSWORD = 'forgot-password-url'
const URL_FORGET_PASSWORD_CONFIRMATION = 'forgot-password-confirmation-url'
const URL_RESET_PASSWORD = 'reset-password-url'
const URL_CREATE_CANDIDATE_USER = 'candidate-registration-url'
const URL_CREATE_CANDIDATE_PROFIL = 'candidate-create-profil-url'
const URL_CREATE_CANDIDATE_USER_CONFIRMATION = 'candidate-registration-confirmation-url'
const URL_CREATE_RECUITER_PROFIL = 'recruiter-registration-url'
const URL_CREATE_RECRUITER_ERROR = 'recruiter-registration-error-url'
const URL_CREATE_ADVISOR_PROFIL = 'advisor-registration-url'
const URL_CREATE_ADVISOR_ERROR = 'advisor-registration-error-url'
const URL_REGISTER = 'registration-url'

const URL_STUDY_PROGRAM_APPLICATION_CONVOCATIONS_LIST = 'study-program-application-convocations-list-url'
const URL_STUDY_PROGRAM_APPLICATION_CONVOCATION = 'study-program-application-convocation-url'

const URL_STUDY_PROGRAM_ID = 'study-program-id-url'
const URL_STUDY_PROGRAM_ERROR = 'study-program-error-url'
const URL_STUDY_PROGRAM_CREATE = 'study-program-create-url'
const URL_STUDY_PROGRAM_SUBMITTED = 'study-program-submitted-url'
const URL_STUDY_PROGRAM_PUBLISHED = 'study-program-published-url'
const URL_STUDY_PROGRAM_EDIT = 'study-program-edit-url'
const URL_STUDY_PROGRAM_PREVIEW = 'study-program-preview-url'
const URL_STUDY_PROGRAM_REVIEW = 'study-program-review-url'
const URL_STUDY_PROGRAM_PIPELINE = 'study-program-pipeline-url'
const URL_STUDY_PROGRAM_SORT_NEW = 'study-program-sort-new-url'
const URL_STUDY_PROGRAM_APPLY = 'study-program-apply-url'
const URL_STUDY_PROGRAM_APPLY_SUBMITTED = 'study-program-apply-submitted-url'
const URL_STUDY_PROGRAM_LIST = 'study-program-list-url'
const URL_STUDY_PROGRAM_LIST_CANDIDATE = 'study-program-list-candidate-url'
const URL_FAVORITE_LIST = 'favorite-list-url'
const URL_INSTITUTION_PROFILE = 'institution-profile-url'
const URL_INSTITUTION_PROFILE_EDITION = 'institution-profile-edition-url'
const URL_INSTITUTION_PROFILE_SUBMITTED = 'institution-profile-submitted-url'
const URL_INSTITUTION_LIST = 'institution-list-url'
const URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL = 'institution-edit-recruiters-educational-url'

const URL_AGENDA_LIST_BY_COMPANY_INSTITUTION = 'agenda-list-by-company-institution-url'
const URL_COMPANY_AGENDA_LIST = 'company-agenda-list-url'
const URL_COMPANY_AGENDA_LIST_DETAIL = 'company-agenda-list-detail-url'
const URL_INSTITUTION_AGENDA_LIST = 'institution-agenda-list-url'
const URL_INSTITUTION_AGENDA_LIST_DETAIL = 'institution-agenda-list-detail-url'

const URL_PARAMS_INSTITUTION_ID = ':institutionId'
const URL_PARAMS_STUDY_PROGRAM_ID = ':studyProgramPostingId'

const URL_INSTITUTION_CREATE = 'institution-create-url'
const URL_INSTITUTION_CREATED = 'institution-created-url'

const URL_BUSINESS_PROFIL = 'business-detail'
const URL_BUSINESS_LIST = 'business-list-url'
const URL_BUSINESS_EDIT_RECRUITERS = 'business-edit-recruiters-url'
const URL_BUSINESS_CREATE = 'business-create-url'
const URL_BUSINESS_CREATED = 'business-created-url'
const URL_BUSINESS_ID = 'business-id-url'
const URL_BUSINESS_PROFIL_EDITION = 'business-profil-edition-url'
const URL_BUSINESS_PROFIL_SUBMITTED = 'business-profil-submitted-url'

const URL_MISSION = 'mission-url'
const URL_MISSION_LIST = 'mission-list-url'
const URL_MISSION_CREATE = 'mission-create-url'
const URL_MISSION_CREATED = 'mission-created-url'
const URL_MISSION_EDITION = 'mission-edition-url'

const URL_APPLICATIONS_LIST = 'applications-list-url'
const APPLICATIONS_LIST_APPLICATIONS = 'applications-list-applications'
const APPLICATIONS_LIST_STUDY_PROGRAM_APPLICATIONS = 'applications-list-study-program-applications'

const URL_APPLICATION_CONVOCATIONS_LIST = 'application-convocations-list-url'
const URL_APPLICATION_CONVOCATION = 'application-convocation-url'

const URL_CANDIDATE_APPLICATION = 'candidate-application-url'
const URL_CANDIDATE_APPLICATION_CV = 'candidate-application-cv-url'
const URL_CANDIDATE_CV = 'candidate-cv-url'
const URL_CANDIDATE_EDIT = 'candidate-cv-edition-url'
const URL_CANDIDATE_PROFIL_PRO_PREVIEW = 'candidate-profil-pro-preview-url'
const URL_CANDIDATE_EDIT_PRO = 'candidate-cv-edition-pro-url'
const URL_CANDIDATE_PROFIL_EDUCATIONAL_PREVIEW = 'candidate-profil-edu-preview-url'
const URL_CANDIDATE_EDIT_EDUCATIONAL = 'candidate-cv-edition-edu-url'
const URL_PARAMS_PROFILE_CREATE = ':profileCreate'
const URL_CANDIDATE_LIST = 'candidate-list-url'
const URL_EDIT_ADVISORS = 'edit-advisors-list-url'

const URL_PARAMS_JOB_OFFER_ID = ':jobOfferId'
const URL_PARAMS_BUSINESS_ID = ':businessId'
const URL_PARAMS_APPLICATION_ID = ':applicationId'
const URL_PARAMS_MISSION_ID = ':missionId'
const URL_CANDIDATE_CV_ID = ':candidateId'

const LOCAL_STORAGE_DEVICEID = 'device-id'
const LOCAL_STORAGE_AUTHTOKEN = 'auth-token'
const LOCAL_STORAGE_REFRESHTOKEN = 'refresh-token'
const LOCAL_STORAGE_RENEWTOKEN_STATUS = 'refresh-token-status'
const LOCAL_STORAGE_CURRENT_COMPANY = 'current-company'
const LOCAL_STORAGE_CURRENT_MISSION = 'current-mission'
const LOCAL_STORAGE_CURRENT_INSTITUTION = 'current-institution'
const LOCAL_STORAGE_DISPLAYED_STUDYPROGRAM_HINT = 'display-studyprogram-hint'
const LOCAL_STORAGE_GEOLOC = 'geoip2'
const LOCAL_STORAGE_GEOLOC_DATE = 'geoip2-date'

const INSTITUTION_LIST_DISPLAY_COUNT = 50
const COMPANY_LIST_DISPAY_COUNT = 50

const AGENDA_BUSINESS = 'agenda-business'
const AGENDA_INSTITUTION = 'agenda-institution'

const AGENDA_LIST_INSTITUTION_DISPLAY_COUNT = 24
const AGENDA_LIST_COMPANY_DISPLAY_COUNT = 24

const CHANGE_STUDY_PROGRAM_PIPELINE_LIST_DISPLAY_COUNT = 80 // Needed for Université Laval which often has between 50 and 60 programs per mission.
const STUDY_PROGRAM_LIST_DISPLAY_COUNT = 30
const JOB_OFFER_LIST_DISPLAY_COUNT = 30

const STUDY_PROGRAM_APPLY_LIST_DISPLAY_COUNT = 100
const JOB_OFFER_APPLY_LIST_DISPLAY_COUNT = 100

const STUDY_PIPELINE_DISPLAY_COUNT = 50
const PIPELINE_DISPLAY_COUNT = 50

const JOB_OFFER_COMPETENCIES_MAX_TAG_LENGTH = 40
const JOB_OFFER_COMPETENCIES_MAX_TAG_COUNT = 20
const APPLICATIONS_LOAD_COUNT = 30
const CANDIDATE_LIST_DISPLAY_COUNT = 60
const CANDIDATE_APPLICATIONS_LOAD_COUNT = 30
const CANDIDATE_NEW_APPLICATION_SORT_LIMIT_COUNT = 25
const DESCRIPTION_MAX_LENGTH = 1000
const PIPELINE_APPLICATIONS_MAX_COUNT = 30

const FORM_LOGIN_EMAIL_INPUT = 'FORM_LOGIN_EMAIL_INPUT'
const FORM_LOGIN_PASSWORD_INPUT = 'FORM_LOGIN_PASSWORD_INPUT'

const FORM_CREATE_JOB_OFFER_TITLE_INPUT = 'FORM_CREATE_JOB_OFFER_TITLE_INPUT'
const FORM_CREATE_JOB_OFFER_CNP_INPUT = 'FORM_CREATE_JOB_OFFER_CNP_INPUT'
const FORM_CREATE_JOB_OFFER_NUMBER_POSITION_INPUT = 'FORM_CREATE_JOB_OFFER_NUMBER_POSITION_INPUT'
const FORM_CREATE_JOB_OFFER_START_DATE_INPUT = 'FORM_CREATE_JOB_OFFER_START_DATE_INPUT'
const FORM_CREATE_JOB_OFFER_LOCATION_INPUT = 'FORM_CREATE_JOB_OFFER_LOCATION_INPUT'
const FORM_CREATE_JOB_OFFER_CORE_COMPETENCIES_INPUT = 'FORM_CREATE_JOB_OFFER_CORE_COMPETENCIES_INPUT'
const FORM_CREATE_JOB_OFFER_DESCRIPTION_INPUT = 'FORM_CREATE_JOB_OFFER_DESCRIPTION_INPUT'

const FORM_CREATE_CANDIDATE_EMAIL_INPUT = 'FORM_CREATE_CANDIDATE_EMAIL_INPUT'
const FORM_CREATE_CANDIDATE_PASSWORD_INPUT = 'FORM_CREATE_CANDIDATE_PASSWORD_INPUT'
const FORM_CREATE_CANDIDATE_PASSWORD_CONFIRMATION_INPUT = 'FORM_CREATE_CANDIDATE_PASSWORD_CONFIRMATION_INPUT'
const FORM_CREATE_CANDIDATE_FIRSTNAME_INPUT = 'FORM_CREATE_CANDIDATE_FIRSTNAME_INPUT'
const FORM_CREATE_CANDIDATE_LASTNAME_INPUT = 'FORM_CREATE_CANDIDATE_LASTNAME_INPUT'
const FORM_CREATE_CANDIDATE_JOBTITLE_INPUT = 'FORM_CREATE_CANDIDATE_JOBTITLE_INPUT'

const JOB_OFFER_STATE_DEACTIVATED = 'DEACTIVATED'
const JOB_OFFER_STATE_PAUSED = 'PAUSED'
const JOB_OFFER_STATE_DRAFT = 'DRAFT'
const JOB_OFFER_STATE_FULFILLED = 'FULFILLED'
const JOB_OFFER_STATE_PUBLISHED = 'PUBLISHED'
const JOB_OFFER_STATE_ARCHIVED = 'ARCHIVED'

const STUDY_PROGRAM_STATE_DEACTIVATED = 'DEACTIVATED'
const STUDY_PROGRAM_STATE_PAUSED = 'PAUSED'
const STUDY_PROGRAM_STATE_DRAFT = 'DRAFT'
const STUDY_PROGRAM_STATE_FULFILLED = 'FULFILLED'
const STUDY_PROGRAM_STATE_PUBLISHED = 'PUBLISHED'
const STUDY_PROGRAM_STATE_ARCHIVED = 'ARCHIVED'

const JOB_OFFER_DRAFT_STATE_APPROVED = 'APPROVED'
const JOB_OFFER_DRAFT_STATE_EDITING = 'EDITING'
const JOB_OFFER_DRAFT_STATE_REJECTED = 'REJECTED'
const JOB_OFFER_DRAFT_STATE_SUBMITTED = 'SUBMITTED'
const JOB_OFFER_DRAFT_STATE_UNCHANGED = 'UNCHANGED'

const STUDY_PROGRAM_DRAFT_STATE_APPROVED = 'APPROVED'
const STUDY_PROGRAM_DRAFT_STATE_EDITING = 'EDITING'
const STUDY_PROGRAM_DRAFT_STATE_REJECTED = 'REJECTED'
const STUDY_PROGRAM_DRAFT_STATE_SUBMITTED = 'SUBMITTED'
const STUDY_PROGRAM_DRAFT_STATE_UNCHANGED = 'UNCHANGED'

const JOB_OFFER_STEP_FIRST_INTERVIEW = 'FIRST_INTERVIEW'
const JOB_OFFER_STEP_FRENCH_EVALUATION = 'FRENCH_EVALUATION'
const JOB_OFFER_STEP_HIRED = 'HIRED'
const JOB_OFFER_STEP_NEW = 'NEW'
const JOB_OFFER_STEP_OFFER_SENT = 'OFFER_SENT'
const JOB_OFFER_STEP_PROSPECT = 'PROSPECT'
const JOB_OFFER_STEP_REJECTED = 'REJECTED'
const JOB_OFFER_STEP_TO_CALL = 'TO_CALL'
const JOB_OFFER_STEP_CALLED = 'CALLED'
const JOB_OFFER_STEP_INTERVIEW_PLANNED = 'INTERVIEW_PLANNED'
const JOB_OFFER_STEP_INTERVIEW_NOT_SELECTED = 'NOT_SELECTED'
const JOB_OFFER_STEP_INTERVIEW_TEST_REQUIRED = 'TEST_REQUIRED'
const JOB_OFFER_STEP_NOT_SELECTED_AFTER_INTERVIEW = 'NOT_SELECTED_AFTER_INTERVIEW'
const JOB_OFFER_STEP_NO_SHOW = 'NO_SHOW'

const APPLICATION_STATUS_SENT = 'SENT'
const APPLICATION_STATUS_UNDER_CONSIDERATION = 'UNDER_CONSIDERATION'
const APPLICATION_STATUS_CALLED = 'CALLED'
const APPLICATION_STATUS_INTERVIEW_PLANNED = 'INTERVIEW_PLANNED'
const APPLICATION_STATUS_HIRED = 'HIRED'
const APPLICATION_STATUS_POSTPONED = 'POSTPONED'
const APPLICATION_STATUS_OFFER_SENT = 'OFFER_SENT'
const APPLICATION_STATUS_NOT_SELECTED = 'NOT_SELECTED'
const APPLICATION_STATUS_NO_SHOW = 'NO_SHOW'
const APPLICATION_STATUS_NOT_SELECTED_AFTER_INTERVIEW = 'NOT_SELECTED_AFTER_INTERVIEW'
const APPLICATION_STATUS_INTERVIEW_DONE = 'INTERVIEW_DONE'

const MISSION_TYPE_ON_LOCATION = 'ON_LOCATION'
const MISSION_TYPE_VIRTUAL = 'VIRTUAL'
const MISSION_STATUS_ACTIVE = 'ACTIVE'
const MISSION_STATUS_ARCHIVED = 'ARCHIVED'

const RECRUITER_INVITATION = 'RECRUITER_INVITATION'
const RECRUITER_EDUCATIONAL_INVITATION = 'RECRUITER_EDUCATIONAL_INVITATION'
const ADVISOR_INVITATION = 'ADVISOR_INVITATION'
const CONFIRM_EMAIL = 'CONFIRM_EMAIL'

const FIELD_OF_STUDY_ADMIN = 'ADMINISTRATION_COMMERCE_AND_MANAGEMENT'
const FIELD_OF_STUDY_ARTS = 'ARTS_AND_DESIGN'
const FIELD_OF_STUDY_COMMUNICATION = 'COMMUNICATION_DOCUMENTATION_AND_NEW_MEDIA'
const FIELD_OF_STUDY_EDUCATION = 'EDUCATION_AND_SOCIAL'
const FIELD_OF_STUDY_ENVIRONMENT = 'ENVIRONMENT_SUSTAINABLE_DEVELOPMENT_AGRICULTURE_FISHERIES_AND_REGIONAL_PLANNING'
const FIELD_OF_STUDY_TOURISM = 'FOOD_HOTEL_AND_TOURISM'
const FIELD_OF_STUDY_HEALTH = 'HEALTH_AND_BEAUTY_CARE'
const FIELD_OF_STUDY_HUMANITIES = 'HUMANITIES_AND_SOCIAL_SCIENCES_LAW_INTERNATIONAL_STUDIES_LANGUAGES_AND_LETTERS'
const FIELD_OF_STUDY_IT = 'INFORMATION_TECHNOLOGY'
const FIELD_OF_STUDY_MECHANICS =
  'MAINTENANCE_MECHANICS_ELECTRICAL_ENGINEERING_MOTORIZED_EQUIPMENT_MAINTENANCE_MECHANICAL_MANUFACTURING_AND_METALLURGY'
const FIELD_OF_STUDY_MINES = 'MINES_SITE_WORKS_BUILDINGS_PUBLIC_WORKS_WOOD_AND_RELATED_MATERIALS'
const FIELD_OF_STUDY_SCIENCES = 'PURE_AND_APPLIED_SCIENCES_ENGINEERING'
const FIELD_OF_STUDY_TRANSPORT = 'TRANSPORT_AND_AERONAUTICS'

const DIPLOMA_DEP = 'DVS'
const DIPLOMA_AEC = 'ACS'
const DIPLOMA_DEC = 'DCS'
const DIPLOMA_BAC = 'BACHELOR_DEGREE'
const DIPLOMA_MASTER = 'MASTER'
const DIPLOMA_DESS = 'DESS'
const DIPLOMA_DOCTORAT = 'DOCTORATE'

const CURRENCY_CAD = 'CAD'
const CURRENCY_USD = 'USD'
const CURRENCY_AUD = 'AUD'
const CURRENCY_BRL = 'BRL'
const CURRENCY_CHF = 'CHF'
const CURRENCY_CNY = 'CNY'
const CURRENCY_CZK = 'CZK'
const CURRENCY_DKK = 'DKK'
const CURRENCY_EUR = 'EUR'
const CURRENCY_GBP = 'GBP'
const CURRENCY_HKD = 'HKD'
const CURRENCY_HRK = 'HRK'
const CURRENCY_HUF = 'HUF'
const CURRENCY_IDR = 'IDR'
const CURRENCY_ILS = 'ILS'
const CURRENCY_INR = 'INR'
const CURRENCY_ISK = 'ISK'
const CURRENCY_JPY = 'JPY'
const CURRENCY_KRW = 'KRW'
const CURRENCY_MXN = 'MXN'
const CURRENCY_MYR = 'MYR'
const CURRENCY_NOK = 'NOK'
const CURRENCY_NZD = 'NZD'
const CURRENCY_PHP = 'PHP'
const CURRENCY_PLN = 'PLN'
const CURRENCY_RON = 'RON'
const CURRENCY_RUB = 'RUB'
const CURRENCY_SEK = 'SEK'
const CURRENCY_SGD = 'SGD'
const CURRENCY_THB = 'THB'
const CURRENCY_TRY = 'TRY'
const CURRENCY_ZAR = 'ZAR'

const SCHOLARSHIP_FEES_FREE = 'FREE'
const SCHOLARSHIP_FEES_OTHERS_1000_3000 = 'BETWEEN1K_AND3K'
const SCHOLARSHIP_FEES_OTHERS_3000_6000 = 'BETWEEN3K_AND6K'
const SCHOLARSHIP_FEES_OTHERS_6000_9000 = 'BETWEEN6K_AND9K'
const SCHOLARSHIP_FEES_OTHERS_9000_12000 = 'BETWEEN9K_AND12K'
const SCHOLARSHIP_FEES_OTHERS_12000_15000 = 'BETWEEN12K_AND15K'
const SCHOLARSHIP_FEES_OTHERS_16000_18000 = 'BETWEEN15K_AND18K'
const SCHOLARSHIP_FEES_OTHERS_18000_22000 = 'BETWEEN18K_AND22K'
const SCHOLARSHIP_FEES_OTHERS_MORE_THAN_22000 = 'MORE_THAN22K'

const AGE_GROUP_UNDEFINED = 'UNDEFINED'
const AGE_GROUP_UNDER_18 = 'UNDER18'
const AGE_GROUP_BETWEEN_18_AND_24 = 'BETWEEN18_AND24'
const AGE_GROUP_BETWEEN_25_AND_34 = 'BETWEEN25_AND34'
const AGE_GROUP_BETWEEN_35_AND_44 = 'BETWEEN35_AND44'
const AGE_GROUP_BETWEEN_45_AND_54 = 'BETWEEN45_AND54'
const AGE_GROUP_OVER_55 = 'OVER55'

const EDUCATION_LEVEL_LESS_THAN_12 = 'LESS_THAN12_YEARS'
const EDUCATION_LEVEL_MORE_THAN_12 = 'MORE_THAN12_YEARS'
const EDUCATION_LEVEL_MORE_THAN_13 = 'MORE_THAN13_YEARS'
const EDUCATION_LEVEL_MORE_THAN_14 = 'MORE_THAN14_YEARS'
const EDUCATION_LEVEL_MORE_THAN_15 = 'MORE_THAN15_YEARS'
const EDUCATION_LEVEL_MORE_THAN_16 = 'MORE_THAN16_YEARS'
const EDUCATION_LEVEL_MORE_THAN_17 = 'MORE_THAN17_YEARS'

const CANDIDATE_AGE_UNDER_18 = 'UNDER18'
const CANDIDATE_AGE_BETWEEN18_AND24 = 'BETWEEN18_AND24'
const CANDIDATE_AGE_BETWEEN25_AND34 = 'BETWEEN25_AND34'
const CANDIDATE_AGE_BETWEEN35_AND44 = 'BETWEEN35_AND44'
const CANDIDATE_AGE_BETWEEN45_AND54 = 'BETWEEN45_AND54'
const CANDIDATE_AGE_OVER55 = 'OVER55'

const PROFESSIONAL_DOMAINE_MANAGEMENT = 'MANAGEMENT'
const PROFESSIONAL_DOMAINE_BUSINESS = 'BUSINESS'
const PROFESSIONAL_DOMAINE_SCIENCES_TECHNOLOGY = 'SCIENCES_TECHNOLOGY'
const PROFESSIONAL_DOMAINE_HEALTH = 'HEALTH'
const PROFESSIONAL_DOMAINE_SOCIAL_SERVICES = 'SOCIAL_SERVICES'
const PROFESSIONAL_DOMAINE_CULTURE = 'CULTURE'
const PROFESSIONAL_DOMAINE_SALES = 'SALES'
const PROFESSIONAL_DOMAINE_CONSTRUCTION = 'CONSTRUCTION'
const PROFESSIONAL_DOMAINE_NATURE = 'NATURE'
const PROFESSIONAL_DOMAINE_MANUFACTURING = 'MANUFACTURING'

const LANGUAGE_ENGLISH = 'ENGLISH'
const LANGUAGE_FRENCH = 'FRENCH'

const HAS_CSQ_YES = 'YES'
const HAS_CSQ_NO = 'NO'
const HAS_CSQ_DONTKNOW = 'DONT_KNOW'

const HAS_PASSPORT_YES = 'YES'
const HAS_PASSPORT_NO = 'NO'
const HAS_PASSPORT_UNDEFINED = 'UNDEFINED'

const HAS_TRAVELED_YES = 'YES'
const HAS_TRAVELED_NO = 'NO'

const HAS_TRAVELED_TO_CANADA_YES = 'YES'
const HAS_TRAVELED_TO_CANADA_NO = 'NO'

const FRENCH_LANGUAGE_TEST_PASSED_YES = 'YES'
const FRENCH_LANGUAGE_TEST_PASSED_NO = 'NO'
const FRENCH_LANGUAGE_TEST_PASSED_NO_BUT_FRENCH_IS_FIRST_LANGUAGE = 'NO_BUT_FRENCH_IS_FIRST_LANGUAGE'

const HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_YES = 'YES'
const HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_NO = 'NO'
const HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_NA = 'NA'

const HAS_REPORT_AND_DIPLOMA_YES = 'YES'
const HAS_REPORT_AND_DIPLOMA_NO = 'NO'
const HAS_REPORT_AND_DIPLOMA_NA = 'NA'

const TRAINING_LEVEL_PRE_UNIVERSITY = 'PRE_UNIVERSITY'
const TRAINING_LEVEL_BACHELOR = 'BACHELOR'
const TRAINING_LEVEL_PROFESSIONAL_MASTERS = 'PROFESSIONAL_MASTERS'
const TRAINING_LEVEL_DOCTORATE = 'DOCTORATE'
const TRAINING_LEVEL_NONE = 'NONE'

const HOME_COUNTRY_JOB_PROSPECTS_BAD = 'BAD'
const HOME_COUNTRY_JOB_PROSPECTS_GOOD = 'GOOD'
const HOME_COUNTRY_JOB_PROSPECTS_VERY_GOOD = 'VERY_GOOD'
const HOME_COUNTRY_JOB_PROSPECTS_UNKNOWN = 'UNKNOWN'

const LIVING_EXPENSES_PAYER_ME = 'ME'
const LIVING_EXPENSES_PAYER_FAMILY_MEMBER = 'FAMILY_MEMBER'
const LIVING_EXPENSES_PAYER_ME_AND_FAMILY_MEMBER = 'ME_AND_FAMILY_MEMBER'
const LIVING_EXPENSES_PAYER_OTHER = 'OTHER'
const LIVING_EXPENSES_SCHOLARSHIP = 'SCHOLARSHIP'
const LIVING_EXPENSES_PAYER_NA = 'NA'

const STUDY_EXPENSES_PAYER_ME = 'ME'
const STUDY_EXPENSES_PAYER_FAMILY_MEMBER = 'FAMILY_MEMBER'
const STUDY_EXPENSES_PAYER_ME_AND_FAMILY_MEMBER = 'ME_AND_FAMILY_MEMBER'
const STUDY_EXPENSES_PAYER_OTHER = 'OTHER'
const STUDY_EXPENSES_SCHOLARSHIP = 'SCHOLARSHIP'
const STUDY_EXPENSES_PAYER_NA = 'NA'

const AMOUNT_WILLING__TO_PAY_NOT_ABLE_TO_PAY = 'NOT_ABLE_TO_PAY'
const AMOUNT_WILLING__TO_PAY_THREE_SIX = 'THREE_SIX'
const AMOUNT_WILLING__TO_PAY_SEVEN_TEN = 'SEVEN_TEN'
const AMOUNT_WILLING__TO_PAY_ELEVEN_FOURTEEN = 'ELEVEN_FOURTEEN'
const AMOUNT_WILLING__TO_PAY_FIFTEEN_EIGHTEEN = 'FIFTEEN_EIGHTEEN'
const AMOUNT_WILLING__TO_PAY_MORE_THAN_NINETEEN = 'MORE_THAN_NINETEEN'
const AMOUNT_WILLING__NA = 'NA'

const FINANCIAL_RESOURCE_PROOF_BANK_TRANSACTION = 'BANK_TRANSACTION'
const FINANCIAL_RESOURCE_PROOF_COUNTRY_CONTROL_OFFICE_FUNDS = 'COUNTRY_CONTROL_OFFICE_FUNDS'
const FINANCIAL_RESOURCE_PROOF_SALARY_STATEMENT = 'SALARY_STATEMENT'
const FINANCIAL_RESOURCE_PROOF_TAX_STATEMENT = 'TAX_STATEMENT'
const FINANCIAL_RESOURCE_PROOF_BANK_FUNDS = 'BANK_FUNDS'
const FINANCIAL_RESOURCE_PROOF_BANK_LETTER = 'BANK_LETTER'
const FINANCIAL_RESOURCE_PROOF_MORTGAGE = 'MORTGAGE'
const FINANCIAL_RESOURCE_PROOF_NONE = 'NONE'
const FINANCIAL_RESOURCE_PROOF_NA = 'NA'

const TRAINING_RELATED_MATHEMATICS = 'MATHEMATICS'
const TRAINING_RELATED_SCIENCE = 'SCIENCE'
const TRAINING_RELATED_PHYSICAL = 'PHYSICAL'
const TRAINING_RELATED_CHEMISTRY = 'CHEMISTRY'
const TRAINING_RELATED_NONE = 'NONE'

const HAS_CANFAM_YES = 'YES'
const HAS_CAMFAM_NO = 'NO'

const HAS_VISAREFUSED_YES = 'YES'
const HAS_VISAREFUSED_NO = 'NO'

const HAS_INFRACTION_YES = 'YES'
const HAS_INFRACTION_NO = 'NO'
const HAS_INFRACTION_UNDEFIEND = 'UNDEFINED'

const HAS_ENTRYREFUSED_YES = 'YES'
const HAS_ENTRYREFUSED_NO = 'NO'
const HAS_ENTRYREFUSED_UNDEFINED = 'UNDEFINED'

const VISA_REFUSED_WORKING = 'WORKING'
const VISA_REFUSED_STUDY = 'STUDY'
const VISA_REFUSED_VISITOR = 'VISITOR'

const APPLICATION_SORT_APPLICATION_DATE = 'APPLICATION_DATE'
const APPLICATION_SORT_CANDIDATE_NAME = 'CANDIDATE_NAME'
const APPLICATION_SORT_UPDATE_DATE = 'UPDATE_DATE'
const APPLICATION_SORT_CANDIDATE_COUNTRY = 'CANDIDATE_COUNTRY'
const APPLICATION_SORT_SCORE = 'SCORE'

const JOB_POSTING_STATE_DEACTIVATED = 'DEACTIVATED'
const JOB_POSTING_STATE_DRAFT = 'DRAFT'
const JOB_POSTING_STATE_FULFILLED = 'FULFILLED'
const JOB_POSTING_STATE_PUBLISHED = 'PUBLISHED'

const TOKEN_STATUS_INVALID = 'INVALID'
const TOKEN_STATUS_USER_CONFIRMED = 'USER_CONFIRMED'
const TOKEN_STATUS_VALID = 'VALID'

const ALL_MISSION_ID = 'all-mission'

const DAY_MONTH_YEAR_FORMAT = 'D MMMM YYYY'
const MONTH_DAY_YEAR_FORMAT = 'MMMM D YYYY'
const DAY_OF_MONTH_YEAR_FORMAT = 'MMMM Do, YYYY'
const DAY_OF_MONTH_FORMAT = 'MMMM Do'
const DAY_MONTH_FORMAT = 'D MMMM'
const MONTH_DAY_FORMAT = 'MMMM D'
const MONTH_YEAR_FORMAT = 'MMM YYYY'
const DAY_FORMAT = 'D'
const DIFF_DAY_FORMAT = 'day'
const DIFF_HOUR_FORMAT = 'hour'
const DIFF_MINUTE_FORMAT = 'minute'

const QI_CAN_CONTACT_ME_LATEST = 'LATEST'
const QI_CAN_CONTACT_ME_VERSION1 = 'VERSION1'

const PRIVACY_POLICY_LATEST = 'LATEST'
const PRIVACY_POLICY_VERSION1 = 'VERSION1'

const TOS_LATEST = 'LATEST'
const TOS_VERSION1 = 'VERSION1'
const TOS_VERSION2 = 'VERSION2'

const RECRUITER_TERMS_LATEST = 'LATEST'
const RECRUITER_TERMS_VERSION1 = 'VERSION1'

const TAB_ADD_PREFIX = 'ADD_'

const INTERVIEW_LOCATION_VIRTUAL = '_VIRTUAL_'

const PROFIL_PRO = 'PROFIL_PRO'
const PROFIL_STUDENT = 'PROFIL_STUDENT'

const SORT_STUDY_PROGRAM_PUBLICATION_DATE = 'PUBLICATION_DATE'
const SORT_STUDY_PROGRAM_INSTITUTION_NAME = 'INSTITUTION_NAME'
const SORT_STUDY_PROGRAM_STUDY_PROGRAM_TITLE = 'STUDY_PROGRAM_TITLE'

const PROFIL_TYPE_PROFESSIONAL = 'PROFESSIONAL'
const PROFIL_TYPE_EDUCATIONAL = 'EDUCATIONAL'

const CANADA_COUNTRY_CODE = 'ca'
const FRANCE_COUNTRY_CODE = 'fr'
const BELGIUM_COUNTRY_CODE = 'be'

const AGENDA_FACET_NO_AGENDA_LABEL = 'NO_AGENDA'

const CONVOCATION_STATUS_AVAILABLE = 'available'
const CONVOCATION_STATUS_NOT_AVAILABLE = 'notAvailable'
const CONVOCATION_STATUS_DONE = 'done'

const RATING_SYSTEM_STUDY_FIVE = 'RATING_SYSTEM_STUDY_FIVE'
const RATING_SYSTEM_STUDY_TEN = 'RATING_SYSTEM_STUDY_TEN'
const RATING_SYSTEM_STUDY_TWENTY = 'RATING_SYSTEM_STUDY_TWENTY'
const RATING_SYSTEM_STUDY_HUNDRED = 'RATING_SYSTEM_STUDY_HUNDRED'
const RATING_SYSTEM_STUDY_OTHER = 'RATING_SYSTEM_STUDY_OTHER'

const DEFAUT_GEOLOCATION_ISO = 'CA'

const URL_TWILIO = 'twilio-url'

if (!window.qi_env) {
  window.qi_env = {}
}

export const Consts = {
  LANG_FR,
  LANG_EN,

  THEME_QI,
  THEME_SDED,

  CONTENT_LANGUAGE_FR,
  CONTENT_LANGUAGE_EN,

  APPLY_TYPE_JOB,
  APPLY_TYPE_STUDY_PROGRAM,

  USER_TYPE_CANDIDATE,
  USER_TYPE_RECRUITER,
  USER_TYPE_RECRUITER_EDUCATIONAL,
  USER_TYPE_ADVISOR,
  USER_TYPE_ANONYME,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TIME_PERMANENT,
  POST_TYPE_PERMANENT,
  POST_TYPE_PART_TIME,
  LANGUAGE_LEVEL_NOT_REQUIRED,
  LANGUAGE_LEVEL_ELEMENTARY,
  LANGUAGE_LEVEL_INTERMEDIATE,
  LANGUAGE_LEVEL_ADVANCED,
  COMPUTER_LEVEL_NONE,
  COMPUTER_LEVEL_BEGINNER,
  COMPUTER_LEVEL_INTERMEDIATE,
  COMPUTER_LEVEL_ADVANCED,
  COMPUTER_LEVEL_EXPERT,
  ACADEMIC_LEVEL_NOT_REQUIRED,
  ACADEMIC_LEVEL_SECONDARY,
  ACADEMIC_LEVEL_COLLEGE,
  ACADEMIC_LEVEL_UNIVERSITY,
  ACADEMIC_LEVEL_UNIVERSITY_PLUS,
  EXPERIENCE_LEVEL_JUNIOR,
  EXPERIENCE_LEVEL_INTERMEDIATE,
  EXPERIENCE_LEVEL_SENIOR,
  SALARY_RANGE_YEARLY,
  SALARY_RANGE_HOURLY,

  CRITERIA_VALUES_NOT_REQUIRED,
  CRITERIA_VALUES_HELPFUL,
  CRITERIA_VALUES_IMPORTANT,
  CRITERIA_VALUES_FUNDAMENTAL,

  TUITION_COST_TYPE_BY_YEAR,
  TUITION_COST_TYPE_FULL_PROGRAM,

  STEP_JOB_OFFER_IDENTIFICATION,
  STEP_JOB_OFFER_DESCRIPTION,
  STEP_JOB_OFFER_RESPONSIBILITY_COMPETENCE,
  STEP_JOB_OFFER_LANGUAGE,
  STEP_JOB_OFFER_EDUCATIONS_EXPERIENCE,
  STEP_JOB_OFFER_CONDITIONS,
  STEP_JOB_OFFER_CRITERIA_VALUE,
  STEP_JOB_OFFER_REVIEWING,

  STEP_CREATE_CANDIDATE_IDENTIFICATION,
  STEP_CREATE_CANDIDATE_PROFIL,

  URL_LOGIN,
  URL_LOGIN_RECRUITER_NO_COMPANY_ERROR,
  URL_LOGIN_RECRUITER_EDUCATIONAL_NO_INSTITUTION_ERROR,
  URL_DOC,
  URL_404,
  URL_500,
  URL_HOME,
  URL_MAINTENANCE,
  URL_EMAIL_CONFIRMATION,
  URL_USER_SETTINGS,
  URL_CONFIRM_EMAIL_CHANGE,

  URL_JOB_APPLICATION_INTERVIEW_LIST,
  URL_STUDY_PROGRAM_APPLICATION_INTERVIEW_LIST,

  URL_JOB_INTERVIEW_WAITING_ROOM,
  URL_JOB_INTERVIEW_CONNECTION,
  URL_STUDY_PROGRAM_INTERVIEW_CONNECTION,
  URL_STUDY_PROGRAM_INTERVIEW_WAITING_ROOM,
  WAITING_ROOM_STATUS_NOT_STARTED,
  WAITING_ROOM_STATUS_STARTED,
  WAITING_ROOM_STATUS_FINISHED,

  FAVORITE_JOB_POSTINGS,
  FAVORITE_STUDY_PROGRAM_POSTINGS,

  URL_JOB_OFFER_ERROR,
  URL_JOB_OFFER_LIST,
  URL_JOB_OFFER_LIST_CANDIDATE,
  URL_JOB_OFFER_ID,
  URL_JOB_OFFER_CREATE,
  URL_JOB_OFFER_EDIT,
  URL_JOB_OFFER_SUBMITTED,
  URL_JOB_OFFER_PREVIEW,
  URL_JOB_OFFER_REVIEW,
  URL_JOB_OFFER_PIPELINE,
  URL_JOB_OFFER_SORT_NEW,
  URL_JOB_OFFER_APPLY,
  URL_JOB_OFFER_APPLY_SUBMITTED,
  URL_JOB_OFFER_PUBLISHED,
  URL_FORGET_PASSWORD,
  URL_FORGET_PASSWORD_CONFIRMATION,
  URL_RESET_PASSWORD,
  URL_CREATE_CANDIDATE_USER,
  URL_CREATE_CANDIDATE_PROFIL,
  URL_CREATE_CANDIDATE_USER_CONFIRMATION,
  URL_CREATE_RECUITER_PROFIL,
  URL_CREATE_RECRUITER_ERROR,
  URL_CREATE_ADVISOR_PROFIL,
  URL_CREATE_ADVISOR_ERROR,
  URL_REGISTER,
  URL_BUSINESS_ID,
  URL_STUDY_PROGRAM_APPLICATION_CONVOCATIONS_LIST,
  URL_STUDY_PROGRAM_APPLICATION_CONVOCATION,
  URL_STUDY_PROGRAM_ID,
  URL_STUDY_PROGRAM_ERROR,
  URL_STUDY_PROGRAM_CREATE,
  URL_STUDY_PROGRAM_SUBMITTED,
  URL_STUDY_PROGRAM_PUBLISHED,
  URL_STUDY_PROGRAM_EDIT,
  URL_STUDY_PROGRAM_PREVIEW,
  URL_STUDY_PROGRAM_REVIEW,
  URL_STUDY_PROGRAM_PIPELINE,
  URL_STUDY_PROGRAM_SORT_NEW,
  URL_STUDY_PROGRAM_APPLY,
  URL_STUDY_PROGRAM_APPLY_SUBMITTED,
  URL_STUDY_PROGRAM_LIST,
  URL_STUDY_PROGRAM_LIST_CANDIDATE,
  URL_FAVORITE_LIST,
  URL_INSTITUTION_PROFILE,
  URL_INSTITUTION_PROFILE_EDITION,
  URL_INSTITUTION_PROFILE_SUBMITTED,
  URL_INSTITUTION_LIST,
  URL_INSTITUTION_EDIT_RECRUITERS_EDUCATIONAL,
  URL_AGENDA_LIST_BY_COMPANY_INSTITUTION,
  URL_COMPANY_AGENDA_LIST,
  URL_COMPANY_AGENDA_LIST_DETAIL,
  URL_INSTITUTION_AGENDA_LIST,
  URL_INSTITUTION_AGENDA_LIST_DETAIL,
  URL_PARAMS_INSTITUTION_ID,
  URL_PARAMS_STUDY_PROGRAM_ID,
  URL_BUSINESS_PROFIL,
  URL_BUSINESS_LIST,
  URL_BUSINESS_EDIT_RECRUITERS,
  URL_INSTITUTION_CREATE,
  URL_INSTITUTION_CREATED,
  URL_BUSINESS_CREATE,
  URL_BUSINESS_CREATED,
  URL_BUSINESS_PROFIL_EDITION,
  URL_BUSINESS_PROFIL_SUBMITTED,
  URL_MISSION,
  URL_MISSION_LIST,
  URL_MISSION_CREATE,
  URL_MISSION_CREATED,
  URL_MISSION_EDITION,
  URL_APPLICATIONS_LIST,
  APPLICATIONS_LIST_APPLICATIONS,
  APPLICATIONS_LIST_STUDY_PROGRAM_APPLICATIONS,
  URL_APPLICATION_CONVOCATIONS_LIST,
  URL_APPLICATION_CONVOCATION,
  URL_CANDIDATE_APPLICATION,
  URL_CANDIDATE_APPLICATION_CV,
  URL_CANDIDATE_CV,
  URL_CANDIDATE_PROFIL_PRO_PREVIEW,
  URL_CANDIDATE_EDIT,
  URL_CANDIDATE_EDIT_PRO,
  URL_CANDIDATE_PROFIL_EDUCATIONAL_PREVIEW,
  URL_CANDIDATE_EDIT_EDUCATIONAL,
  URL_PARAMS_PROFILE_CREATE,
  URL_CANDIDATE_LIST,
  URL_EDIT_ADVISORS,

  URL_PARAMS_JOB_OFFER_ID,
  URL_PARAMS_BUSINESS_ID,
  URL_PARAMS_APPLICATION_ID,
  URL_PARAMS_MISSION_ID,
  URL_CANDIDATE_CV_ID,

  LOCAL_STORAGE_DEVICEID,
  LOCAL_STORAGE_AUTHTOKEN,
  LOCAL_STORAGE_REFRESHTOKEN,
  LOCAL_STORAGE_RENEWTOKEN_STATUS,
  LOCAL_STORAGE_CURRENT_COMPANY,
  LOCAL_STORAGE_CURRENT_MISSION,
  LOCAL_STORAGE_CURRENT_INSTITUTION,
  LOCAL_STORAGE_DISPLAYED_STUDYPROGRAM_HINT,
  LOCAL_STORAGE_GEOLOC,
  LOCAL_STORAGE_GEOLOC_DATE,

  CHANGE_STUDY_PROGRAM_PIPELINE_LIST_DISPLAY_COUNT,
  INSTITUTION_LIST_DISPLAY_COUNT,
  STUDY_PROGRAM_LIST_DISPLAY_COUNT,

  STUDY_PROGRAM_APPLY_LIST_DISPLAY_COUNT,
  JOB_OFFER_APPLY_LIST_DISPLAY_COUNT,

  STUDY_PIPELINE_DISPLAY_COUNT,
  PIPELINE_DISPLAY_COUNT,

  JOB_OFFER_LIST_DISPLAY_COUNT,
  JOB_OFFER_COMPETENCIES_MAX_TAG_LENGTH,
  JOB_OFFER_COMPETENCIES_MAX_TAG_COUNT,
  COMPANY_LIST_DISPAY_COUNT,
  AGENDA_BUSINESS,
  AGENDA_INSTITUTION,
  AGENDA_LIST_INSTITUTION_DISPLAY_COUNT,
  AGENDA_LIST_COMPANY_DISPLAY_COUNT,
  APPLICATIONS_LOAD_COUNT,
  CANDIDATE_LIST_DISPLAY_COUNT,
  CANDIDATE_APPLICATIONS_LOAD_COUNT,
  CANDIDATE_NEW_APPLICATION_SORT_LIMIT_COUNT,
  DESCRIPTION_MAX_LENGTH,
  PIPELINE_APPLICATIONS_MAX_COUNT,

  FORM_LOGIN_EMAIL_INPUT,
  FORM_LOGIN_PASSWORD_INPUT,

  FORM_CREATE_JOB_OFFER_TITLE_INPUT,
  FORM_CREATE_JOB_OFFER_CNP_INPUT,
  FORM_CREATE_JOB_OFFER_NUMBER_POSITION_INPUT,
  FORM_CREATE_JOB_OFFER_START_DATE_INPUT,
  FORM_CREATE_JOB_OFFER_LOCATION_INPUT,
  FORM_CREATE_JOB_OFFER_CORE_COMPETENCIES_INPUT,
  FORM_CREATE_JOB_OFFER_DESCRIPTION_INPUT,

  FORM_CREATE_CANDIDATE_EMAIL_INPUT,
  FORM_CREATE_CANDIDATE_PASSWORD_INPUT,
  FORM_CREATE_CANDIDATE_PASSWORD_CONFIRMATION_INPUT,
  FORM_CREATE_CANDIDATE_FIRSTNAME_INPUT,
  FORM_CREATE_CANDIDATE_LASTNAME_INPUT,
  FORM_CREATE_CANDIDATE_JOBTITLE_INPUT,

  JOB_OFFER_STATE_DEACTIVATED,
  JOB_OFFER_STATE_PAUSED,
  JOB_OFFER_STATE_DRAFT,
  JOB_OFFER_STATE_FULFILLED,
  JOB_OFFER_STATE_PUBLISHED,
  JOB_OFFER_STATE_ARCHIVED,

  STUDY_PROGRAM_STATE_DEACTIVATED,
  STUDY_PROGRAM_STATE_PAUSED,
  STUDY_PROGRAM_STATE_DRAFT,
  STUDY_PROGRAM_STATE_FULFILLED,
  STUDY_PROGRAM_STATE_PUBLISHED,
  STUDY_PROGRAM_STATE_ARCHIVED,

  JOB_OFFER_DRAFT_STATE_APPROVED,
  JOB_OFFER_DRAFT_STATE_EDITING,
  JOB_OFFER_DRAFT_STATE_REJECTED,
  JOB_OFFER_DRAFT_STATE_SUBMITTED,
  JOB_OFFER_DRAFT_STATE_UNCHANGED,

  STUDY_PROGRAM_DRAFT_STATE_APPROVED,
  STUDY_PROGRAM_DRAFT_STATE_EDITING,
  STUDY_PROGRAM_DRAFT_STATE_REJECTED,
  STUDY_PROGRAM_DRAFT_STATE_SUBMITTED,
  STUDY_PROGRAM_DRAFT_STATE_UNCHANGED,

  JOB_OFFER_STEP_FIRST_INTERVIEW,
  JOB_OFFER_STEP_FRENCH_EVALUATION,
  JOB_OFFER_STEP_HIRED,
  JOB_OFFER_STEP_NEW,
  JOB_OFFER_STEP_CALLED,
  JOB_OFFER_STEP_INTERVIEW_PLANNED,
  JOB_OFFER_STEP_INTERVIEW_NOT_SELECTED,
  JOB_OFFER_STEP_INTERVIEW_TEST_REQUIRED,
  JOB_OFFER_STEP_OFFER_SENT,
  JOB_OFFER_STEP_PROSPECT,
  JOB_OFFER_STEP_REJECTED,
  JOB_OFFER_STEP_TO_CALL,
  JOB_OFFER_STEP_NOT_SELECTED_AFTER_INTERVIEW,
  JOB_OFFER_STEP_NO_SHOW,

  APPLICATION_STATUS_SENT,
  APPLICATION_STATUS_UNDER_CONSIDERATION,
  APPLICATION_STATUS_CALLED,
  APPLICATION_STATUS_INTERVIEW_PLANNED,
  APPLICATION_STATUS_HIRED,
  APPLICATION_STATUS_POSTPONED,
  APPLICATION_STATUS_OFFER_SENT,
  APPLICATION_STATUS_NOT_SELECTED,
  APPLICATION_STATUS_NO_SHOW,
  APPLICATION_STATUS_NOT_SELECTED_AFTER_INTERVIEW,
  APPLICATION_STATUS_INTERVIEW_DONE,

  STEP_EDIT_INSTITUTION_NAME,
  STEP_EDIT_INSTITUTION_RECRUITER_EDUCATIONALS,
  STEP_EDIT_INSTITUTION_LOGO,
  STEP_EDIT_INSTITUTION_MISSIONS,

  INSTITUTION_LEVEL_PROFESSIONAL,
  INSTITUTION_LEVEL_COLLEGE,
  INSTITUTION_LEVEL_UNIVERSITY,

  INSTITUTION_LEVEL_PROFESSIONAL_VALUE,
  INSTITUTION_LEVEL_COLLEGE_VALUE,
  INSTITUTION_LEVEL_UNIVERSITY_VALUE,

  STEP_EDIT_BUSINESS_NAME,
  STEP_EDIT_BUSINESS_LOGO,
  STEP_EDIT_BUSINESS_MISSIONS,
  STEP_EDIT_BUSINESS_RECRUITERS,

  MISSION_TYPE_ON_LOCATION,
  MISSION_TYPE_VIRTUAL,
  MISSION_STATUS_ACTIVE,
  MISSION_STATUS_ARCHIVED,

  RECRUITER_INVITATION,
  RECRUITER_EDUCATIONAL_INVITATION,
  ADVISOR_INVITATION,
  CONFIRM_EMAIL,

  FIELD_OF_STUDY_ADMIN,
  FIELD_OF_STUDY_ARTS,
  FIELD_OF_STUDY_COMMUNICATION,
  FIELD_OF_STUDY_EDUCATION,
  FIELD_OF_STUDY_ENVIRONMENT,
  FIELD_OF_STUDY_TOURISM,
  FIELD_OF_STUDY_HEALTH,
  FIELD_OF_STUDY_HUMANITIES,
  FIELD_OF_STUDY_IT,
  FIELD_OF_STUDY_MECHANICS,
  FIELD_OF_STUDY_MINES,
  FIELD_OF_STUDY_SCIENCES,
  FIELD_OF_STUDY_TRANSPORT,

  DIPLOMA_DEP,
  DIPLOMA_AEC,
  DIPLOMA_DEC,
  DIPLOMA_BAC,
  DIPLOMA_MASTER,
  DIPLOMA_DESS,
  DIPLOMA_DOCTORAT,

  CURRENCY_CAD,
  CURRENCY_USD,
  CURRENCY_AUD,
  CURRENCY_BRL,
  CURRENCY_CHF,
  CURRENCY_CNY,
  CURRENCY_CZK,
  CURRENCY_DKK,
  CURRENCY_EUR,
  CURRENCY_GBP,
  CURRENCY_HKD,
  CURRENCY_HRK,
  CURRENCY_HUF,
  CURRENCY_IDR,
  CURRENCY_ILS,
  CURRENCY_INR,
  CURRENCY_ISK,
  CURRENCY_JPY,
  CURRENCY_KRW,
  CURRENCY_MXN,
  CURRENCY_MYR,
  CURRENCY_NOK,
  CURRENCY_NZD,
  CURRENCY_PHP,
  CURRENCY_PLN,
  CURRENCY_RON,
  CURRENCY_RUB,
  CURRENCY_SEK,
  CURRENCY_SGD,
  CURRENCY_THB,
  CURRENCY_TRY,
  CURRENCY_ZAR,

  SCHOLARSHIP_FEES_FREE,
  SCHOLARSHIP_FEES_OTHERS_1000_3000,
  SCHOLARSHIP_FEES_OTHERS_3000_6000,
  SCHOLARSHIP_FEES_OTHERS_6000_9000,
  SCHOLARSHIP_FEES_OTHERS_9000_12000,
  SCHOLARSHIP_FEES_OTHERS_12000_15000,
  SCHOLARSHIP_FEES_OTHERS_16000_18000,
  SCHOLARSHIP_FEES_OTHERS_18000_22000,
  SCHOLARSHIP_FEES_OTHERS_MORE_THAN_22000,

  AGE_GROUP_UNDEFINED,
  AGE_GROUP_UNDER_18,
  AGE_GROUP_BETWEEN_18_AND_24,
  AGE_GROUP_BETWEEN_25_AND_34,
  AGE_GROUP_BETWEEN_35_AND_44,
  AGE_GROUP_BETWEEN_45_AND_54,
  AGE_GROUP_OVER_55,

  EDUCATION_LEVEL_LESS_THAN_12,
  EDUCATION_LEVEL_MORE_THAN_12,
  EDUCATION_LEVEL_MORE_THAN_13,
  EDUCATION_LEVEL_MORE_THAN_14,
  EDUCATION_LEVEL_MORE_THAN_15,
  EDUCATION_LEVEL_MORE_THAN_16,
  EDUCATION_LEVEL_MORE_THAN_17,

  CANDIDATE_AGE_UNDER_18,
  CANDIDATE_AGE_BETWEEN18_AND24,
  CANDIDATE_AGE_BETWEEN25_AND34,
  CANDIDATE_AGE_BETWEEN35_AND44,
  CANDIDATE_AGE_BETWEEN45_AND54,
  CANDIDATE_AGE_OVER55,

  PROFESSIONAL_DOMAINE_MANAGEMENT,
  PROFESSIONAL_DOMAINE_BUSINESS,
  PROFESSIONAL_DOMAINE_SCIENCES_TECHNOLOGY,
  PROFESSIONAL_DOMAINE_HEALTH,
  PROFESSIONAL_DOMAINE_SOCIAL_SERVICES,
  PROFESSIONAL_DOMAINE_CULTURE,
  PROFESSIONAL_DOMAINE_SALES,
  PROFESSIONAL_DOMAINE_CONSTRUCTION,
  PROFESSIONAL_DOMAINE_NATURE,
  PROFESSIONAL_DOMAINE_MANUFACTURING,

  LANGUAGE_ENGLISH,
  LANGUAGE_FRENCH,

  HAS_CSQ_YES,
  HAS_CSQ_NO,
  HAS_CSQ_DONTKNOW,

  APPLICATION_SORT_APPLICATION_DATE,
  APPLICATION_SORT_CANDIDATE_NAME,
  APPLICATION_SORT_UPDATE_DATE,
  APPLICATION_SORT_CANDIDATE_COUNTRY,
  APPLICATION_SORT_SCORE,

  JOB_POSTING_STATE_DEACTIVATED,
  JOB_POSTING_STATE_DRAFT,
  JOB_POSTING_STATE_FULFILLED,
  JOB_POSTING_STATE_PUBLISHED,

  TOKEN_STATUS_INVALID,
  TOKEN_STATUS_USER_CONFIRMED,
  TOKEN_STATUS_VALID,

  ALL_MISSION_ID,

  DAY_MONTH_YEAR_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
  DAY_OF_MONTH_YEAR_FORMAT,
  DAY_MONTH_FORMAT,
  MONTH_DAY_FORMAT,
  DAY_OF_MONTH_FORMAT,
  MONTH_YEAR_FORMAT,
  DAY_FORMAT,
  DIFF_DAY_FORMAT,
  DIFF_HOUR_FORMAT,
  DIFF_MINUTE_FORMAT,

  QI_CAN_CONTACT_ME_LATEST,
  QI_CAN_CONTACT_ME_VERSION1,

  PRIVACY_POLICY_LATEST,
  PRIVACY_POLICY_VERSION1,

  TOS_LATEST,
  TOS_VERSION1,
  TOS_VERSION2,

  RECRUITER_TERMS_LATEST,
  RECRUITER_TERMS_VERSION1,

  TAB_ADD_PREFIX,

  INTERVIEW_LOCATION_VIRTUAL,

  PROFIL_PRO,
  PROFIL_STUDENT,

  PROFIL_TYPE_PROFESSIONAL,
  PROFIL_TYPE_EDUCATIONAL,

  HAS_PASSPORT_YES,
  HAS_PASSPORT_NO,
  HAS_PASSPORT_UNDEFINED,

  HAS_TRAVELED_YES,
  HAS_TRAVELED_NO,

  HAS_TRAVELED_TO_CANADA_YES,
  HAS_TRAVELED_TO_CANADA_NO,

  FRENCH_LANGUAGE_TEST_PASSED_YES,
  FRENCH_LANGUAGE_TEST_PASSED_NO,
  FRENCH_LANGUAGE_TEST_PASSED_NO_BUT_FRENCH_IS_FIRST_LANGUAGE,

  HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_YES,
  HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_NO,
  HAS_FIRST_STUDY_YEAR_LIVING_EXPENSES_FINANCIAL_RESOURCES_NA,

  HAS_REPORT_AND_DIPLOMA_YES,
  HAS_REPORT_AND_DIPLOMA_NO,
  HAS_REPORT_AND_DIPLOMA_NA,

  TRAINING_LEVEL_PRE_UNIVERSITY,
  TRAINING_LEVEL_BACHELOR,
  TRAINING_LEVEL_PROFESSIONAL_MASTERS,
  TRAINING_LEVEL_DOCTORATE,
  TRAINING_LEVEL_NONE,

  HOME_COUNTRY_JOB_PROSPECTS_BAD,
  HOME_COUNTRY_JOB_PROSPECTS_GOOD,
  HOME_COUNTRY_JOB_PROSPECTS_VERY_GOOD,
  HOME_COUNTRY_JOB_PROSPECTS_UNKNOWN,

  LIVING_EXPENSES_PAYER_ME,
  LIVING_EXPENSES_PAYER_FAMILY_MEMBER,
  LIVING_EXPENSES_PAYER_ME_AND_FAMILY_MEMBER,
  LIVING_EXPENSES_PAYER_OTHER,
  LIVING_EXPENSES_SCHOLARSHIP,
  LIVING_EXPENSES_PAYER_NA,

  STUDY_EXPENSES_PAYER_ME,
  STUDY_EXPENSES_PAYER_FAMILY_MEMBER,
  STUDY_EXPENSES_PAYER_ME_AND_FAMILY_MEMBER,
  STUDY_EXPENSES_PAYER_OTHER,
  STUDY_EXPENSES_SCHOLARSHIP,
  STUDY_EXPENSES_PAYER_NA,

  AMOUNT_WILLING__TO_PAY_NOT_ABLE_TO_PAY,
  AMOUNT_WILLING__TO_PAY_THREE_SIX,
  AMOUNT_WILLING__TO_PAY_SEVEN_TEN,
  AMOUNT_WILLING__TO_PAY_ELEVEN_FOURTEEN,
  AMOUNT_WILLING__TO_PAY_FIFTEEN_EIGHTEEN,
  AMOUNT_WILLING__TO_PAY_MORE_THAN_NINETEEN,
  AMOUNT_WILLING__NA,

  FINANCIAL_RESOURCE_PROOF_BANK_TRANSACTION,
  FINANCIAL_RESOURCE_PROOF_COUNTRY_CONTROL_OFFICE_FUNDS,
  FINANCIAL_RESOURCE_PROOF_SALARY_STATEMENT,
  FINANCIAL_RESOURCE_PROOF_TAX_STATEMENT,
  FINANCIAL_RESOURCE_PROOF_BANK_FUNDS,
  FINANCIAL_RESOURCE_PROOF_BANK_LETTER,
  FINANCIAL_RESOURCE_PROOF_MORTGAGE,
  FINANCIAL_RESOURCE_PROOF_NONE,
  FINANCIAL_RESOURCE_PROOF_NA,

  TRAINING_RELATED_MATHEMATICS,
  TRAINING_RELATED_SCIENCE,
  TRAINING_RELATED_PHYSICAL,
  TRAINING_RELATED_CHEMISTRY,
  TRAINING_RELATED_NONE,

  HAS_CANFAM_YES,
  HAS_CAMFAM_NO,

  HAS_VISAREFUSED_YES,
  HAS_VISAREFUSED_NO,

  HAS_INFRACTION_YES,
  HAS_INFRACTION_NO,
  HAS_INFRACTION_UNDEFIEND,

  HAS_ENTRYREFUSED_YES,
  HAS_ENTRYREFUSED_NO,
  HAS_ENTRYREFUSED_UNDEFINED,

  VISA_REFUSED_WORKING,
  VISA_REFUSED_STUDY,
  VISA_REFUSED_VISITOR,

  SORT_STUDY_PROGRAM_PUBLICATION_DATE,
  SORT_STUDY_PROGRAM_INSTITUTION_NAME,
  SORT_STUDY_PROGRAM_STUDY_PROGRAM_TITLE,

  CANADA_COUNTRY_CODE,
  FRANCE_COUNTRY_CODE,
  BELGIUM_COUNTRY_CODE,

  AGENDA_FACET_NO_AGENDA_LABEL,

  CONVOCATION_STATUS_AVAILABLE,
  CONVOCATION_STATUS_NOT_AVAILABLE,
  CONVOCATION_STATUS_DONE,

  RATING_SYSTEM_STUDY_FIVE,
  RATING_SYSTEM_STUDY_TEN,
  RATING_SYSTEM_STUDY_TWENTY,
  RATING_SYSTEM_STUDY_HUNDRED,
  RATING_SYSTEM_STUDY_OTHER,

  DEFAUT_GEOLOCATION_ISO,

  URL_TWILIO,

  ROOT_API: window.qi_env.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API,
  AZURE_INSIGHT_API: window.qi_env.VUE_APP_AZURE_INSIGHT_API || process.env.VUE_APP_AZURE_INSIGHT_API,
  AZURE_INSIGHT_APIKEY: window.qi_env.VUE_APP_AZURE_INSIGHT_APIKEY || process.env.VUE_APP_AZURE_INSIGHT_APIKEY,
  AZURE_LOAD_THRESHOLD_MILLISECONDS:
    window.qi_env.VUE_APP_AZURE_LOAD_THRESHOLD_MILLISECONDS || process.env.VUE_APP_AZURE_LOAD_THRESHOLD_MILLISECONDS
}
