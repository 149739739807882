<template>
  <v-breadcrumbs v-if="app && app.showBreadcrumb" :items="breadcrumbs" divider="/"></v-breadcrumbs>
</template>

<script>
import { Consts } from '@/helpers/consts'

import gql from 'graphql-tag'

const GET_SHOW_BREADCRUMB = gql`
  query LocalShowBreadcrumb {
    app @client {
      showBreadcrumb
    }
  }
`

export default {
  apollo: {
    app: {
      query: GET_SHOW_BREADCRUMB
    }
  },
  data() {
    return {
      breadcrumbs: [],
      currentRoute: ''
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== this.currentRoute) {
        this.currentRoute = to.path
        this.updateBreadcrumbs(to)
      }
    }
  },
  methods: {
    async updateBreadcrumbs(to) {
      this.breadcrumbs = []
      let disabled = false
      for (let i = 0; i < to.meta.generatedBreadcrumb.length; i++) {
        if (i + 1 === to.meta.generatedBreadcrumb.length) {
          disabled = true
        }
        const breadcrumb = to.meta.generatedBreadcrumb[i]
        const text = await breadcrumb.get(to.params)
        const exact = true
        const href = breadcrumb.url
          .replace(Consts.URL_PARAMS_BUSINESS_ID, to.params.businessId)
          .replace(Consts.URL_PARAMS_JOB_OFFER_ID, to.params.jobOfferId)
          .replace(Consts.URL_PARAMS_APPLICATION_ID, to.params.applicationId)
          .replace(Consts.URL_PARAMS_MISSION_ID, to.params.missionId)
          .replace(Consts.URL_CANDIDATE_CV_ID, to.params.candidateId)
          .replace(Consts.URL_PARAMS_INSTITUTION_ID, to.params.institutionId)
          .replace(Consts.URL_PARAMS_STUDY_PROGRAM_ID, to.params.studyProgramPostingId)

        this.breadcrumbs.push({ text, href, exact, disabled })
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/config.scss';

.v-breadcrumbs {
  & {
    @media screen {
      flex-wrap: nowrap;
      padding: 22px 0 0 16px;
      margin-right: 56px;
      overflow: hidden;
    }

    @media screen and (min-width: map-get($breakpoint, 'xs')) {
      margin-right: 100px;
    }

    @media screen and (min-width: map-get($breakpoint, 'md')) {
      padding: 22px 0 0 24px;
    }
  }

  &--absolute-pos {
    @media screen {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  // All text elements
  li:nth-child(odd) {
    @media screen {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }

    @media screen and (min-width: map-get($breakpoint, 'xs')) {
      max-width: 100%;
    }
  }

  &__item {
    @media screen {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  // First and last elements
  li:last-child,
  li:first-child {
    & {
      @media screen and (min-width: map-get($breakpoint, 'xs')) {
        flex-shrink: 0;
      }
    }
  }

  // Middle elements
  li:not(:last-child):not(:first-child) {
    & {
      @media screen {
        max-width: 10%;
      }

      @media screen and (min-width: map-get($breakpoint, 'sm')) {
        max-width: 100%;
      }
    }
  }

  // Dividers
  li:nth-child(even) {
    @media screen {
      padding: 0 6px;
    }

    @media screen and (min-width: map-get($breakpoint, 'xs')) {
      padding: 0 12px;
    }
  }
}

// Disabled last element
.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  & {
    @media screen {
      color: color('black');
    }
  }
}
</style>
