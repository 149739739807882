import logger from '@/services/logger'

export default {
  install(Vue) {
    Vue.prototype.$logInformation = (msg) => logger.logInformation(msg)
    Vue.prototype.$logWarning = (msg) => logger.logWarning(msg)
    Vue.prototype.$logError = (msg) => logger.logError(msg)
    Vue.prototype.$logCritical = (msg) => logger.logCritical(msg)
  }
}
