<template>
  <v-list dense class="nav-app-institution">
    <!-- Mini -->
    <v-list-tile v-show="mini" @click="triggerOpen">
      <v-list-tile-action>
        <div class="text-truncate">{{ currentInstitution.name }}</div>
      </v-list-tile-action>
    </v-list-tile>
    <!-- NOT Mini -->
    <v-list-group v-show="!mini" v-model="open">
      <v-list-tile slot="activator">
        <v-list-tile-action>
          <v-icon>business</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ currentInstitution.name }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile
        @click="selectInstitution(institution.id)"
        v-for="institution in this.me.recruiterEducationalOf.filter((i) => !isCurrentInstitution(i.id))"
        :key="institution.id"
      >
        <v-list-tile-action></v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ institution.name }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list-group>
  </v-list>
</template>

<script>
import Institution from '@/services/institution'
import { Consts } from '@/helpers/consts'
import i18n from '@/helpers/i18n'

import gql from 'graphql-tag'

const GET_LOCAL_USER_CURRENT_INSTITUTION = gql`
  query LocalUserCurrentInstitution {
    user @client {
      selectedInstitution
    }
  }
`

export default {
  data: function () {
    return {
      isOpen: false
    }
  },
  props: {
    mini: {
      type: Boolean,
      required: true
    },
    me: {
      type: Object,
      required: false
    }
  },
  computed: {
    open: {
      get() {
        return this.mini ? false : this.isOpen
      },
      set(bool) {
        this.isOpen = this.mini ? false : bool
      }
    },
    isCurrentInstitution: function () {
      return (institutionId) => {
        if (this.user && this.user.selectedInstitution) {
          return this.user.selectedInstitution === institutionId
        }
        return false
      }
    },
    currentInstitution: function () {
      const current = this.me.recruiterEducationalOf.filter((institution) => this.isCurrentInstitution(institution.id))
      return current[0] || { name: '' }
    }
  },
  methods: {
    selectInstitution: async function (institutionId) {
      await Institution.updateInstitutionSelected(institutionId)
      this.isGroupOpen = false
      this.$router.replace(i18n.getLocalizedUrl(Consts.URL_STUDY_PROGRAM_LIST))
    },
    triggerOpen: function () {
      this.isOpen = true
      this.$emit('open')
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER_CURRENT_INSTITUTION
    }
  }
}
</script>

<style lang="stylus">
.nav-app-institution { // Scope
  .v-list__group {
    &:before, &:after {
      display: none;
    }
  }

  .v-list__group__header {
    .v-list__group__header__append-icon {
      padding-left: 0;
    }
  }
}
</style>
