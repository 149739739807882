import Apollo from '@/graphql/vue-apollo'

import gql from 'graphql-tag'

const ACCEPT_TOS = gql`
  mutation AcceptTos($input: AcceptTosInput!) {
    acceptTos(input: $input) {
      user {
        id
        qiCanContactMe {
          id
          hasAcceptedLatest
        }
        privacyPolicy {
          id
          hasAcceptedLatest
        }
        tos {
          id
          hasAcceptedLatest
        }
      }
    }
  }
`

const ACCEPT_RECRUITER_TERMS = gql`
  mutation AcceptRecruiterTerms($input: AcceptRecruiterTermsInput!) {
    acceptRecruiterTerms(input: $input) {
      user {
        id
        recruiterTerms {
          id
          hasAcceptedLatest
        }
      }
    }
  }
`

export default {
  acceptTos: async (qiCanContactMeVersion, privacyPolicyVersion, tosVersion) => {
    await Apollo.instance.defaultClient.mutate({
      mutation: ACCEPT_TOS,
      variables: {
        input: {
          qiCanContactMeVersion,
          privacyPolicyVersion,
          tosVersion
        }
      }
    })
  },
  acceptRecruiterTerms: async (recruiterTermsVersion) => {
    await Apollo.instance.defaultClient.mutate({
      mutation: ACCEPT_RECRUITER_TERMS,
      variables: {
        input: {
          recruiterTermsVersion
        }
      }
    })
  }
}
