<template>
  <div class="nav-app">
    <!-- Mobile -->
    <nav-app-mobile app v-if="mobile" v-on:open="open = !open"></nav-app-mobile>
    <!-- Drawer -->
    <v-navigation-drawer
      ref="drawer"
      class="nav-app__drawer"
      v-model="open"
      fixed
      hide-overlay
      :permanent="!mobile"
      :mini-variant="mini"
      :mini-variant-width="miniWidth"
      :width="width"
      :class="{ 'elevation-16': open }"
    >
      <v-list>
        <v-list-tile>
          <v-list-tile-action>
            <v-btn v-if="mobile" icon color="primary" @click.stop="open = !open">
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn v-else icon @click.stop="mini = !mini">
              <v-icon v-if="defaultMini && !mini">close</v-icon>
              <v-icon color="primary" v-else>menu</v-icon>
            </v-btn>
          </v-list-tile-action>
          <v-list-tile-content>
            <ui-app-logo />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider></v-divider>
      <!-- Mission selector -->
      <template v-if="userIsRecruiter">
        <nav-app-mission-recruiter :mini="mini" @open="mini = false" />
        <v-divider></v-divider>
      </template>
      <template v-if="userIsRecruiterEducational">
        <nav-app-mission-recruiter-educational :mini="mini" @open="mini = false" />
        <v-divider></v-divider>
      </template>
      <!-- Navigation (by role) -->
      <nav-app-list :user-role="userRole" />
      <v-divider></v-divider>
      <v-spacer />
      <!-- Business -->
      <template v-if="userHasEnterprise">
        <nav-app-business :mini="mini" @open="mini = false" :me="me" />
        <v-divider></v-divider>
      </template>
      <!-- Institution -->
      <template v-if="userHasInstitution">
        <nav-app-institution :mini="mini" @open="mini = false" :me="me" />
        <v-divider></v-divider>
      </template>
      <!-- Schedule candidates report -->
      <template v-if="!mini && userIsAdvisor">
        <v-list dense>
          <v-list-tile @click="scheduleCandidatesReport">
            <v-list-tile-action>
              <v-icon>schedule_send</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="nav-app__contact-btn-text">{{
              $t('nav-app-schedule-candidates-report')
            }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
      </template>
      <!-- Contact -->
      <template v-if="!mini">
        <v-list dense>
          <v-list-tile @click="contactQI">
            <v-list-tile-action>
              <v-icon>help_outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="nav-app__contact-btn-text">{{ $t('nav-app-contact') }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
      </template>
      <!-- Go to QET website -->
      <template v-if="!mini">
        <v-list dense>
          <v-list-tile @click="goToQETWebsite">
            <v-list-tile-action>
              <v-icon>open_in_new</v-icon>
            </v-list-tile-action>
            <v-list-tile-content class="nav-app__contact-btn-text">{{ websiteLabel }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
      </template>
      <!-- Language -->
      <template v-if="!mini">
        <v-list dense>
          <v-list-tile @click="changeLang">
            <v-list-tile-action>
              <v-icon>language</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>{{ $t('opposite-lang') }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
      </template>
      <!-- User -->
      <nav-app-user :userType="userRole" :mini="mini" @open="mini = false" :me="me" />
      <v-divider></v-divider>
      <!-- Terms of service -->
      <v-list>
        <v-list-tile-content class="nav-app__tos-container">
          <div class="nav-app__tos-btn-text" @click="openUrl($t('privacy-policy-url'))">
            {{ $t('nav-app-privacy-policy') }}
          </div>
          <div class="nav-app__tos-btn-text" @click="openUrl($t('terms-of-use-url'))">
            {{ $t('nav-app-terms-of-service') }}
          </div>
        </v-list-tile-content>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Applicationable from 'vuetify/lib/mixins/applicationable'

import NavAppList from '@/components/nav/nav-app-list'
import NavAppBusiness from '@/components/nav/nav-app-business'
import NavAppInstitution from '@/components/nav/nav-app-institution'
import NavAppUser from '@/components/nav/nav-app-user'
import NavAppMissionRecruiter from '@/components/nav/nav-app-mission-recruiter'
import NavAppMissionRecruiterEducational from '@/components/nav/nav-app-mission-recruiter-educational'
import NavAppMobile from '@/components/nav/nav-app-mobile'

import { Consts } from '@/helpers/consts'
import { ThemeSlug, ThemeIsQI } from '@/helpers/theme'
import router from '@/helpers/router'
import gql from 'graphql-tag'

import Report from '@/services/report'
import Notification from '@/services/notification'
import i18n from '@/helpers/i18n'

const GET_ME = gql`
  query Me {
    me {
      id
      type
      recruiterOf {
        id
        name
      }
      recruiterEducationalOf {
        id
        name
      }
      profile {
        id
        firstName
        lastName
        picture
      }
      email
    }
  }
`
const GET_LOCAL_USER_IS_CONNECTED = gql`
  query LocalUserIsConnected {
    user @client {
      isConnected
    }
  }
`

export default {
  components: {
    NavAppList,
    NavAppBusiness,
    NavAppInstitution,
    NavAppUser,
    NavAppMissionRecruiter,
    NavAppMissionRecruiterEducational,
    NavAppMobile
  },
  mixins: [Applicationable('left', [])],
  data() {
    return {
      ThemeSlug,
      open: !!this.$vuetify.breakpoint.lgAndUp,
      mini: !!(this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.mdAndDown),
      miniWidth: 56,
      width: 260
    }
  },
  computed: {
    userIsAnonyme: function () {
      return !this.user || !this.user.isConnected
    },
    userIsRecruiter: function () {
      return this.user && this.user.isConnected && this.me !== undefined && this.me.type === Consts.USER_TYPE_RECRUITER
    },
    userIsRecruiterEducational: function () {
      return (
        this.user && this.user.isConnected && this.me !== undefined && this.me.type === Consts.USER_TYPE_RECRUITER_EDUCATIONAL
      )
    },
    userHasEnterprise: function () {
      return this.userRole === Consts.USER_TYPE_RECRUITER && this.me && this.me.recruiterOf.length > 1
    },
    userHasInstitution: function () {
      return this.userRole === Consts.USER_TYPE_RECRUITER_EDUCATIONAL && this.me && this.me.recruiterEducationalOf.length > 1
    },
    userIsAdvisor: function () {
      return this.userRole === Consts.USER_TYPE_ADVISOR
    },
    userRole() {
      if (this.user && this.user.isConnected && this.me !== undefined) {
        return this.me.type
      } else {
        return Consts.USER_TYPE_ANONYME
      }
    },
    appWidth() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 0
      }
      if (this.$vuetify.breakpoint.mdAndDown || this.mini) {
        return this.miniWidth
      }
      return this.width
    },
    defaultMini() {
      if (this.appWidth === this.miniWidth) {
        return true
      }
      return false
    },
    mobile() {
      return this.appWidth === 0
    },
    websiteLabel() {
      return ThemeIsQI ? this.$t('nav-app-go-to-QET-website') : this.$t('nav-app-go-to-SDED-website')
    }
  },
  methods: {
    changeLang: function () {
      router.changeLang()
    },
    goToQETWebsite: function () {
      window.open(this.$t('QET-website-btn-url'), '_blank')
    },
    contactQI: function () {
      window.open(this.$t('contact-btn-url'), '_blank')
    },
    clickOutside(e) {
      // Click is inside drawer, quit
      if (this.$refs.drawer.$el.contains(e.target)) {
        return
      }
      this.mini = this.defaultMini
    },
    // Mandatory for 'Applicationable' mixins
    updateApplication() {
      return this.appWidth
    },
    openUrl: function (url) {
      window.open(url, '_blank')
    },
    scheduleCandidatesReport: async function () {
      const scheduled = await Report.ScheduleCandidatesReport()
      if (scheduled) Notification.showSuccess(i18n.instance().t('scheduled-candidates-report-success'), 6000)
    }
  },
  watch: {
    appWidth() {
      if (typeof this.mini !== 'undefined') {
        this.mini = this.defaultMini
      }
      // 'Applicationable' update
      this.callUpdate()
    }
  },
  apollo: {
    user: {
      query: GET_LOCAL_USER_IS_CONNECTED
    },
    me: {
      query: GET_ME,
      skip() {
        return this.userIsAnonyme
      }
    }
  },
  mounted() {
    this.callUpdate()
    const html = document.getElementsByTagName('html')[0]
    html.addEventListener('click', this.clickOutside)
  },
  beforeDestroy() {
    const html = document.getElementsByTagName('html')[0]
    html.removeEventListener('click', this.clickOutside)
  }
}
</script>

<style lang="stylus">
.nav-app {
  &__drawer {
    display: flex;
    flex-direction: column;
    z-index: 1000;

    &.v-navigation-drawer--mini-variant {
      .v-list__tile {
        padding-left: 4px;
        padding-right: 4px;
      }

      .v-divider {
        display: block !important;
        opacity: 0;
      }
    }
  }
  &__contact-btn{
    font-size:small !important;
  }
  &__contact-btn-text{
    flex:none;
  }
  &__tos-container {
    flex-direction: row;
  }
  &__tos-btn-text {
    cursor: pointer;
    font-size: 10px;
    padding: 0 5px 0 5px;
    &:hover {
      text-decoration: underline
    }
  }
}
</style>
