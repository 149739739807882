<template>
  <img class="ui-app-logo" :style="{ height: getHeight }" :src="src" :alt="$t('app-name')" />
</template>

<script>
import { ThemeSlug, ThemeIsQI } from '@/helpers/theme'

export default {
  data() {
    return {
      src: require(`@/assets/app-logo-${ThemeSlug}-fr.png`)
    }
  },
  props: {
    height: {
      type: String,
      default: null
    }
  },
  computed: {
    getHeight() {
      if (this.height === null) {
        return ThemeIsQI ? '45px' : '27px'
      }

      return this.height
    }
  }
}
</script>

<style lang="stylus" scoped>
.ui-app-logo {
  display: block;
  flex: 0 1 auto;
}
</style>

<docs>
Logo

<ui-app-logo></ui-app-logo>
<br />

``` html
<ui-app-logo></ui-app-logo>
```

**Propriété**

``` javascript
{
  height,   String    // Optionnel, Default: '32px'
}
```
</docs>
